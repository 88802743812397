import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Music, Brain, TrendingUp, Play, Volume2, VolumeX } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import CurriculumOverview from '../components/Home/CurriculumOverview';
import SEO from '../components/common/SEO';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';

const HomePage = () => {
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <SEO 
        title="EduVibz - Music Powered Learning Platform"
        description="Transform your learning experience with EduVibz, the innovative music-powered education platform. Boost retention, enjoy catchy educational tunes, and track your progress."
        keywords="music education, learning platform, EduVibz, music powered learning, educational technology, learn to the beat"
        canonical="/"
      />
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <section className="text-center mb-16">
            <h1 className="text-5xl font-extrabold mb-4 bg-clip-text text-eduvibe-green">
              Edu<span className="text-eduvibe-blue">Vibz</span>: Learn to the Beat
            </h1>
            <p className="text-2xl text-eduvibe-light mb-8">Unlock your potential with the power of music.</p>
            
            <div className="relative w-full max-w-4xl mx-auto mb-8">
              <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden shadow-2xl ring-4 ring-eduvibe-blue ring-opacity-50">
                <video 
                  ref={videoRef}
                  className="w-full h-full object-cover transform hover:scale-105 transition duration-500"
                  playsInline
                  muted={isMuted}
                  aria-label="EduVibz promotional video showcasing the music-powered learning platform"
                >
                  <source src="https://assets.eduvibz.com/LandingPageEduVibz.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                
                <div 
                  className="absolute inset-0 cursor-pointer"
                  onClick={togglePlay}
                  role="button"
                  aria-label={isPlaying ? "Pause video" : "Play video"}
                  tabIndex="0"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      togglePlay();
                    }
                  }}
                >
                  <div className="absolute inset-0 bg-gradient-to-t from-eduvibe-dark/30 to-transparent"></div>
                  
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div 
                      className={`transform transition-all duration-300 ${
                        isPlaying ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
                      } bg-eduvibe-blue bg-opacity-70 p-4 rounded-full hover:bg-opacity-100`}
                    >
                      <Play className="w-8 h-8 text-white" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute bottom-4 right-4">
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMute();
                  }}
                  className="bg-eduvibe-blue bg-opacity-70 p-2 rounded-full hover:bg-opacity-100 transition duration-300"
                  aria-label={isMuted ? "Unmute video" : "Mute video"}
                >
                  {isMuted ? 
                    <VolumeX className="w-6 h-6 text-white" /> : 
                    <Volume2 className="w-6 h-6 text-white" />
                  }
                </button>
              </div>
            </div>

            <Link 
              to="/subscription-plans" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-4 rounded-full text-xl font-bold hover:bg-eduvibe-blue hover:scale-105 transition duration-300 inline-flex items-center shadow-lg"
              aria-label="Start learning with EduVibz subscription plans"
            >
              <Play className="w-6 h-6 mr-2" />
              Start Learning Today
            </Link>
          </section>

          <section className="grid md:grid-cols-3 gap-8 mb-16" aria-labelledby="benefits-heading">
            <h2 id="benefits-heading" className="sr-only">EduVibz Benefits</h2>
            {[
              { icon: Brain, title: "Boost Retention", description: "Improve memorization with our audio learning technology" },
              { icon: Music, title: "Catchy Tunes", description: "Turn boring study sessions into unforgettable musical experiences" },
              { icon: TrendingUp, title: "Level Up", description: "Track your progress and see your grades improve over time" }
            ].map((item, index) => (
              <article key={index} className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300">
                <item.icon className="w-12 h-12 text-eduvibe-green mx-auto mb-4" aria-hidden="true" />
                <h3 className="text-2xl font-bold mb-2">{item.title}</h3>
                <p className="text-white">{item.description}</p>
              </article>
            ))}
          </section>

          <CurriculumOverview />
          
          <section className="text-center">
            <h2 className="text-3xl font-bold mb-6">Ready to Drop the Beat on Your Studies?</h2>
            <Link 
              to="/pricing" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 inline-flex items-center"
              aria-label="View EduVibz pricing and start your free trial"
            >
              <Music className="w-5 h-5 mr-2" aria-hidden="true" />
              Start Your Free Trial
            </Link>
          </section>
        </main>
      </div>
    </div>
  );
};

export default HomePage;