import React from 'react';

const FAQItem = ({ question, answer }) => {
  return (
    <div className="faq-item mb-6">
      <div className="faq-question font-bold mb-2">{question}</div>
      <div className="faq-answer">{answer}</div>
    </div>
  );
};

export default FAQItem;
