import React from 'react';
import { Link } from 'react-router-dom';
import { Zap, Music, Trophy, Star, PlayCircle, FolderHeart, Headphones } from 'lucide-react';

const PricingPlanCard = ({ name, price, features, cta, onSelectPlan }) => {
  const featureIcons = {
    'Supercharge your learning': <Zap className="text-yellow-400" />,
    'Full access to all content until the beta ends': <Music className="text-purple-400" />,
    'Curriculum aligned songs': <PlayCircle className="text-blue-400" />,
    'Gamified learning experience': <Trophy className="text-orange-400" />,
    'Earn edupoints and badges': <Star className="text-yellow-400" />,
    'Playlists to organise your content': <FolderHeart className="text-pink-400" />,
    'Engaging podcasts for tricky concepts': <Headphones className="text-green-400" />
  };

  return (
    <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300 border border-eduvibe-green shadow-lg hover:shadow-eduvibe-green">
      <div className="text-3xl font-bold text-eduvibe-green mb-6">{name}</div>
      <div className="text-4xl font-bold mb-8 text-white">{price}</div>
      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3 text-left">
            <span className="flex-shrink-0">{featureIcons[feature] || <Star className="text-eduvibe-green" />}</span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        onClick={onSelectPlan}
        className="w-full bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-opacity-90 transition duration-300 transform hover:scale-105 shadow-lg hover:shadow-eduvibe-green"
      >
        {cta.text}
      </button>
    </div>
  );
};

export default PricingPlanCard;
