// Client-side logger utility
const LOG_LEVELS = {
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  DEBUG: 'debug'
};

const sanitizeUserData = (userData) => {
  if (!userData) return null;
  
  // Only include safe user data
  return {
    id: userData.id || userData.sub,
    email: userData.email ? `***@${userData.email.split('@')[1]}` : undefined,
    role: userData.role,
    preferredCurriculum: userData.preferredCurriculum,
    isActive: userData.isActive,
  };
};

const sanitizeError = (error) => {
  if (!error) return null;
  return {
    message: error.message,
    code: error.code,
    name: error.name,
    stack: process.env.NODE_ENV === 'development' ? error.stack : undefined
  };
};

class Logger {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production';
    this.debugEnabled = process.env.REACT_APP_DEBUG === 'true';
    
    // Use the correct API URL in production
    if (this.isProduction) {
      this.logEndpoint = 'https://api.eduvibz.com/api/logs';
    } else {
      this.logEndpoint = process.env.REACT_APP_LOG_ENDPOINT || '/api/logs';
    }
    
    this.batchSize = 10;
    this.logQueue = [];
    this.flushInterval = 5000; // 5 seconds

    // Setup periodic log flushing
    if (this.isProduction) {
      setInterval(() => this.flushLogs(), this.flushInterval);
    }
  }

  error(message, meta = {}) {
    this._log(LOG_LEVELS.ERROR, message, meta);
  }

  warn(message, meta = {}) {
    this._log(LOG_LEVELS.WARN, message, meta);
  }

  info(message, meta = {}) {
    if (this.isProduction) return;
    this._log(LOG_LEVELS.INFO, message, meta);
  }

  debug(message, meta = {}) {
    // Always log in development, or if debug is explicitly enabled
    if (!this.isProduction || this.debugEnabled) {
      this._log(LOG_LEVELS.DEBUG, message, meta);
      // Force console output even in production for debugging
      this._consoleLog(LOG_LEVELS.DEBUG, {
        timestamp: new Date().toISOString(),
        level: LOG_LEVELS.DEBUG,
        message,
        ...this._processMetadata(meta)
      });
    }
  }

  _log(level, message, meta = {}) {
    const logEntry = {
      timestamp: new Date().toISOString(),
      level,
      message,
      ...this._processMetadata(meta),
      environment: process.env.NODE_ENV || 'development',
      debugEnabled: this.debugEnabled,
      isProduction: this.isProduction,
      userAgent: navigator.userAgent,
      url: window.location.href,
    };

    // Always console log in development
    if (!this.isProduction) {
      this._consoleLog(level, logEntry);
    }
    
    // Queue for server in production
    if (this.isProduction) {
      this.queueLog(logEntry);
      // Also console log errors and warnings in production
      if (level === LOG_LEVELS.ERROR || level === LOG_LEVELS.WARN) {
        this._consoleLog(level, logEntry);
      }
    }
  }

  _processMetadata(meta) {
    return {
      ...meta,
      user: meta.user ? sanitizeUserData(meta.user) : undefined,
      error: meta.error ? sanitizeError(meta.error) : undefined
    };
  }

  _consoleLog(level, logEntry) {
    const { timestamp, message, ...rest } = logEntry;
    const prefix = `[${timestamp}] [${level.toUpperCase()}]`;
    
    switch (level) {
      case LOG_LEVELS.ERROR:
        console.error(prefix, message, rest);
        break;
      case LOG_LEVELS.WARN:
        console.warn(prefix, message, rest);
        break;
      case LOG_LEVELS.INFO:
        console.info(prefix, message, rest);
        break;
      case LOG_LEVELS.DEBUG:
        console.debug(prefix, message, rest);
        break;
      default:
        console.log(prefix, message, rest);
    }
  }

  queueLog(logEntry) {
    this.logQueue.push(logEntry);
    
    if (this.logQueue.length >= this.batchSize) {
      this.flushLogs();
    }
  }

  async flushLogs() {
    if (this.logQueue.length === 0) return;

    const logs = [...this.logQueue];
    this.logQueue = [];

    try {
      await fetch(this.logEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ logs }),
        credentials: 'include'
      });
    } catch (error) {
      console.error('Failed to send logs to server:', error);
    }
  }
}

export const logger = new Logger();
