// src/components/common/NorthernLights.js
import React, { useEffect, useRef, useState } from 'react';
import '../../styles/northernLights.css';
import { logger } from '../../utils/logger';

const NorthernLights = ({ audioElement, isPlaying }) => {
  const svgRef = useRef(null);
  const starsRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const animationRef = useRef(null);

  // Configuration for wave amplification when music is playing
  const MUSIC_AMPLIFICATION = 1.7; // 70% increase, change this value to adjust the effect
  const starsCreatedRef = useRef(false); // Flag to check if stars are already created

  useEffect(() => {
    const svg = svgRef.current;
    const width = window.innerWidth;
    const height = window.innerHeight;

    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

    // Create SVG filter for glow effect
    const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
    filter.setAttribute('id', 'glow');
    filter.innerHTML = `
      <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
      <feMerge>
        <feMergeNode in="coloredBlur"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    `;
    svg.appendChild(filter);

    const createWave = (color1, color2, speed, opacity, amplitude, frequency) => {
      const wave = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      const gradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
      const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;

      gradient.setAttribute('id', gradientId);
      gradient.innerHTML = `
        <stop offset="0%" stop-color="${color1}"/>
        <stop offset="100%" stop-color="${color2}"/>
      `;
      svg.appendChild(gradient);

      wave.setAttribute('stroke', `url(#${gradientId})`);
      wave.setAttribute('stroke-width', '3');
      wave.setAttribute('opacity', opacity);
      wave.setAttribute('filter', 'url(#glow)');
      wave.setAttribute('fill', 'none');

      return { wave, speed, amplitude, frequency };
    };

    const waves = [
      createWave('#2feA9B', '#52D4F2', 0.001, 0.5, 50, 0.002),
      createWave('#52D4F2', '#2feA9B', 0.002, 0.3, 30, 0.003),
      createWave('#2feA9B', '#52D4F2', 0.001, 0.2, 40, 0.001)
    ];

    waves.forEach(({ wave }) => svg.appendChild(wave));

    // Create stars if they have not been created yet
    if (!starsCreatedRef.current) {
      const stars = starsRef.current;
      const numberOfStars = 200;

      for (let i = 0; i < numberOfStars; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.width = `${Math.random() * 2 + 1}px`;
        star.style.height = star.style.width;
        star.style.animationDelay = `${Math.random() * 5}s`;
        stars.appendChild(star);
      }
      starsCreatedRef.current = true; // Set the flag to true after creating stars
    }

    let phase = 0;

    const animate = () => {
      waves.forEach(({ wave, speed, amplitude, frequency }, index) => {
        const points = [];
        for (let x = 0; x <= width + 10; x += 5) {
          let y = Math.sin(x * frequency * (isPlaying ? MUSIC_AMPLIFICATION : 1) + phase * (isPlaying ? MUSIC_AMPLIFICATION : 1)) 
                  * amplitude * (isPlaying ? MUSIC_AMPLIFICATION : 1);
          y += height / 2;
          points.push(`${x},${y}`);
        }
        wave.setAttribute('d', `M${points.join(' ')}`);
      });

      phase += 0.01;
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      waves.forEach(({ wave }) => svg.removeChild(wave));
      svg.removeChild(filter);
    };
  }, [isPlaying]);

  const initializeAudioContext = () => {
    if (audioElement && !audioContextRef.current) {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        sourceNodeRef.current = audioContextRef.current.createMediaElementSource(audioElement);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);

        sourceNodeRef.current.connect(analyserRef.current);
        analyserRef.current.connect(audioContextRef.current.destination);
      } catch (error) {
        logger.error('Failed to setup audio context', { error });
      }
    }
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      initializeAudioContext();
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };

    window.addEventListener('mousedown', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    return () => {
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
        sourceNodeRef.current = null;
        analyserRef.current = null;
        dataArrayRef.current = null;
      }
    };
  }, [audioElement]);

  useEffect(() => {
    if (audioContextRef.current) {
      if (isPlaying) {
        audioContextRef.current.resume();
      } else {
        audioContextRef.current.suspend();
      }
    }
  }, [isPlaying]);

  return (
    <div className="northern-lights">
      <div className="stars" ref={starsRef}></div>
      <svg ref={svgRef} className="wave-container" xmlns="http://www.w3.org/2000/svg"></svg>
    </div>
  );
};

export default NorthernLights;