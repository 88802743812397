import React from 'react';
import { motion } from 'framer-motion';
import { Music, Brain, Zap, ChevronRight, Lightbulb, GraduationCap, Globe } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const AboutUs = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const journey = [
    { icon: <Lightbulb />, title: "The Spark", description: "A childhood passion for entrepreneurship and innovation" },
    { icon: <Brain />, title: "The Foundation", description: "Studying business and gaining real-world experience" },
    { icon: <Zap />, title: "The Tech Revelation", description: "Discovering the endless possibilities in the tech world" },
    { icon: <Music />, title: "The Eureka Moment", description: "Realizing the potential of music in education" },
    { icon: <GraduationCap />, title: "The Mission", description: "Founding EduVibz to revolutionize learning" },
    { icon: <Globe />, title: "The Vision", description: "Changing the world through innovative education" }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            About EduVibz
          </motion.h1>
          
          <motion.section 
            className="mb-16 bg-eduvibe-dark bg-opacity-80 p-8 rounded-3xl shadow-2xl"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold text-eduvibe-blue mb-6 flex items-center">
              <Music className="mr-3 w-10 h-10" />
              Our Mission
            </h2>
            <p className="text-xl leading-relaxed italic">
              "To revolutionize education by harnessing the power of music, unlocking every student's potential to learn, remember, and excel."
            </p>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Lightbulb className="mr-3 w-10 h-10" />
              Our Story
            </h2>
            <div className="bg-eduvibe-blue bg-opacity-20 p-6 rounded-xl mb-6">
              <p className="text-xl mb-4">
                For decades, educators have observed a fascinating phenomenon: students somehow struggle to remember academic content, but can effortlessly recite the lyrics of their favorite pop songs. This stark contrast highlights an untapped potential in education.
              </p>
              <p className="text-xl">
                EduVibz was born from this observation. We asked ourselves: What if we could create educational content that was as catchy and memorable as pop songs? What if we could unlock the superpower of music's effect on learning and memory retention, and tie it directly to the school curriculum?
              </p>
            </div>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Brain className="mr-3 w-10 h-10" />
              The Science Behind EduVibz
            </h2>
            <div className="bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl">
              <p className="text-xl mb-4">
                The power of music in learning is not just anecdotal – it's backed by science. Research has shown that music can:
              </p>
              <ul className="list-disc list-inside text-xl mb-4">
                <li>Enhance memory formation and recall</li>
                <li>Improve focus and attention</li>
                <li>Reduce stress and anxiety, creating an optimal learning state</li>
                <li>Activate multiple areas of the brain simultaneously, strengthening neural connections</li>
              </ul>
              <p className="text-xl">
                By leveraging these scientific insights, EduVibz creates a learning experience that's not just effective, but enjoyable and memorable.
              </p>
            </div>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Zap className="mr-3 w-10 h-10" />
              The Journey to EduVibz
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {journey.map((step, index) => (
                <div key={index} className="bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl flex items-start">
                  <div className="mr-4 text-eduvibe-green">
                    {React.cloneElement(step.icon, { size: 24 })}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <GraduationCap className="mr-3 w-10 h-10" />
              Our Vision for Education
            </h2>
            <div className="bg-eduvibe-blue bg-opacity-20 p-6 rounded-xl">
              <p className="text-xl mb-4">
                At EduVibz, we're passionate about improving children's educational experiences. We believe that every child has latent potential waiting to be unlocked. Our goal is to reach those students whose learning styles may not have been served by traditional methods, opening up the joys of education to all.
              </p>
              <p className="text-xl mb-4">
                By making learning fun and engaging, we aim to see others find the same joy in education that drives us. We believe that by improving education – the cornerstone of a better, more equal world – we can make a lasting positive impact on society.
              </p>
              <p className="text-xl">
                EduVibz is more than just an educational platform; it's a movement towards a future where every student has the opportunity to reach their full potential, regardless of their background or learning style.
              </p>
            </div>
          </motion.section>

          <motion.section 
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6">Join the EduVibz Revolution</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">
              Whether you're a student looking to boost your grades, a parent seeking an innovative learning tool for your child, or an educator wanting to revolutionize your classroom, EduVibz is your key to a more engaging, effective, and enjoyable educational experience.
            </p>
            <a 
              href="/pricing" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-4 rounded-full text-xl font-bold hover:bg-eduvibe-blue transition duration-300 inline-flex items-center"
            >
              Start Your Musical Learning Journey
              <ChevronRight className="ml-2" />
            </a>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default AboutUs;