import React from 'react';
import { motion } from 'framer-motion';
import { Music, Brain, Zap, ChevronRight, Book, Headphones, BarChart, Star } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const HowItWorks = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const steps = [
    { icon: <Book />, title: "Curriculum Integration", description: "We analyze your curriculum and identify key concepts." },
    { icon: <Music />, title: "Musical Transformation", description: "Our AI transforms these concepts into catchy, educational songs." },
    { icon: <Headphones />, title: "Engaging Learning", description: "Students listen and learn, reinforcing knowledge through music." },
    { icon: <BarChart />, title: "Progress Tracking", description: "Our platform monitors progress and adapts to individual needs." }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            How EduVibz Works
          </motion.h1>
          
          <motion.section 
            className="mb-16 bg-eduvibe-dark bg-opacity-80 p-8 rounded-3xl shadow-2xl"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold text-eduvibe-blue mb-6 flex items-center">
              <Brain className="mr-3 w-10 h-10" />
              The Science Behind EduVibz
            </h2>
            <p className="text-xl mb-4">
              EduVibz is built on a solid foundation of scientific research that demonstrates the powerful connection between music and learning:
            </p>
            <ul className="list-disc list-inside text-lg space-y-2 mb-4">
              <li>
                <strong>Enhanced Memory:</strong> A study by Ludke, Ferreira, & Overy (2014) found that singing can facilitate foreign language learning, with participants showing improved memory for phrases learned through song compared to speech.
              </li>
              <li>
                <strong>Improved Information Processing:</strong> According to Ferreri & Verga (2016), music engages multiple brain areas simultaneously, potentially leading to more efficient information processing and memory formation.
              </li>
              <li>
                <strong>Increased Motivation:</strong> Research by Kang & Williamson (2014) suggests that music can enhance motivation and engagement in learning tasks, particularly for language acquisition.
              </li>
            </ul>
            <p className="text-lg italic">
              These findings form the backbone of our innovative approach to learning at EduVibz.
            </p>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Zap className="mr-3 w-10 h-10" />
              The EduVibz Process
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {steps.map((step, index) => (
                <div key={index} className="bg-eduvibe-blue bg-opacity-20 p-6 rounded-xl flex items-start">
                  <div className="mr-4 text-eduvibe-green">
                    {React.cloneElement(step.icon, { size: 24 })}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                    <p className="text-lg">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Star className="mr-3 w-10 h-10" />
              Key Features and Benefits
            </h2>
            <div className="bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl">
              <ul className="space-y-4 text-lg">
                <li className="flex items-start">
                  <Music className="mr-2 mt-1 flex-shrink-0" />
                  <span><strong>Customized Learning Tracks:</strong> Our AI creates personalized educational songs tailored to your curriculum and learning objectives.</span>
                </li>
                <li className="flex items-start">
                  <Brain className="mr-2 mt-1 flex-shrink-0" />
                  <span><strong>Multi-Sensory Learning:</strong> Engage auditory, visual, and kinesthetic learning styles simultaneously for improved retention.</span>
                </li>
                <li className="flex items-start">
                  <Zap className="mr-2 mt-1 flex-shrink-0" />
                  <span><strong>Adaptive Learning:</strong> Our platform adjusts to each student's progress, ensuring optimal challenge and growth.</span>
                </li>
                <li className="flex items-start">
                  <BarChart className="mr-2 mt-1 flex-shrink-0" />
                  <span><strong>Progress Tracking:</strong> Detailed analytics help students, parents, and educators monitor improvement over time.</span>
                </li>
              </ul>
            </div>
          </motion.section>

          <motion.section 
            className="mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6 flex items-center">
              <Book className="mr-3 w-10 h-10" />
              Backed by Research
            </h2>
            <div className="bg-eduvibe-blue bg-opacity-20 p-6 rounded-xl">
              <p className="text-xl mb-4">
                EduVibz's methodology is grounded in peer-reviewed research:
              </p>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>Ludke, K. M., Ferreira, F., & Overy, K. (2014). Singing can facilitate foreign language learning. Memory & cognition, 42(1), 41-52.</li>
                <li>Ferreri, L., & Verga, L. (2016). Benefits of music on verbal learning and memory: How and when does it work?. Music Perception: An Interdisciplinary Journal, 34(2), 167-182.</li>
                <li>Kang, H. J., & Williamson, V. J. (2014). Background music can aid second language learning. Psychology of Music, 42(5), 728-747.</li>
                <li>Altenmüller, E., & Schlaug, G. (2013). Neurobiological aspects of neurologic music therapy. Music and Medicine, 5(4), 210-216.</li>
              </ul>
            </div>
          </motion.section>

          <motion.section 
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-4xl font-bold mb-6">Ready to Revolutionize Your Learning?</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">
              Experience the power of music-enhanced learning with EduVibz. Whether you're a student, parent, or educator, our platform offers a unique and effective approach to mastering any subject.
            </p>
            <a 
              href="/pricing" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-4 rounded-full text-xl font-bold hover:bg-eduvibe-blue transition duration-300 inline-flex items-center"
            >
              Start Your EduVibz Journey
              <ChevronRight className="ml-2" />
            </a>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default HowItWorks;