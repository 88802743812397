import React, { useState, useEffect } from 'react';
import { User, Mail, Lock } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const AccountSettings = () => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (user) {
      setEmail(user.email || '');
    }
  }, [user]);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-eduvibe-green">Account Settings</h2>
      <div className="mb-4">
        <label className="text-lg font-bold mb-2 flex items-center">
          <Mail className="mr-2" /> Email
        </label>
        <p className="text-white">{email}</p>
      </div>
      <div className="mb-4">
        <label className="text-lg font-bold mb-2 flex items-center">
          <Lock className="mr-2" /> Password
        </label>
        <p className="text-white">********</p>
      </div>
      <Link 
        to="/changepassword" 
        className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors inline-block"
      >
        Change Password
      </Link>
    </div>
  );
};

export default AccountSettings;