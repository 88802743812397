// client/src/components/common/PreferenceRibbon.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';

const PreferenceRibbon = ({ onDismiss }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleDismiss = () => {
    setIsVisible(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="bg-eduvibe-blue text-white py-2 px-4 flex justify-between items-center mt-16">
      <p>To enhance your learning experience, please set your preferences.</p>
      <div className="flex items-center space-x-4">
        <Link
          to="/student-onboarding"
          className="bg-eduvibe-green text-eduvibe-dark px-4 py-1 rounded-full hover:bg-opacity-80 transition-colors duration-300"
        >
          Set Preferences
        </Link>
        <button 
          onClick={handleDismiss} 
          className="text-white bg-eduvibe-dark rounded-full p-1 hover:bg-eduvibe-green hover:text-eduvibe-dark transition-colors duration-300"
        >
          <X size={16} />
        </button>
      </div>
    </div>
  );
};

export default PreferenceRibbon;