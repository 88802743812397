import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../services/api';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const ResetRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      await requestPasswordReset(email);
      // If successful, redirect to the reset confirmation page
      navigate('/reset-password', { 
        state: { email },
        replace: true 
      });
    } catch (err) {
      console.error('Password reset error:', err);
      setError(err.message || 'An error occurred while sending the reset link');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <h1 className="text-4xl md:text-6xl font-bold text-center text-eduvibe-green mb-8">
            Reset Password
          </h1>

          <div className="max-w-md mx-auto bg-eduvibe-dark bg-opacity-80 p-6 rounded-3xl shadow-2xl">
            {error && (
              <div className="mb-4 p-3 bg-red-500 bg-opacity-20 text-red-100 rounded-lg">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-lg font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
              >
                {isSubmitting ? 'Sending Reset Link...' : 'Send Reset Link'}
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ResetRequest;