import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Mail, AlertCircle, Loader, Eye, EyeOff, Lock } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
// Import the configured api instance instead of the specific signUp function
import api from '../services/api';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';
import { Link } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState('');
  const [details, setDetails] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [terms, setTerms] = useState(false);

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    return requirements;
  };

  useEffect(() => {
    const selectedPlan = 'student'; // Default to student plan
    setPlan(selectedPlan);
  }, [navigate]);

  useEffect(() => {
    const requirements = validatePassword(details.password);
    setPasswordStrength(requirements);
  }, [details.password]);

  useEffect(() => {
    setPasswordsMatch(details.password === details.confirmPassword && details.password !== '');
  }, [details.password, details.confirmPassword]);

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  const handleTermsChange = (e) => {
    setTerms(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true);

    if (!Object.values(passwordStrength).every(Boolean)) {
      setError('Password does not meet all requirements');
      setIsLoading(false);
      return;
    }

    if (!passwordsMatch) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!terms) {
      setError('Please agree to the terms and conditions');
      setIsLoading(false);
      return;
    }

    try {
      // Call the backend registration endpoint which handles Cognito + DB
      await api.post('/auth/register', {
        username: details.fullName, // Backend controller expects 'username'
        email: details.email,
        password: details.password,
        planId: plan // Send the selected planId ('student' by default)
      });

      // Keep storing email for verification page
      localStorage.setItem('userEmail', details.email);
      // localStorage.removeItem('tempPassword'); // No longer need to store temp password

      setMessage('Registration successful. Please check your email for verification.');
      setTimeout(() => navigate('/verify-email'), 2000);
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error.message || 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-5xl font-extrabold text-eduvibe-green text-center mb-12">Sign Up</h1>
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg max-w-lg mx-auto">
            {error && (
              <div className="bg-red-500 text-white p-3 rounded-lg mb-4 flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}
            {message && (
              <div className="bg-green-500 text-white p-3 rounded-lg mb-4 flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {message}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg font-bold mb-2" htmlFor="fullName">Full Name</label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark" />
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={details.fullName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 pl-10 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-lg font-bold mb-2" htmlFor="email">Email</label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={details.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 pl-10 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-lg font-bold mb-2">
                  Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={details.password}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
                {details.password && (
                  <motion.div 
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-2 text-sm space-y-1"
                  >
                    <p className={`flex items-center transition-colors duration-300 ${passwordStrength.length ? 'text-green-500' : 'text-red-500'}`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 ${passwordStrength.length ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      At least 8 characters
                    </p>
                    <p className={`flex items-center transition-colors duration-300 ${passwordStrength.uppercase ? 'text-green-500' : 'text-red-500'}`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 ${passwordStrength.uppercase ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      At least one uppercase letter
                    </p>
                    <p className={`flex items-center transition-colors duration-300 ${passwordStrength.lowercase ? 'text-green-500' : 'text-red-500'}`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 ${passwordStrength.lowercase ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      At least one lowercase letter
                    </p>
                    <p className={`flex items-center transition-colors duration-300 ${passwordStrength.number ? 'text-green-500' : 'text-red-500'}`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 ${passwordStrength.number ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      At least one number
                    </p>
                    <p className={`flex items-center transition-colors duration-300 ${passwordStrength.special ? 'text-green-500' : 'text-red-500'}`}>
                      <span className={`inline-block w-2 h-2 rounded-full mr-2 ${passwordStrength.special ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      At least one special character
                    </p>
                  </motion.div>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="confirmPassword" className="block text-lg font-bold mb-2">
                  Confirm Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={details.confirmPassword}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showConfirmPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
                {details.confirmPassword && (
                  <p className={`mt-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                    {passwordsMatch ? '✓ Passwords match' : '✗ Passwords do not match'}
                  </p>
                )}
              </div>
              <div className="mb-6">
                <div className="flex items-center space-x-2 p-4 bg-eduvibe-dark bg-opacity-50 rounded-lg">
                  <input
                    type="checkbox"
                    id="terms"
                    required
                    checked={terms}
                    onChange={handleTermsChange}
                    className="w-6 h-6 rounded border-2 border-eduvibe-green accent-eduvibe-green cursor-pointer"
                  />
                  <label htmlFor="terms" className="text-base cursor-pointer flex-1">
                    I agree to the{' '}
                    <Link to="/terms-of-service" className="text-eduvibe-green hover:underline" target="_blank">
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link to="/privacy-policy" className="text-eduvibe-green hover:underline" target="_blank">
                      Privacy Policy
                    </Link>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 w-full flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader className="animate-spin mr-2" />
                    Processing...
                  </>
                ) : (
                  'Sign Up'
                )}
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Register;
