// src/components/playlists/PlaylistCard.js
import React, { useState, useEffect, useMemo } from 'react';
import { Play, Edit, Users, School, User, PlusCircle, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';

const PlaylistCard = ({ playlist, onAddSong, onUpdate, onEdit, onDelete }) => {
  const navigate = useNavigate();
  const [displayedSongs, setDisplayedSongs] = useState([]);
  const { user } = useAuth();
  
  const isOwner = useMemo(() => {
    logger.debug('PlaylistCard ownership check', {
      userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined,
      playlistOwner: playlist?.owner ? `***${playlist.owner.slice(-4)}` : undefined
    });

    return user?.sub === playlist?.owner;
  }, [user?.sub, playlist?.owner]);

  const playlistTypeIcons = {
    personal: User,
    class: School,
    family: Users,
  };

  const TypeIcon = playlistTypeIcons[playlist.type] || User;

  useEffect(() => {
    setDisplayedSongs(playlist.songs || []);
  }, [playlist.songs]);

  const handlePlay = () => {
    navigate(`/music-player/playlist/${playlist._id}`);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    try {
      logger.debug('Attempting to delete playlist', {
        playlistId: playlist._id,
        isOwner
      });

      if (!isOwner) {
        logger.warn('Unauthorized delete attempt', {
          playlistId: playlist._id,
          userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined
        });
        return;
      }

      await onDelete(playlist._id);
      logger.info('Playlist deleted successfully', {
        playlistId: playlist._id
      });
    } catch (error) {
      logger.error('Failed to delete playlist', {
        playlistId: playlist._id,
        error: error.message
      });
    }
  };

  return (
    <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl overflow-hidden backdrop-filter backdrop-blur-lg p-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-semibold text-eduvibe-green">{playlist.name}</h3>
        <TypeIcon className="text-eduvibe-green" size={20} />
      </div>
      <p className="text-gray-300 text-sm mb-2">{playlist.songs?.length || 0} songs</p>
      <div className="mb-4 max-h-40 overflow-y-auto">
        {displayedSongs.length > 0 ? (
          displayedSongs.slice(0, 5).map((song, index) => (
            <div key={song._id} className="flex items-center mb-2">
              <img
                src={song.chapter?.coverImage || song.album?.coverImage || '/default-cover.jpg'}
                alt={song.title}
                className="w-10 h-10 object-cover rounded-md mr-2"
              />
              <div>
                <p className="text-sm text-gray-300 truncate">{song.title}</p>
                <p className="text-xs text-gray-500">{song.artist}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No songs in this playlist.</p>
        )}
      </div>
      <div className="flex justify-between">
        <button 
          className="bg-eduvibe-green text-eduvibe-dark px-3 py-2 rounded-full hover:bg-eduvibe-blue transition-colors flex items-center"
          onClick={handlePlay}
        >
          <Play size={16} className="mr-1" />
          <span className="text-sm">Play</span>
        </button>
        {isOwner && (
          <>
            <button 
              className="bg-eduvibe-blue text-white px-3 py-2 rounded-full hover:bg-eduvibe-green transition-colors flex items-center"
              onClick={() => onEdit(playlist)}
            >
              <Edit size={16} className="mr-1" />
              <span className="text-sm">Edit</span>
            </button>
            <button 
              onClick={() => onAddSong(playlist)}
              className="bg-eduvibe-blue text-white px-3 py-2 rounded-full hover:bg-eduvibe-green transition-colors flex items-center"
            >
              <PlusCircle size={16} className="mr-1" />
              <span className="text-sm">Add</span>
            </button>
            <button 
              className="bg-red-500 text-white px-3 py-2 rounded-full hover:bg-red-600 transition-colors flex items-center"
              onClick={handleDelete}
            >
              <Trash2 size={16} className="mr-1" />
              <span className="text-sm">Delete</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PlaylistCard;
