import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { Lock } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';

const Payment = () => {
  const { user, updateUserStatus, fetchUserData } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // Get the plan type from localStorage
  const selectedPlan = localStorage.getItem('selectedPlan');
  const planCode = selectedPlan === 'annual' ? 'PLN_52ma6lqd68a5evu' : 'PLN_s79w3cojev6tnyh';

  useEffect(() => {
    if (!user?.email) {
      navigate('/login');
    }
  }, [user, navigate]);

  const config = {
    reference: `tr_${new Date().getTime()}`,
    email: user?.email,
    amount: 100, // R1.00 in cents for card tokenization
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    currency: 'ZAR',
    label: 'EduVibe Subscription',
    metadata: {
      userId: user?.sub,
      planType: selectedPlan
    },
    // Remove plan parameter for initial R1 charge
    channels: ['card']
  };

  const handleSuccess = async (reference) => {
    setIsProcessing(true);
    try {
      // After successful R1 charge, initiate subscription
      const verificationResult = await api.post(`/paystack/verify?reference=${reference.reference}`, {
        planCode,
        isInitialCharge: true
      });
      
      if (verificationResult.data.status) {
        // Fetch updated user data to maintain login state
        await fetchUserData();
        
        setSuccess('Payment verified! Starting your free trial...');
        setTimeout(() => {
          navigate('/student-onboarding');
        }, 2000);
      } else {
        setError('Payment verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Verification error:', error);
      setError(error.response?.data?.message || 'Payment verification failed');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setError('Payment cancelled. Please try again.');
  };

  const componentProps = {
    ...config,
    text: 'Start Free Trial',
    onSuccess: handleSuccess,
    onClose: handleClose
  };

  if (!user?.email) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-5xl font-extrabold text-eduvibe-green text-center mb-12">Complete Your Payment</h1>
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg max-w-lg mx-auto">
            <div className="mb-6">
              <h2 className="text-2xl font-bold mb-2">Start Your Free Trial</h2>
              <p>Plan: {selectedPlan === 'annual' ? 'Annual Student Plan' : 'Monthly Student Plan'}</p>
              <p>Email: {user?.email}</p>
              <div className="mt-4">
                <p className="text-sm">
                  <Lock className="inline-block w-4 h-4 mr-1" />
                  Your payment information is secure and encrypted
                </p>
              </div>
            </div>
            {error && <div className="bg-red-500 text-white p-3 rounded-lg mb-4">{error}</div>}
            {success && <div className="bg-green-500 text-white p-3 rounded-lg mb-4">{success}</div>}
            <div className="mt-6">
              <PaystackButton {...componentProps} className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 w-full flex items-center justify-center" />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Payment;