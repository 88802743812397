import React from 'react';
import { logger } from '../../utils/logger';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    logger.error('Error Boundary caught an error', {
      error: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="min-h-[200px] flex items-center justify-center p-4 bg-eduvibe-dark bg-opacity-50 rounded-lg">
          <div className="text-center">
            <h2 className="text-xl font-bold text-eduvibe-green mb-2">Oops! Something went wrong</h2>
            <p className="text-white mb-4">We're working on fixing this issue.</p>
            <button
              onClick={() => {
                this.setState({ hasError: false, error: null });
                window.location.reload();
              }}
              className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full hover:bg-eduvibe-blue transition duration-300"
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
