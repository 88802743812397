// src/pages/PlaylistsPage.js
import React, { useState, useEffect } from 'react';
import NorthernLights from '../components/common/NorthernLights';
import Header from '../components/common/Header';
import PlaylistCard from '../components/playlists/PlaylistCard';
import CreatePlaylistModal from '../components/playlists/CreatePlaylistModal';
import AddSongModal from '../components/playlists/AddSongModal';
import EditPlaylistModal from '../components/playlists/EditPlaylistModal';
import { PlusCircle, Music, Users, School, Globe, User } from 'lucide-react';
import api, { getPlaylists, createPlaylist, addSongToPlaylist, updatePlaylist, deletePlaylist } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { logger } from '../utils/logger';

const PlaylistsPage = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isAddSongModalOpen, setIsAddSongModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [activeTab, setActiveTab] = useState('personal');
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const { user } = useAuth();
  const [initialSong, setInitialSong] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.openCreateModal) {
      setIsCreateModalOpen(true);
      if (location.state.initialSong) {
        setInitialSong(location.state.initialSong);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (user?.sub) {
      fetchPlaylists();
    }
  }, [user?.sub]);

  const fetchPlaylists = async () => {
    try {
      const response = await getPlaylists();
      setPlaylists(response.data);
    } catch (error) {
      logger.error('Failed to fetch playlists', { error });
    }
  };

  const handleCreatePlaylist = async (newPlaylist, initialSong) => {
    try {
      const response = await createPlaylist(newPlaylist);
      const createdPlaylist = response.data;
      if (initialSong) {
        await addSongToPlaylist(createdPlaylist._id, initialSong._id);
      }
      setPlaylists([...playlists, createdPlaylist]);
      setIsCreateModalOpen(false);
    } catch (error) {
      logger.error('Failed to create playlist', { error });
    }
  };

  const handleAddSong = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsAddSongModalOpen(true);
  };

  const handleEditPlaylist = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsEditModalOpen(true);
  };

  const handleUpdatePlaylist = async (updatedPlaylist) => {
    try {
      const response = await updatePlaylist(updatedPlaylist._id, updatedPlaylist);
      const updatedPlaylists = playlists.map(p => p._id === response.data._id ? response.data : p);
      setPlaylists(updatedPlaylists);
      setIsEditModalOpen(false);
      await fetchPlaylists(); // Refresh the playlists after updating
    } catch (error) {
      logger.error('Failed to update playlist', { error });
    }
  };

  const handleAddSongToPlaylist = async (song) => {
    try {
      await addSongToPlaylist(selectedPlaylist._id, song._id);
      await fetchPlaylists(); // Refresh the playlists after adding a song
      // The modal remains open for adding multiple songs
    } catch (error) {
      logger.error('Failed to add song to playlist', { error });
    }
  };

  const handleDeletePlaylist = async (playlistId) => {
    if (window.confirm('Are you sure you want to delete this playlist?')) {
      try {
        await deletePlaylist(playlistId);
        fetchPlaylists();
      } catch (error) {
        logger.error('Failed to delete playlist', { error });
      }
    }
  };

  logger.info('PlaylistsPage - Current user:', user?.sub);
  logger.info('PlaylistsPage - Active tab:', activeTab);
  logger.info('PlaylistsPage - All playlists:', playlists.map(p => ({ name: p.name, owner: p.owner, type: p.type, isPublic: p.isPublic })));
  
  const filteredPlaylists = playlists.filter(playlist => {
    if (!user?.sub) return false; // Don't show any playlists until user is loaded
    
    const isOwner = user.sub === playlist.owner;
    logger.info(`PlaylistsPage - Checking playlist "${playlist.name}":`, {
      owner: playlist.owner,
      userSub: user.sub,
      isOwner,
      type: playlist.type,
      isPublic: playlist.isPublic
    });
    
    switch (activeTab) {
      case 'all':
        return true;
      case 'public':
        return playlist.isPublic === true;
      case 'personal':
        return isOwner;
      case 'family':
        return playlist.type === 'family' && (isOwner || playlist.sharedWith?.includes(user.sub));
      case 'class':
        return playlist.type === 'class' && (isOwner || playlist.sharedWith?.includes(user.sub));
      default:
        return false;
    }
  });

  // Sort playlists to show personal ones first
  const sortedPlaylists = [...filteredPlaylists].sort((a, b) => {
    const isOwnerA = user?.sub === a.owner;
    const isOwnerB = user?.sub === b.owner;
    
    if (isOwnerA && !isOwnerB) return -1;
    if (!isOwnerA && isOwnerB) return 1;
    return 0;
  });
  
  logger.info('PlaylistsPage - Sorted playlists:', sortedPlaylists.map(p => p.name));

  const getVisibleTabs = () => {
    const tabs = [
      { id: 'all', label: 'All', icon: Music },
      { id: 'personal', label: 'Personal', icon: User },
      { id: 'public', label: 'Public', icon: Globe },
    ];

    if (user.planId === 'family') {
      tabs.push({ id: 'family', label: 'Family', icon: Users });
    }

    if (user.planId === 'school') {
      tabs.push({ id: 'class', label: 'Class', icon: School });
    }

    return tabs;
  };

  const visibleTabs = getVisibleTabs();

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 z-10">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl md:text-4xl font-bold text-eduvibe-green flex items-center">
            <Music className="mr-2" /> My Playlists
          </h1>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full hover:bg-eduvibe-blue transition-colors flex items-center"
          >
            <PlusCircle className="mr-2" />
            <span className="sm:inline">Playlist</span>
          </button>
        </div>
        <div className="flex space-x-2 mb-6 overflow-x-auto pb-2">
          {visibleTabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center px-4 py-2 rounded-full whitespace-nowrap ${
                activeTab === tab.id ? 'bg-eduvibe-green text-eduvibe-dark' : 'bg-eduvibe-blue bg-opacity-20 text-white'
              }`}
            >
              <tab.icon className="mr-2" size={18} />
              <span>{tab.label}</span>
            </button>
          ))}
        </div>
        {sortedPlaylists.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full">
            <h2 className="text-2xl md:text-3xl font-bold text-eduvibe-green mb-4">No Playlists Yet!</h2>
            <p className="text-xl text-eduvibe-blue mb-4">Create your first playlist and start vibing!</p>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full hover:bg-eduvibe-blue transition-colors flex items-center"
            >
              <PlusCircle className="mr-2" />
              <span className="sm:inline">Create Playlist</span>
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedPlaylists.map((playlist) => (
              <PlaylistCard 
                key={playlist._id} 
                playlist={playlist} 
                onAddSong={() => handleAddSong(playlist)}
                onUpdate={fetchPlaylists}
                onEdit={handleEditPlaylist}
                onDelete={(id) => handleDeletePlaylist(id)}
              />
            ))}
          </div>
        )}
      </main>
      <CreatePlaylistModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreatePlaylist={handleCreatePlaylist}
        initialSong={initialSong}
      />
      <AddSongModal
        isOpen={isAddSongModalOpen}
        onClose={() => setIsAddSongModalOpen(false)}
        onAddSong={handleAddSongToPlaylist}
        playlist={selectedPlaylist}
      />
      <EditPlaylistModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        playlist={selectedPlaylist}
        onUpdatePlaylist={handleUpdatePlaylist}
      />
    </div>
  );
};

export default PlaylistsPage;
