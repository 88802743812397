// src/components/Browse/SearchResults.js
import React from 'react';
import { Play, PlusCircle } from 'lucide-react';

const SearchResults = ({ results, onSongSelect, onAddToPlaylist, showPlayButton = true }) => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl md:text-3xl font-bold text-eduvibe-green mb-4">Search Results</h2>
      <ul className="space-y-4">
        {results.map((song) => (
          <li key={song._id} className="bg-eduvibe-blue bg-opacity-30 rounded-lg p-4 flex items-center">
            <img 
              src={song.chapter?.coverImage || song.album?.coverImage || '/default-cover.jpg'} 
              alt={song.title} 
              className="w-16 h-16 object-cover rounded-md mr-4" 
            />
            <div className="flex-grow">
              <h3 className="text-lg font-semibold text-eduvibe-green">{song.title}</h3>
              <p className="text-sm text-gray-300">{song.artist} • {song.chapter?.name || song.album?.title}</p>
            </div>
            {showPlayButton && (
              <button 
                className="bg-eduvibe-green text-eduvibe-dark p-2 rounded-full hover:bg-opacity-90 transition-colors duration-300 mr-2"
                onClick={() => onSongSelect(song)}
              >
                <Play size={20} />
              </button>
            )}
            <button 
              className="bg-eduvibe-blue text-white p-2 rounded-full hover:bg-opacity-90 transition-colors duration-300"
              onClick={() => onAddToPlaylist(song)}
            >
              <PlusCircle size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResults;