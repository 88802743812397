import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';
import api from '../../services/api';
import { X, AlertCircle, CheckCircle } from 'lucide-react';
import CreatePlaylistModal from './CreatePlaylistModal'; // Assuming CreatePlaylistModal is in the same directory

const SelectPlaylistModal = ({ isOpen, onClose, onAddToPlaylist, song }) => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      fetchPlaylists();
      setError(null);
      setSuccessMessage('');
    }
  }, [isOpen]);

  const fetchPlaylists = async () => {
    try {
      setLoading(true);
      setError(null);
      
      logger.debug('Fetching playlists for user', {
        userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined
      });

      const response = await api.get('/playlists');
      
      if (!response.data) {
        logger.error('Invalid response from server', { response });
        throw new Error('Invalid server response');
      }

      // Filter for personal playlists (where the user is the owner)
      const personalPlaylists = response.data.filter(playlist => playlist.owner === user?.sub);

      logger.debug('Personal playlists fetched successfully', {
        playlistCount: personalPlaylists.length
      });

      setPlaylists(personalPlaylists);
    } catch (error) {
      logger.error('Error fetching playlists', {
        error: error.message,
        userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined
      });
      setError('Failed to fetch playlists. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePlaylistSelect = async (playlist) => {
    if (!playlist?._id || !song?._id) {
      setError('Invalid playlist or song data');
      return;
    }

    try {
      const response = await api.post('/playlists/add-song', {
        playlistId: playlist._id,
        songId: song._id
      });

      // Handle the response based on the data
      if (response?.data?.alreadyExists) {
        setError(`"${song?.title}" is already in this playlist.`);
        logger.info('Attempted to add duplicate song to playlist', {
          playlistId: playlist._id,
          songId: song._id,
          error: 'Song already exists in playlist'
        });
        return;
      }

      // If we get here and have success, show success message
      setSuccessMessage(`"${song?.title}" has been added to the playlist.`);
      logger.info('Song added to playlist successfully', {
        playlistId: playlist._id,
        songId: song._id
      });
      
      // Close modal after showing success message briefly
      setTimeout(() => {
        onAddToPlaylist(song, playlist._id);
        onClose();
      }, 1500);

    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message || 'An unexpected error occurred';
      setError(errorMessage);
      logger.error('Error adding song to playlist', {
        error: errorMessage,
        playlistId: playlist._id,
        songId: song._id,
        status: error?.response?.status
      });
    }
  };

  const handleCreatePlaylist = async (newPlaylist) => {
    try {
      const response = await api.post('/playlists', newPlaylist);
      logger.info('Playlist created successfully', {
        playlistId: response.data._id,
        name: newPlaylist.name
      });
      setIsCreatePlaylistModalOpen(false);
      fetchPlaylists(); // Refresh the playlists list
    } catch (error) {
      logger.error('Error creating playlist', {
        error: error.message,
        playlistData: newPlaylist
      });
      setError('Failed to create playlist. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-eduvibe-dark border border-eduvibe-blue rounded-lg p-6 w-full max-w-xl max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-eduvibe-green hover:text-white p-2 rounded-full bg-eduvibe-green flex items-center justify-center"
          style={{ width: '48px', height: '48px' }}
        >
          <X size={24} color="white" />
        </button>

        <div className="mb-6">
          <h2 className="text-2xl font-bold text-eduvibe-green flex items-center">
            Select Playlist
          </h2>
          <p className="text-gray-400 mt-2">Choose a playlist to add "{song?.title}"</p>
        </div>
        
        {error && (
          <div className="flex items-center gap-2 text-red-500 mb-4 p-3 bg-red-500 bg-opacity-10 rounded">
            <AlertCircle size={20} />
            <span>{error}</span>
          </div>
        )}

        {successMessage && (
          <div className="flex items-center gap-2 text-eduvibe-green mb-4 p-3 bg-green-500 bg-opacity-10 rounded">
            <CheckCircle className="text-eduvibe-green" size={20} />
            <span>{successMessage}</span>
          </div>
        )}

        {loading ? (
          <div className="flex items-center justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-eduvibe-green"></div>
          </div>
        ) : (
          <>
            {playlists.length === 0 ? (
              <div className="text-center py-8 text-gray-400">
                <p className="mb-4">No playlists found. Create one to get started!</p>
                <button
                  onClick={() => setIsCreatePlaylistModalOpen(true)}
                  className="bg-eduvibe-green text-white px-4 py-2 rounded hover:bg-eduvibe-green-dark transition-colors"
                >
                  Create New Playlist
                </button>
              </div>
            ) : (
              <>
                <div className="space-y-2 mb-6">
                  {playlists.map(playlist => (
                    <div
                      key={playlist._id}
                      onClick={() => handlePlaylistSelect(playlist)}
                      className="p-4 bg-eduvibe-blue bg-opacity-10 hover:bg-opacity-20 cursor-pointer rounded-lg text-white transition-all duration-300 flex items-center justify-between"
                    >
                      <span>{playlist.name}</span>
                      <span className="text-sm text-gray-400">{playlist.songs?.length || 0} songs</span>
                    </div>
                  ))}
                </div>

                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => setIsCreatePlaylistModalOpen(true)}
                    className="bg-eduvibe-green text-white px-4 py-2 rounded hover:bg-eduvibe-green-dark transition-colors"
                  >
                    Create New Playlist
                  </button>
                  <button
                    onClick={onClose}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </>
        )}
        <CreatePlaylistModal
          isOpen={isCreatePlaylistModalOpen}
          onClose={() => setIsCreatePlaylistModalOpen(false)}
          onCreatePlaylist={handleCreatePlaylist}
        />
      </div>
    </div>
  );
};

export default SelectPlaylistModal;
