import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Music } from 'lucide-react';
import { logger } from '../../utils/logger';

const Sidebar = ({ chapter, playlist, songs, onSongSelect, currentSong }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [displaySongs, setDisplaySongs] = useState([]);

  useEffect(() => {
    logger.debug('Sidebar props:', { chapter, playlist, songs });
    if (chapter) {
      logger.debug('Chapter ID:', chapter._id);
      const chapterSongs = songs.filter(song => {
        logger.debug('Comparing:', {
          songChapterId: song.chapter?._id,
          currentChapterId: chapter._id
        });
        return song.chapter?._id === chapter._id;
      });
      logger.debug('Filtered songs:', chapterSongs);
      setDisplaySongs(chapterSongs);
    } else if (playlist) {
      setDisplaySongs(songs);
    } else {
      setDisplaySongs([]);
    }
  }, [chapter, playlist, songs]);

  const title = playlist ? playlist.name : chapter?.name;

  if (!title) return null;

  return (
    <div className={`bg-eduvibe-dark bg-opacity-95 h-full flex flex-col transition-all duration-300 absolute top-10 pb-40 left-0 z-10 pt-12 ${isExpanded ? 'w-96' : 'w-0'}`}>
      <div className={`p-4 border-b border-eduvibe-green ${isExpanded ? '' : 'hidden'}`}>
        <div className="text-container">
          <h2 className="text-lg font-bold scrolling-text">{title}</h2>
        </div>
      </div>
      <ul className={`flex-grow overflow-y-auto ${isExpanded ? '' : 'hidden'}`}>
        {displaySongs.map((song) => (
          <li
            key={song._id}
            className={`p-4 cursor-pointer hover:bg-eduvibe-green hover:bg-opacity-20 ${currentSong && currentSong._id === song._id ? 'bg-eduvibe-green bg-opacity-20' : ''}`}
            onClick={() => onSongSelect(song)}
          >
            <div className="flex items-center">
              <Music className="mr-2 flex-shrink-0" size={16} />
              <div className="text-container flex-grow">
                <span className="scrolling-text inline-block">{song.title}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="absolute top-1/2 -right-6 transform -translate-y-1/2 bg-eduvibe-green bg-opacity-50 text-white rounded-r-full p-2 focus:outline-none"
      >
        {isExpanded ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
      </button>
    </div>
  );
};

export default Sidebar;