// src/components/playlists/AddSongModal.js
import React, { useState, useEffect } from 'react';
import { X, AlertCircle, CheckCircle } from 'lucide-react';
import SearchBar from '../Browse/SearchBar';
import SearchResults from '../Browse/SearchResults';
import Filters from '../Browse/Filters';
import { searchMusic, addSongToPlaylist } from '../../services/api';
import { logger } from '../../utils/logger';

const AddSongModal = ({ isOpen, onClose, onAddSong, playlist }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    curriculum: [],
    grade: [],
    subject: []
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSearchResults([]);
      setShowFilters(false);
      setError('');
      setSuccessMessage('');
      setSearchTerm('');
    }
  }, [isOpen]);

  const handleSearch = async (term) => {
    setSearchTerm(term);
    try {
      const params = { songName: term };
      
      if (filters.curriculum.length > 0) {
        params.curriculum = filters.curriculum.join(',');
      }
      if (filters.grade.length > 0) {
        params.grade = filters.grade.join(',');
      }
      if (filters.subject.length > 0) {
        params.subject = filters.subject.join(',');
      }

      const response = await searchMusic(params);
      setSearchResults(response.data);
    } catch (error) {
      logger.error('Failed to search songs', { error });
      setError('Failed to search songs. Please try again.');
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleAddSong = async (song) => {
    setError('');
    setSuccessMessage('');
    try {
      const response = await addSongToPlaylist(playlist._id, song._id);

      if (response.data.alreadyExists) {
        setError(`"${song.title}" is already in this playlist.`);
      } else {
        setSuccessMessage(`"${song.title}" has been added to the playlist.`);
        onAddSong(song);
        setSearchResults(prevResults => prevResults.filter(s => s._id !== song._id));
      }
    } catch (error) {
      logger.error('Failed to add song to playlist', { error });
      setError(`Failed to add "${song.title}" to the playlist. Please try again.`);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-eduvibe-dark border border-eduvibe-blue rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white focus:outline-none p-3"
        >
          <X size={24} />
        </button>
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-eduvibe-green flex items-center">
            Add Song to {playlist?.name}
          </h2>
        </div>
        <SearchBar onSearch={handleSearch} toggleFilters={toggleFilters} />
        <Filters 
          onFilterChange={handleFilterChange} 
          isVisible={showFilters}
        />
        {error && (
          <div className="bg-red-500 text-white p-3 rounded-lg mb-4 flex items-center">
            <AlertCircle className="mr-2 flex-shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        )}
        {successMessage && (
          <div className="bg-green-500 text-white p-3 rounded-lg mb-4 flex items-center">
            <CheckCircle className="mr-2 flex-shrink-0" />
            <span className="text-sm">{successMessage}</span>
          </div>
        )}
        <SearchResults 
          results={searchResults} 
          onSongSelect={handleAddSong}
          onAddToPlaylist={handleAddSong}
          showPlayButton={false}
        />
      </div>
    </div>
  );
};

export default AddSongModal;
