import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import PricingPlanCard from '../components/subscription/PricingPlanCard';
import FAQItem from '../components/subscription/FAQItem';
import NorthernLights from '../components/common/NorthernLights';
import '../styles/northernLights.css';
import '../styles/index.css';

const SubscriptionPlans = () => {
  const navigate = useNavigate();

  const handleJoinBeta = () => {
    navigate('/register');
  };

  const betaFeatures = [
    'Supercharge your learning',
    'Full access to all content until the beta ends',
    'Curriculum aligned songs',
    'Gamified learning experience',
    'Earn edupoints and badges',
    'Playlists to organise your content',
    'Engaging podcasts for tricky concepts'
  ];

  const faqs = [
    {
      question: 'What is the EduVibe Beta Program?',
      answer: 'The EduVibe Beta Program gives you early access to our innovative music education platform. During this phase, all features are available for free while we gather feedback and improve the platform.'
    },
    {
      question: 'Will it always be free?',
      answer: 'The beta phase offers free access to help us refine the platform. After the beta period, we will introduce paid plans. Beta users may receive special benefits or discounted rates when we launch our premium features.'
    },
    {
      question: 'How long will the beta phase last?',
      answer: 'The beta phase duration will depend on our development milestones and user feedback. We will provide advance notice before transitioning to paid plans.'
    },
    {
      question: 'What features are included in the beta?',
      answer: 'Beta users get full access to all platform features including gamified learning, personalized playlists, progress tracking, and our innovative AI-powered music education tools.'
    },
    {
      question: 'How can I provide feedback during the beta?',
      answer: 'We encourage all beta users to share their experiences and suggestions. You can submit feedback directly through the platform or contact our support team.'
    }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <div className="text-center mb-12">
            <h1 className="text-5xl font-extrabold text-eduvibe-green mb-4">Join the EduVibe Beta</h1>
            <p className="text-xl text-gray-300">Be among the first to experience the future of music education</p>
          </div>

          <div className="max-w-2xl mx-auto mb-16">
            <PricingPlanCard
              name="Beta Access"
              price="FREE"
              features={betaFeatures}
              cta={{ text: 'Join the Beta', action: handleJoinBeta }}
              onSelectPlan={handleJoinBeta}
            />
          </div>

          <div className="faq mt-16 max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold text-eduvibe-green mb-8 text-center">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SubscriptionPlans;