import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPassword } from '../services/api';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import { Info, Eye, EyeOff } from 'lucide-react';

const ResetPassword = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    return requirements;
  };

  useEffect(() => {
    const requirements = validatePassword(newPassword);
    setPasswordStrength(requirements);
    setShowPasswordRequirements(newPassword.length > 0);
  }, [newPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!Object.values(passwordStrength).every(Boolean)) {
      setError('Password does not meet all requirements');
      setIsSubmitting(false);
      return;
    }

    try {
      await confirmPassword(email, verificationCode, newPassword);
      navigate('/login', { 
        state: { message: 'Password reset successful. Please login with your new password.' },
        replace: true 
      });
    } catch (err) {
      console.error('Password reset confirmation error:', err);
      setError(err.message || 'An error occurred while resetting your password');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPasswordStrength = () => {
    return (
      <div className="mt-2 text-sm">
        <p className={passwordStrength.length ? 'text-green-500' : 'text-red-500'}>
          • At least 8 characters
        </p>
        <p className={passwordStrength.uppercase ? 'text-green-500' : 'text-red-500'}>
          • At least one uppercase letter
        </p>
        <p className={passwordStrength.lowercase ? 'text-green-500' : 'text-red-500'}>
          • At least one lowercase letter
        </p>
        <p className={passwordStrength.number ? 'text-green-500' : 'text-red-500'}>
          • At least one number
        </p>
        <p className={passwordStrength.special ? 'text-green-500' : 'text-red-500'}>
          • At least one special character
        </p>
      </div>
    );
  };

  if (!email) {
    navigate('/reset-request', { replace: true });
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <h1 className="text-4xl md:text-6xl font-bold text-center text-eduvibe-green mb-8">
            Reset Password
          </h1>

          <div className="max-w-md mx-auto bg-eduvibe-dark bg-opacity-80 p-6 rounded-3xl shadow-2xl">
            {error && (
              <div className="mb-4 p-3 bg-red-500 bg-opacity-20 text-red-100 rounded-lg">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="code" className="block text-lg font-bold mb-2">
                  Verification Code
                </label>
                <input
                  type="text"
                  id="code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="newPassword" className="block text-lg font-bold mb-2">
                  New Password
                  <button
                    type="button"
                    className="ml-2 text-eduvibe-green hover:text-eduvibe-blue group relative"
                    onClick={() => setShowPasswordRequirements(!showPasswordRequirements)}
                    aria-label="Toggle password requirements"
                  >
                    <Info className="w-5 h-5 inline" />
                    <span className="invisible group-hover:visible absolute -top-8 left-1/2 -translate-x-1/2 w-40 bg-black text-white text-xs rounded py-1 px-2">
                      View password requirements
                    </span>
                  </button>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark"
                  >
                    {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </button>
                </div>
                {showPasswordRequirements && renderPasswordStrength()}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
              >
                {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ResetPassword;
