import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const VerifyEmailHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      navigate('/verify-email', { state: { token } });
    } else {
      navigate('/verify-email', { state: { token: null } });
    }
  }, [navigate, location]);

  return <div>Processing verification...</div>;
};

export default VerifyEmailHandler;
