import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Music, Star, Zap, Flame, Crown, Mic, Radio, Guitar, Drum, Piano, Speaker, Megaphone, Music4, Award, Cable, PenTool, AudioLines, AudioWaveform, Medal, Headset, Lightbulb } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import RecentlyListenedHeader from '../components/Browse/RecentlyListenedHeader';
import AlbumRow from '../components/Browse/AlbumRow';
import PreferenceRibbon from '../components/common/PreferenceRibbon';
import { useAuth } from '../contexts/AuthContext';
import { fetchUserChallenges, getRecentlyListened } from '../services/api';
import { onChallengesCompleted, onUserDataUpdated, initializeSocket } from '../services/socket';
import * as LucideIcons from 'lucide-react';
import { logger } from '../utils/logger';

const Dashboard = () => {
  const { user, loading, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('Learner');
  const [eduPoints, setEduPoints] = useState(0);
  const [level, setLevel] = useState({ name: "Newbie Noodler", ep: 0, icon: Music, number: 0 });
  const [nextLevel, setNextLevel] = useState({ name: "Melody Memer", ep: 100, icon: Star, number: 1 });
  const [streakDays, setStreakDays] = useState(0);
  const [achievements, setAchievements] = useState([]);
  const [activeChallenges, setActiveChallenges] = useState([]);
  const [currentChallengeIndex, setCurrentChallengeIndex] = useState(0);
  const [showPreferenceRibbon, setShowPreferenceRibbon] = useState(false);
  const [recentlyPlayed, setRecentlyPlayed] = useState([]);
  const [learningTips, setLearningTips] = useState([]);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const levels = [
    { name: "Newbie Noodler", ep: 0, icon: Music, number: 0 },
    { name: "Melody Memer", ep: 100, icon: Star, number: 1 },
    { name: "Beat Boffin", ep: 250, icon: Zap, number: 2 },
    { name: "Rhythm Renegade", ep: 500, icon: Flame, number: 3 },
    { name: "Harmony Hacker", ep: 1000, icon: Crown, number: 4 },
    { name: "Lyric Loremaster", ep: 1500, icon: Headset, number: 5 },
    { name: "Chord Champion", ep: 2000, icon: Mic, number: 6 },
    { name: "Tempo Tyrant", ep: 2500, icon: Radio, number: 7 },
    { name: "Sonic Sorcerer", ep: 3000, icon: Guitar, number: 8 },
    { name: "Groove Guru", ep: 3500, icon: Drum, number: 9 },
    { name: "Riff Royalty", ep: 4000, icon: Piano, number: 10 },
    { name: "Decibel Deity", ep: 4500, icon: Speaker, number: 11 },
    { name: "Anthem Alchemist", ep: 5000, icon: Megaphone, number: 12 },
    { name: "Bassline Boss", ep: 5500, icon: Music4, number: 13 },
    { name: "Treble Trailblazer", ep: 6000, icon: Medal, number: 14 },
    { name: "Melodic Mastermind", ep: 6500, icon: Award, number: 15 },
    { name: "Symphonic Sage", ep: 7000, icon: Cable, number: 16 },
    { name: "Harmony Highlord", ep: 7500, icon: AudioLines, number: 17 },
    { name: "Crescendo Commander", ep: 8000, icon: AudioWaveform, number: 18 },
    { name: "Legendary Lyricist", ep: 8500, icon: PenTool, number: 19 }
  ];

  const getCurrentLevel = useCallback((xp) => {
    for (let i = levels.length - 1; i >= 0; i--) {
      if (xp >= levels[i].ep) {
        return levels[i];
      }
    }
    return levels[0];
  }, [levels]);

  const getNextLevel = useCallback((xp) => {
    for (let i = 0; i < levels.length; i++) {
      if (xp < levels[i].ep) {
        return levels[i];
      }
    }
    return levels[levels.length - 1];
  }, [levels]);

  const handleChallengesCompleted = useCallback((completedChallenges) => {
    logger.debug('Challenges completed', { count: completedChallenges.length, completedChallenges });
    setAchievements(prevAchievements => [
      ...completedChallenges.map(challenge => ({ icon: challenge.icon, name: challenge.title })),
      ...prevAchievements
    ].slice(0, 5));

    fetchUserChallenges().then(response => {
      const userChallenges = response.data.challengeProgress;
      setActiveChallenges(getIncompleteActiveChallenges(userChallenges));
    }).catch(error => {
      logger.error('Failed to fetch user challenges', { error });
    });
  }, []);

  const handleUserDataUpdated = useCallback((updatedData) => {
    logger.debug('User data updated', { updatedData });
    setAchievements(getRecentAchievements(updatedData.challengeProgress, updatedData.earnedBadges));
    setActiveChallenges(getIncompleteActiveChallenges(updatedData.challengeProgress));
    setEduPoints(updatedData.xp || 0);
    logger.debug('EduPoints updated', { points: updatedData.xp || 0 });
    const calculatedLevel = getCurrentLevel(updatedData.xp || 0);
    setLevel(calculatedLevel);
    setNextLevel(getNextLevel(updatedData.xp || 0));
    setStreakDays(updatedData.streakDays || 0);
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!isLoggedIn) {
        navigate('/login');
      } else if (user) {
        logger.debug('User data fetched', { user });
        setUserName(user.username || 'Learner');
        setShowPreferenceRibbon(!user.preferredCurriculum && !user.preferredGrade && (!user.preferredSubjects || user.preferredSubjects.length === 0));
        
        fetchUserChallenges().then(response => {
          logger.debug('User challenges fetched', { challenges: response.data });
          const userData = response.data;
          setAchievements(getRecentAchievements(userData.challengeProgress, userData.earnedBadges));
          setActiveChallenges(getIncompleteActiveChallenges(userData.challengeProgress));
          setEduPoints(userData.xp || 0);
          const currentLevel = getCurrentLevel(userData.xp || 0);
          const nextLevelData = getNextLevel(userData.xp || 0);
          setLevel(currentLevel);
          setNextLevel(nextLevelData);
          setStreakDays(userData.streakDays || 0);
          logger.debug('Achievements set', { achievements });
        }).catch(error => {
          logger.error('Failed to fetch user challenges', { error });
        });

        getRecentlyListened().then(response => {
          setRecentlyPlayed(response.data.chapters || []);
        }).catch(error => {
          logger.error('Failed to fetch recently played', { error });
        });

        initializeSocket(user.sub);
        onChallengesCompleted(handleChallengesCompleted);
        onUserDataUpdated(handleUserDataUpdated);

        setLearningTips([
          "Bangers are made to be replayed. The more you vibe to a track, the easier it gets to remember the lyrics. Repetition makes retention effortless.",
          "Create a custom playlist for each month or term. Teachers don’t always follow chapter 1, so curate what you need for the now and stack more as you go.",
          "Level up with EduVibz: take notes or map out key points while listening. Pro tip: mind maps = ✨ next-level memory ✨.",
          "Daily listens make studying a breeze when exam time rolls around. Why not make studying fun and stress-free?",
          "Read along with the lyrics the first few times. Even Taylor Swift's mom thought she said 'Starbucks lovers' (look it up). So don’t sweat it if you don’t get the lyrics at first. Once you can sing without the lyrics, you’re golden!",
          "Switch it up! Sing along or even dance if the beat slaps. Engaging your whole body while listening helps your brain lock in the info.",
          "Track your progress with challenges. They’re designed to help you build healthy, fun study habits.",
          "Stay focused. Don't skip too far ahead in the curriculum. Lock in on a few tracks that match what you're learning for ultimate memory gains.",
          "Peep public playlists to see if someone’s already done the work or to get study inspo from others.",
          "EduVibz is all about making learning stress-free. Don’t cram the night before. Play tracks on your way to school, during walks, or while hanging with friends. Music hits different as a study hack.",
          "Group jam sessions for the win! Study with friends and share the vibes — social learning is one of the most effective ways to lock in what you learn."
        ]);

        return () => {
          onChallengesCompleted(null);
          onUserDataUpdated(null);
        };
      }
    }
  }, [loading, isLoggedIn, user, navigate, handleChallengesCompleted, handleUserDataUpdated]);

  const getRecentAchievements = (challengeProgress, earnedBadges) => {
    if (!challengeProgress || !earnedBadges) {
      logger.info('No challenge progress or badges data available');
      return [];
    }
  
    logger.debug('Challenge progress and badges', { 
      challengeProgress, 
      earnedBadges 
    });
  
    return challengeProgress
      .filter(progress => progress.completed)
      .sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt))
      .slice(0, 5)
      .map(progress => ({
        icon: progress.challengeId.icon,
        name: progress.challengeId.title,
        description: progress.challengeId.description,
        badgeUrl: progress.challengeId.badgeUrl
      }));
  };

  const getIncompleteActiveChallenges = (challengeProgress) => {
    return challengeProgress.filter(progress => !progress.completed);
  };

  const nextTip = () => {
    setCurrentTipIndex((prevIndex) => (prevIndex + 1) % learningTips.length);
  };

  const prevTip = () => {
    setCurrentTipIndex((prevIndex) => (prevIndex - 1 + learningTips.length) % learningTips.length);
  };

  const nextChallenge = () => {
    setCurrentChallengeIndex((prevIndex) => (prevIndex + 1) % activeChallenges.length);
  };

  const prevChallenge = () => {
    setCurrentChallengeIndex((prevIndex) => (prevIndex - 1 + activeChallenges.length) % activeChallenges.length);
  };

  const handleAlbumSelect = (album) => {
    navigate(`/music-player/chapter/${album._id}`);
  };

  const renderChallengeIcon = (icon) => {
    if (typeof icon === 'string') {
      return <span className="text-4xl">{icon}</span>;
    } else if (LucideIcons[icon]) {
      const IconComponent = LucideIcons[icon];
      return <IconComponent size={48} className="text-eduvibe-green" />;
    } else {
      return <LucideIcons.Award size={48} className="text-eduvibe-green" />;
    }
  };

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  const LearningJourney = () => (
    <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
      <h2 className="text-2xl font-bold text-eduvibe-green mb-4">Your Learning Journey</h2>
      <div className="flex items-center justify-center mb-6">
        <div className="relative">
          <svg className="w-48 h-48" viewBox="0 0 130 130">
            <circle cx="65" cy="65" r="60" fill="none" stroke="#009688" strokeWidth="8" />
            <circle
              cx="65"
              cy="65"
              r="60"
              fill="none"
              stroke="#52d4f2"
              strokeWidth="8"
              strokeDasharray={2 * Math.PI * 60}
              strokeDashoffset={2 * Math.PI * 60 * (1 - (eduPoints - level.ep) / (nextLevel.ep - level.ep))}
              transform="rotate(-90 65 65)"
            />
          </svg>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            {React.createElement(level.icon, { size: 32, className: "text-eduvibe-green mx-auto mb-2" })}
            <p className="text-lg font-bold">{level.name}</p>
            <p className="text-sm">{eduPoints} EP</p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <p className="text-lg mb-2">Level: <span className="font-bold">{level.number + 1}</span></p>
        <p className="text-lg mb-2">Next level: <span className="font-bold">{nextLevel.name}</span></p>
        <p className="text-sm">{nextLevel.ep - eduPoints} EduPoints needed to level up</p>
        <p className="text-lg mt-4">Current Streak: <span className="font-bold text-eduvibe-green">{streakDays} days</span></p>
      </div>
    </div>
  );

  const RecentVibes = () => (
    <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
      <h2 className="text-2xl font-bold text-eduvibe-green mb-4">Continue Learning</h2>
      <RecentlyListenedHeader />
      <AlbumRow albums={recentlyPlayed} onAlbumSelect={handleAlbumSelect} />
      <button
        onClick={() => navigate('/browse')}
        className="mt-4 bg-eduvibe-green text-eduvibe-dark px-6 py-2 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
      >
        Explore More Songs
      </button>
    </div>
  );

  const LearningTips = () => (
    <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
      <h2 className="text-2xl font-bold text-eduvibe-green mb-4 flex items-center">
        <Lightbulb className="mr-2" size={24} />
        EduVibz Learning Tips
      </h2>
      <div className="relative h-48">
        <button 
          onClick={prevTip} 
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-eduvibe-green text-eduvibe-dark p-2 rounded-full z-10 hover:bg-eduvibe-blue transition-colors duration-300"
          >
            <ChevronLeft size={24} />
          </button>
          <motion.div
            key={currentTipIndex}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="text-center px-12 absolute inset-0 flex flex-col justify-center"
          >
            <p className="text-lg">{learningTips[currentTipIndex]}</p>
          </motion.div>
          <button 
            onClick={nextTip} 
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-eduvibe-green text-eduvibe-dark p-2 rounded-full z-10 hover:bg-eduvibe-blue transition-colors duration-300"
          >
            <ChevronRight size={24} />
          </button>
        </div>
        <div className="flex justify-center mt-4">
          {learningTips.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full mx-1 ${
                index === currentTipIndex ? 'bg-eduvibe-green' : 'bg-gray-400'
              }`}
            />
          ))}
        </div>
      </div>
    );
  
    const ActiveChallenges = () => (
      <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
        <h2 className="text-2xl font-bold text-eduvibe-green mb-4">Active Challenges</h2>
        {activeChallenges.length > 0 ? (
          <div className="relative">
            <button onClick={prevChallenge} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-eduvibe-green text-eduvibe-dark p-2 rounded-full">
              <ChevronLeft size={24} />
            </button>
            <div className="text-center px-12">
              <div className="flex justify-center items-center mb-2">
                {renderChallengeIcon(activeChallenges[currentChallengeIndex].challengeId.icon)}
              </div>
              <h3 className="text-xl font-semibold mb-2">{activeChallenges[currentChallengeIndex].challengeId.title}</h3>
              <p className="mb-4">{activeChallenges[currentChallengeIndex].challengeId.description}</p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div 
                  className="bg-eduvibe-green h-2.5 rounded-full" 
                  style={{width: `${activeChallenges[currentChallengeIndex].progress}%`}}
                ></div>
              </div>
              <p className="mt-2">{activeChallenges[currentChallengeIndex].progress.toFixed(2)}% complete</p>
            </div>
            <button onClick={nextChallenge} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-eduvibe-green text-eduvibe-dark p-2 rounded-full">
              <ChevronRight size={24} />
            </button>
          </div>
        ) : (
          <p>Challenge drought 🌵 Level up your profile to reveal what’s waiting!</p>
        )}
      </div>
    );
  
    const Achievements = ({ achievements }) => (
      <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
        <h2 className="text-2xl font-bold text-eduvibe-green mb-4">Recent Achievements</h2>
        {achievements.length > 0 ? (
          <ul className="space-y-4">
            {achievements.map((achievement, index) => (
              <li key={index} className="bg-eduvibe-dark bg-opacity-50 rounded-lg p-4 flex items-center">
                <div className="flex-shrink-0 mr-4">
                  {achievement.badgeUrl ? (
                    <img src={achievement.badgeUrl} alt={achievement.name} className="w-16 h-16 object-contain" />
                  ) : (
                    <span className="text-4xl">{achievement.icon}</span>
                  )}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-eduvibe-green">{achievement.name}</h3>
                  <p className="text-sm text-gray-300">{achievement.description}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-300">Your achievement board is empty – let’s get those trophies rolling!🏆</p>
        )}
      </div>
    );
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
        <NorthernLights />
        <Header className="z-50 absolute top-0 left-0 right-0" />
        <motion.div 
          className="flex-grow flex flex-col overflow-hidden relative pt-16"
          initial="initial"
          animate="animate"
          variants={fadeIn}
        >
          {showPreferenceRibbon && (
            <div className="z-40 relative">
              <PreferenceRibbon onDismiss={() => setShowPreferenceRibbon(false)} />
            </div>
          )}
          <motion.main className="flex-grow overflow-y-auto scrollbar-hide z-10 p-8" variants={fadeIn}>
            <motion.h1 className="text-4xl font-bold text-eduvibe-green mb-8" variants={fadeIn}>
              Welcome back, {userName}!
            </motion.h1>
            <motion.div className="grid grid-cols-1 lg:grid-cols-3 gap-8" variants={fadeIn}>
              <motion.div className="lg:col-span-2 space-y-8" variants={fadeIn}>
                <motion.div variants={fadeIn}>
                  <LearningJourney />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <RecentVibes />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <LearningTips />
                </motion.div>
              </motion.div>
              <motion.div className="space-y-8" variants={fadeIn}>
                <motion.div variants={fadeIn}>
                  <Achievements achievements={achievements} />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <ActiveChallenges />
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.main>
        </motion.div>
      </div>
    );
  };
  
  export default Dashboard;