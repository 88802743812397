import React, { useState, useEffect } from 'react';
import NorthernLights from '../components/common/NorthernLights';
import Header from '../components/common/Header';
import { TrendingUp, Music, BookOpen, Clock, Award, Activity } from 'lucide-react';

const ProgressPage = () => {
  const [progress, setProgress] = useState({
    overallProgress: 0,
    songsLearned: 0,
    subjectsCovered: 0,
    hoursOfLearning: 0,
    recentActivity: [],
    achievements: [],
  });

  useEffect(() => {
    // Fetch progress data from API in a real application
    setProgress({
      overallProgress: 68,
      songsLearned: 42,
      subjectsCovered: 15,
      hoursOfLearning: 8.5,
      recentActivity: [
        { icon: '🎵', description: 'Completed "Photosynthesis Groove" in Biology', timeAgo: '2 hours ago' },
        { icon: '🏆', description: 'Earned "Math Maestro" badge', timeAgo: 'Yesterday' },
        { icon: '📚', description: 'Started new playlist "Literature Legends"', timeAgo: '3 days ago' },
      ],
      achievements: [
        { icon: '🧪', name: 'Science Savant' },
        { icon: '🔢', name: 'Math Maestro' },
        { icon: '📜', name: 'History Buff' },
        { icon: '🖋️', name: 'Literature Lover' },
        { icon: '🌍', name: 'Global Linguist' },
        { icon: '🎨', name: 'Creative Genius' },
      ],
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-eduvibe-green mb-8 flex items-center">
          <TrendingUp className="mr-4" /> Your Learning Progress
        </h1>

        <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg mb-8">
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Activity className="mr-2" /> Overall Progress
          </h2>
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-eduvibe-green bg-eduvibe-green bg-opacity-20">
                  Progress
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-eduvibe-green">
                  {progress.overallProgress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-eduvibe-green bg-opacity-20">
              <div style={{ width: `${progress.overallProgress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-eduvibe-green"></div>
            </div>
          </div>
          <p className="text-gray-300">You've completed {progress.overallProgress}% of your learning goals. Keep up the great work!</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg text-center">
            <Music className="w-8 h-8 text-eduvibe-green mb-2 mx-auto" />
            <h3 className="text-4xl font-bold text-eduvibe-green mb-2">{progress.songsLearned}</h3>
            <p className="text-gray-300">Songs Learned</p>
          </div>
          <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg text-center">
            <BookOpen className="w-8 h-8 text-eduvibe-green mb-2 mx-auto" />
            <h3 className="text-4xl font-bold text-eduvibe-green mb-2">{progress.subjectsCovered}</h3>
            <p className="text-gray-300">Subjects Covered</p>
          </div>
          <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg text-center">
            <Clock className="w-8 h-8 text-eduvibe-green mb-2 mx-auto" />
            <h3 className="text-4xl font-bold text-eduvibe-green mb-2">{progress.hoursOfLearning}</h3>
            <p className="text-gray-300">Hours of Learning</p>
          </div>
        </div>

        <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg mb-8">
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Activity className="mr-2" /> Recent Activity
          </h2>
          <ul className="space-y-4">
            {progress.recentActivity.map((activity, index) => (
              <li key={index} className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-2xl mr-3">{activity.icon}</span>
                  <span>{activity.description}</span>
                </div>
                <span className="text-sm text-gray-400">{activity.timeAgo}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Award className="mr-2" /> Achievements
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {progress.achievements.map((achievement, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl mb-2">{achievement.icon}</div>
                <p className="text-sm">{achievement.name}</p>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProgressPage;