import React, { useState, useEffect } from 'react';
import { Bell, Mail, Smartphone } from 'lucide-react';
import api from '../../services/api';
import { logger } from '../../utils/logger';

const NotificationSettings = ({ user, onUpdate }) => {
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(true);
  const [reminderFrequency, setReminderFrequency] = useState('daily');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/notifications/settings');
        const settings = response.data;
        
        // Only update state if we received values
        if (settings) {
          setEmailNotifications(settings.emailNotifications ?? true);
          setPushNotifications(settings.pushNotifications ?? true);
          setReminderFrequency(settings.reminderFrequency ?? 'daily');
        }
      } catch (error) {
        logger.error('Failed to fetch notification settings', { error });
        setError('Failed to load notification settings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/notifications/settings', {
        emailNotifications,
        pushNotifications,
        reminderFrequency
      });
      if (onUpdate) onUpdate();
      alert('Notification settings updated successfully!');
    } catch (error) {
      logger.error('Failed to update notification settings', { error });
      alert('Failed to update notification settings. Please try again.');
    }
  };

  if (isLoading) {
    return <div className="text-white">Loading notification settings...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-eduvibe-green">Notification Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={emailNotifications}
              onChange={(e) => setEmailNotifications(e.target.checked)}
              className="mr-2"
            />
            <Mail className="mr-2" /> Receive email notifications
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={pushNotifications}
              onChange={(e) => setPushNotifications(e.target.checked)}
              className="mr-2"
            />
            <Smartphone className="mr-2" /> Receive push notifications
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="reminderFrequency" className="text-lg font-bold mb-2 flex items-center">
            <Bell className="mr-2" /> Learning Reminder Frequency
          </label>
          <select
            id="reminderFrequency"
            value={reminderFrequency}
            onChange={(e) => setReminderFrequency(e.target.value)}
            className="w-full p-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg focus:outline-none focus:border-eduvibe-green text-white"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <button type="submit" className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors">
          Save Notification Settings
        </button>
      </form>
    </div>
  );
};

export default NotificationSettings;