// src/components/Music/MusicFooter.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';
import api, { userPool } from '../../services/api';
import { getStreamingUrl, streamSong, preloadSong, clearSongCache } from '../../services/musicService';
import { Play, Pause, SkipBack, SkipForward, Volume2, Book, ChevronDown, Loader } from 'lucide-react';

const MusicFooter = ({
  currentSong, 
  isPlaying, 
  onPlayPause, 
  onSongChange, 
  songs = [], 
  onTimeUpdate, 
  onSongEnd, 
  onSkip, 
  onReplay,
  getAudioRef,
  logListeningEvent,
  chapters,
  onChapterSelect,
  fetchChaptersBySubject
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(() => {
    // Get saved volume from localStorage or default to 80%
    const savedVolume = localStorage.getItem('musicPlayerVolume');
    // Ensure volume is between 0 and 1
    return savedVolume ? Math.min(Math.max(parseFloat(savedVolume), 0), 1) : 0.8;
  });
  const [autoplayBlocked, setAutoplayBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const lastTime = useRef(0);
  const progressBarRef = useRef(null);
  const [isChapterMenuOpen, setIsChapterMenuOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragProgress, setDragProgress] = useState(0);
  const [isVolumeDragging, setIsVolumeDragging] = useState(false);
  const volumeBarRef = useRef(null);
  const lastValidTime = useRef(null);
  const currentUrlRef = useRef(null);
  const isLoadingSource = useRef(false);
  const auth = useAuth();

  // Add a new state to force audio element recreation when needed
  const [audioKey, setAudioKey] = useState(0);

  useEffect(() => {
    if (audioRef.current && getAudioRef) {
      getAudioRef(audioRef.current);
    }
  }, [getAudioRef]);

  // Find the index of the current song in the songs array
  const currentSongIndex = songs.findIndex(song => song._id === currentSong?._id);
  
  // Determine the next song for preloading
  const nextSong = currentSongIndex >= 0 && currentSongIndex < songs.length - 1 
    ? songs[currentSongIndex + 1] 
    : null;

  const loadAudioSource = useCallback(async () => {
    if (!currentSong?._id || !audioRef.current || isLoadingSource.current) return;

    isLoadingSource.current = true;
    const wasPlaying = isPlaying;
    
    // Store the current song ID to prevent race conditions
    const loadingSongId = currentSong._id;
    
    try {
      // Get streaming URL with token
      const { url } = await getStreamingUrl(loadingSongId);
      const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';
      const fullUrl = `${baseUrl}${url}`;

      // Check if song changed during async operation
      if (currentSong._id !== loadingSongId) {
        logger.debug('Song changed during loading, aborting', {
          loadingSongId,
          currentSongId: currentSong._id
        });
        isLoadingSource.current = false;
        return;
      }

      // Don't reload if URL hasn't changed and source is valid
      if (currentUrlRef.current === fullUrl && audioRef.current.src && audioRef.current.src !== '' && 
          audioRef.current.error === null && audioRef.current.networkState !== audioRef.current.NETWORK_NO_SOURCE) {
        logger.debug('Audio source already loaded and valid, skipping reload', {
          songId: loadingSongId,
          url: fullUrl
        });
        isLoadingSource.current = false;
        
        // If we were playing, ensure we're still playing
        if (wasPlaying && audioRef.current.paused) {
          try {
            await audioRef.current.play();
          } catch (error) {
            if (error.name === 'NotAllowedError') {
              setAutoplayBlocked(true);
            }
            logger.warn('Error resuming playback of already loaded source', {
              error: error.message,
              songId: loadingSongId
            });
          }
        }
        return;
      }

      // Stream the song and get object URL
      const objectUrl = await streamSong(fullUrl);
      
      // Check again if song changed during streaming
      if (currentSong._id !== loadingSongId) {
        logger.debug('Song changed during streaming, aborting', {
          loadingSongId,
          currentSongId: currentSong._id
        });
        isLoadingSource.current = false;
        return;
      }

      // Update audio source
      audioRef.current.src = objectUrl;
      currentUrlRef.current = fullUrl;
      
      // If we were playing, attempt to resume
      if (wasPlaying) {
        try {
          await audioRef.current.play();
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            setAutoplayBlocked(true);
          }
          logger.warn('Error starting playback after loading new source', {
            error: error.message,
            songId: loadingSongId
          });
        }
      }

      // Preload next song if available
      if (nextSong) {
        preloadSong(nextSong._id).catch(error => {
          logger.warn('Error preloading next song', {
            error: error.message,
            nextSongId: nextSong._id
          });
        });
      }
    } catch (error) {
      logger.error('Error loading audio source', {
        error: error.message,
        songId: loadingSongId
      });
      setIsLoading(false);
    } finally {
      isLoadingSource.current = false;
    }
  }, [currentSong, isPlaying, nextSong, setAutoplayBlocked]);

  // Load audio source when song changes
  useEffect(() => {
    if (currentSong?._id) {
      // Store the current song ID to prevent race conditions
      const currentSongId = currentSong._id;
      
      // Add a small delay before loading to ensure component is fully mounted
      setTimeout(() => {
        // Check if the component is still mounted with the same song
        if (currentSong?._id === currentSongId) {
          // Reset loading state to ensure we can load
          isLoadingSource.current = false;
          
          loadAudioSource().then(() => {
            // Check if the song has changed during loading
            if (currentSong?._id !== currentSongId) {
              logger.debug('Song changed during loading, skipping further processing', {
                loadedSongId: currentSongId,
                currentSongId: currentSong?._id
              });
              return;
            }
          }).catch(error => {
            logger.error('Error in loadAudioSource promise chain', {
              error: error.message,
              songId: currentSongId
            });
            
            // Reset loading state if there was an error
            isLoadingSource.current = false;
          });
        }
      }, 100); // Increased from 50ms to 100ms for more reliable loading
    }
  }, [currentSong, loadAudioSource]);

  // Preload next song when current song is loaded
  useEffect(() => {
    if (nextSong && nextSong._id && !isLoading && currentSong?._id) {
      preloadSong(nextSong._id);
    }
  }, [nextSong, currentSong, isLoading]);

  // Define all the event handlers
  const handleError = useCallback((e) => {
    // Only log errors if we have a current song
    if (!currentSong?._id) return;
    
    const audio = audioRef.current;
    if (!audio) return;
    
    // Get detailed error information
    const errorInfo = {
      error: e.target.error ? {
        code: e.target.error.code,
        message: e.target.error.message,
        name: e.target.error.name
      } : 'Unknown error',
      networkState: audio.networkState,
      readyState: audio.readyState,
      currentSrc: audio.currentSrc,
      paused: audio.paused,
      songId: currentSong._id,
      user: auth?.user?.sub,
      environment: process.env.NODE_ENV
    };
    
    // Log the error with detailed diagnostics
    logger.error('Audio element error', errorInfo);
    
    // Handle specific error codes
    if (e.target.error) {
      switch (e.target.error.code) {
        case MediaError.MEDIA_ERR_ABORTED:
          logger.error('Audio playback aborted', errorInfo);
          break;
        case MediaError.MEDIA_ERR_NETWORK:
          logger.error('Audio network error', errorInfo);
          // Attempt to reload the source after a short delay
          setTimeout(() => {
            if (currentSong?._id === errorInfo.songId && !isLoadingSource.current) {
              logger.debug('Attempting to reload audio after network error', {
                songId: currentSong._id
              });
              loadAudioSource();
            }
          }, 2000);
          break;
        case MediaError.MEDIA_ERR_DECODE:
          logger.error('Audio decode error', errorInfo);
          break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          logger.error('Audio source not supported', errorInfo);
          // For source not supported errors, try to clear cache and reload
          setTimeout(() => {
            if (currentSong?._id === errorInfo.songId && !isLoadingSource.current) {
              logger.debug('Attempting to reload audio after source not supported error', {
                songId: currentSong._id
              });
              // Clear the cache for this song specifically
              if (currentUrlRef.current) {
                if (audioRef.current._objectUrl) {
                  try {
                    URL.revokeObjectURL(audioRef.current._objectUrl);
                  } catch (error) {
                    logger.warn('Error revoking object URL', { error: error.message });
                  }
                  audioRef.current._objectUrl = null;
                }
                currentUrlRef.current = null;
              }
              loadAudioSource();
            }
          }, 500);
          break;
        default:
          logger.error('Unknown audio error', errorInfo);
      }
    }
    
    // Ensure we're not stuck in a loading state
    setIsLoading(false);
    
    // If we were playing, stop playback to prevent further errors
    if (isPlaying) {
      onPlayPause(false);
    }
  }, [currentSong, isPlaying, onPlayPause, loadAudioSource, auth?.user?.sub]);

  const handleLoadStart = useCallback(() => {
    setIsLoading(true);
    logger.debug('Audio loading started', {
      src: audioRef.current?.src,
      songId: currentSong?._id
    });
  }, [currentSong]);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      const duration = audioRef.current.duration;
      setDuration(duration);
      logger.debug('Audio metadata loaded', {
        duration,
        songId: currentSong?._id
      });
    }
  }, [currentSong]);

  const handleLoadedData = useCallback(() => {
    setIsLoading(false);
    logger.debug('Audio data loaded', {
      songId: currentSong?._id
    });
  }, [currentSong]);

  const handleCanPlay = useCallback(() => {
    setIsLoading(false);
    logger.debug('Audio can play', {
      songId: currentSong?._id,
      isPlaying: isPlaying,
      readyState: audioRef.current?.readyState
    });
    
    // If we're supposed to be playing, try to play now
    if (isPlaying && audioRef.current) {
      logger.debug('Attempting to play on canplay event', {
        songId: currentSong?._id
      });
      
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Successfully started playback
          logger.debug('Successfully started playback on canplay event', {
            songId: currentSong?._id
          });
          
          if (autoplayBlocked) {
            setAutoplayBlocked(false);
          }
        }).catch(error => {
          // Check if this is an autoplay restriction
          const isAutoplayBlocked = 
            error.name === 'NotAllowedError' || 
            error.message.includes('user gesture') ||
            error.message.includes('user interaction');
          
          if (isAutoplayBlocked) {
            logger.warn('Autoplay blocked by browser, waiting for user interaction', {
              error: error.message,
              songId: currentSong?._id
            });
            setAutoplayBlocked(true);
            
            // Notify parent component about autoplay being blocked
            // This ensures the UI reflects the actual state
            onPlayPause(false);
          } else {
            logger.error('Playback failed after canplay', {
              error: error.message,
              songId: currentSong?._id
            });
            // Notify parent component that playback failed
            onPlayPause(false);
          }
        });
      }
    }
  }, [currentSong, isPlaying, onPlayPause, autoplayBlocked]);

  // Handle time updates
  const handleTimeUpdate = useCallback((e) => {
    if (audioRef.current) {
      const time = audioRef.current.currentTime;
      setCurrentTime(time);
      
      // Only trigger replay if we're actively playing and time jumps backwards
      if (isPlaying && time < lastTime.current && lastTime.current - time > 1) {
        onReplay?.(lastTime.current, time);
      }
      lastTime.current = time;

      // Call parent's onTimeUpdate if provided
      if (onTimeUpdate) {
        onTimeUpdate(e);
      }
    }
  }, [isPlaying, onReplay, onTimeUpdate]);

  // Handle song end
  const handleSongEnd = useCallback(() => {
    logger.debug('Song ended', { 
      songId: currentSong?._id,
      isPlaying: isPlaying 
    });
    setCurrentTime(0);
    
    if (onSongEnd) {
      onSongEnd();
    }
    
    // When a song ends naturally, we always want to play the next song
    // regardless of the current isPlaying state
    handleNextSong();
  }, [currentSong, isPlaying, onSongEnd]);

  // Now set up the event listeners after all handlers are defined
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }
    
    // Set up event listeners for the audio element
    const audio = audioRef.current;
    
    // Add event listeners
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleSongEnd);
    audio.addEventListener('error', handleError);
    audio.addEventListener('loadstart', handleLoadStart);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('loadeddata', handleLoadedData);
    audio.addEventListener('canplay', handleCanPlay);
    
    // Set audio ref for parent component if needed
    if (getAudioRef) {
      getAudioRef(audio);
    }
    
    // Clean up event listeners on unmount
    return () => {
      if (audio) {
        try {
          audio.removeEventListener('timeupdate', handleTimeUpdate);
          audio.removeEventListener('ended', handleSongEnd);
          audio.removeEventListener('error', handleError);
          audio.removeEventListener('loadstart', handleLoadStart);
          audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
          audio.removeEventListener('loadeddata', handleLoadedData);
          audio.removeEventListener('canplay', handleCanPlay);
        } catch (error) {
          logger.warn('Error removing event listeners', { error: error.message });
        }
      }
    };
  }, [audioKey, handleTimeUpdate, handleSongEnd, handleError, handleLoadStart, handleLoadedMetadata, handleLoadedData, handleCanPlay, getAudioRef]);

  // Handle play/pause state changes
  useEffect(() => {
    if (!audioRef.current || isLoadingSource.current) return;

    logger.debug('Play state changed', {
      isPlaying,
      songId: currentSong?._id,
      readyState: audioRef.current?.readyState,
      autoplayBlocked
    });

    if (isPlaying) {
      // Only attempt to play if the audio is ready
      if (audioRef.current.readyState >= 2) {
        logger.debug('Attempting to play due to play state change', {
          songId: currentSong?._id,
          readyState: audioRef.current.readyState
        });
        
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Successfully played, clear autoplay blocked state
            logger.debug('Successfully played after play state change', {
              songId: currentSong?._id
            });
            
            if (autoplayBlocked) {
              setAutoplayBlocked(false);
            }
          }).catch(error => {
            // Check if this is an autoplay restriction
            const isAutoplayBlocked = 
              error.name === 'NotAllowedError' || 
              error.message.includes('user gesture') ||
              error.message.includes('user interaction');
            
            if (isAutoplayBlocked) {
              logger.warn('Autoplay blocked by browser, waiting for user interaction', {
                error: error.message,
                songId: currentSong?._id
              });
              setAutoplayBlocked(true);
            } else {
              logger.error('Playback failed', {
                error: error.message,
                songId: currentSong?._id,
                readyState: audioRef.current?.readyState,
                networkState: audioRef.current?.networkState
              });
              // Only notify parent of non-autoplay errors
              onPlayPause(false);
            }
          });
        }
      } else {
        // Audio not ready yet, we'll rely on the canplay event
        logger.debug('Audio not ready for playback yet', {
          readyState: audioRef.current?.readyState,
          songId: currentSong?._id
        });
      }
    } else {
      audioRef.current.pause();
      // Don't clear autoplay blocked state when pausing if it was due to autoplay restrictions
      // This allows the play button to work properly when clicked
    }
  }, [isPlaying, currentSong, onPlayPause, autoplayBlocked]);

  useEffect(() => {
    // Ensure volume is between 0 and 1 before setting it
    const safeVolume = Math.min(Math.max(volume, 0), 1);
    
    if (audioRef.current) {
      audioRef.current.volume = safeVolume;
    }
    
    localStorage.setItem('musicPlayerVolume', safeVolume.toString());
    logger.debug('Volume saved', { volume: Math.round(safeVolume * 100) });
  }, [volume]);

  const handlePlayPause = () => {
    if (!audioRef.current) {
      logger.warn('Audio reference not found when attempting to play/pause');
      return;
    }

    logger.debug('Play/pause button clicked', {
      currentState: isPlaying ? 'playing' : 'paused',
      songId: currentSong?._id,
      readyState: audioRef.current?.readyState
    });

    // If we're currently paused, attempt to play
    if (!isPlaying) {
      // First set the state to playing
      onPlayPause(true);
      
      // Then try to play the audio directly
      if (audioRef.current.readyState >= 2) {
        logger.debug('Attempting to play due to user interaction', {
          songId: currentSong?._id,
          readyState: audioRef.current.readyState
        });
        
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Successfully played
            if (autoplayBlocked) {
              setAutoplayBlocked(false);
            }
            logger.debug('Successfully played audio after user interaction');
          }).catch(error => {
            logger.error('Could not play after user interaction', {
              error: error.message,
              songId: currentSong?._id
            });
            // If we still can't play, update the UI to reflect that
            onPlayPause(false);
          });
        }
      } else {
        logger.debug('Audio not ready yet, waiting for canplay event');
      }
    } else {
      // If we're currently playing, pause
      audioRef.current.pause();
      onPlayPause(false);
      
      // Clear autoplay blocked state when explicitly paused
      if (autoplayBlocked) {
        setAutoplayBlocked(false);
      }
    }
  };

  const getCoverImage = (song) => {
    return song?.chapter?.coverImage || song?.album?.coverImage || '/default-cover.jpg';
  };

  const getCoverTitle = (song) => {
    return song?.chapter?.name || song?.album?.title || 'Unknown Album';
  };

  const handleSeekMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    updateSeekPosition(e);
  };

  const handleSeekMouseUp = () => {
    if (isDragging && lastValidTime.current !== null) {
      const newTime = lastValidTime.current;
      if (audioRef.current && isFinite(newTime)) {
        audioRef.current.currentTime = newTime;
        onSkip(newTime - currentTime);
      }
    }
    setIsDragging(false);
    lastValidTime.current = null;
  };

  const updateSeekPosition = (e) => {
    if (!progressBarRef.current || !duration) return;
    
    const rect = progressBarRef.current.getBoundingClientRect();
    const clickX = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
    const progress = (clickX / rect.width) * 100;
    const newTime = (progress / 100) * duration;
    
    if (isFinite(newTime)) {
      lastValidTime.current = newTime;
      setDragProgress(progress);
      logger.debug('Progress changed manually', {
        songId: currentSong?._id,
        newProgress: Math.round(progress)
      });
    }
  };

  const handleVolumeMouseDown = (e) => {
    e.preventDefault();
    setIsVolumeDragging(true);
    updateVolumePosition(e);
  };

  const updateVolumePosition = (e) => {
    if (!volumeBarRef.current) return;
    const rect = volumeBarRef.current.getBoundingClientRect();
    const clickX = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
    const newVolume = clickX / rect.width;
    
    // Ensure volume is between 0 and 1
    const safeVolume = Math.min(Math.max(newVolume, 0), 1);
    setVolume(safeVolume);
    
    logger.debug('Volume changed', {
      songId: currentSong?._id,
      newVolume: Math.round(safeVolume * 100)
    });
  };

  const handleVolumeMouseUp = () => {
    setIsVolumeDragging(false);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        updateSeekPosition(e);
      }
      if (isVolumeDragging) {
        updateVolumePosition(e);
      }
    };

    const handleMouseUp = () => {
      handleSeekMouseUp();
      if (isVolumeDragging) {
        handleVolumeMouseUp();
      }
    };

    if (isDragging || isVolumeDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isVolumeDragging, duration]);

  const handleNextSong = () => {
    if (!songs || songs.length === 0 || !currentSong) return;
    
    // Store current play state before changing songs
    const currentPlayState = isPlaying;
    
    const currentIndex = songs.findIndex(song => song._id === currentSong._id);
    if (currentIndex === -1) return;
    
    const nextIndex = (currentIndex + 1) % songs.length;
    const nextSong = songs[nextIndex];
    
    // If cycling back to first song, handle it specially
    if (nextIndex === 0) {
      logger.debug('Cycling back to first song, clearing song cache', {
        currentSongId: currentSong._id,
        nextSongId: nextSong._id,
        currentPlayState
      });
      
      // First pause playback to avoid race conditions
      if (audioRef.current) {
        try {
          audioRef.current.pause();
        } catch (error) {
          logger.warn('Error pausing audio before cycling to first song', {
            error: error.message
          });
        }
      }
      
      // Clear song cache and reset URL reference
      clearSongCache();
      currentUrlRef.current = null;
      
      // Reset loading state
      isLoadingSource.current = false;
      
      // Force audio element recreation by changing the key
      setAudioKey(prevKey => prevKey + 1);
      
      // Use a timeout to ensure state is reset before changing song
      setTimeout(() => {
        if (onSongChange) {
          // Pass the current play state to maintain it
          onSongChange(nextSong, 'next', currentPlayState);
        }
      }, 100);
      
      return; // Exit early to let the timeout handle it
    }
    
    logger.debug('Playing next song', {
      currentSongId: currentSong._id,
      nextSongId: nextSong._id,
      currentPlayState
    });

    if (onSongChange) {
      // Pass the current play state to maintain it
      onSongChange(nextSong, 'next', currentPlayState);
    }
  };

  const handlePrevSong = () => {
    if (!songs || songs.length === 0 || !currentSong) return;
    
    // Check if the current song has played for more than 10 seconds
    if (audioRef.current && audioRef.current.currentTime > 10) {
      // If more than 10 seconds, restart the current song
      logger.debug('Restarting current song (played > 10s)', {
        songId: currentSong._id,
        currentTime: audioRef.current.currentTime
      });
      
      audioRef.current.currentTime = 0;
      
      // If not already playing, start playback
      if (!isPlaying) {
        onPlayPause(true);
      }
    } else {
      // Store current play state before changing songs
      const currentPlayState = isPlaying;
      
      // If less than 10 seconds, go to previous song
      const currentIndex = songs.findIndex(song => song._id === currentSong._id);
      if (currentIndex === -1) return;
      
      const prevIndex = (currentIndex - 1 + songs.length) % songs.length;
      const prevSong = songs[prevIndex];
      
      // If cycling to last song, clear the song cache completely
      if (prevIndex === songs.length - 1) {
        logger.debug('Cycling to last song, clearing song cache', {
          currentSongId: currentSong._id,
          prevSongId: prevSong._id,
          currentPlayState
        });
        
        // Clear song cache and reset URL reference
        clearSongCache();
        currentUrlRef.current = null;
        
        // If we have an audio element, reset its source
        if (audioRef.current) {
          audioRef.current.src = '';
        }
      }
      
      logger.debug('Playing previous song', {
        currentSongId: currentSong._id,
        prevSongId: prevSong._id,
        currentTime: audioRef.current?.currentTime || 0,
        currentPlayState
      });
      
      // Pass the current play state to maintain it
      onSongChange(prevSong, 'prev', currentPlayState);
    }
  };

  const formatTime = (time) => {
    if (!isFinite(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const toggleChapterMenu = () => {
    setIsChapterMenuOpen(!isChapterMenuOpen);
    if (!isChapterMenuOpen && currentSong && currentSong.chapter && currentSong.chapter.subject) {
      fetchChaptersBySubject(currentSong.chapter.subject);
    }
  };

  const handleChapterSelect = (chapterId) => {
    onChapterSelect(chapterId);
    setIsChapterMenuOpen(false);
  };

  const handleFooterClick = useCallback(() => {
    if (autoplayBlocked && audioRef.current) {
      logger.debug('User interaction detected, attempting to play audio');
      
      // If we're already in a "playing" state according to the parent component
      if (isPlaying) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            setAutoplayBlocked(false);
            logger.debug('Successfully played audio after user interaction with footer');
          }).catch(error => {
            logger.error('Playback still failed after user interaction with footer', {
              error: error.message
            });
            // If we still can't play, update the UI to reflect that
            onPlayPause(false);
          });
        }
      }
    }
  }, [autoplayBlocked, isPlaying, onPlayPause]);

  const renderPlayPauseButton = () => {
    if (isLoading) {
      return (
        <button 
          className="p-2 rounded-full bg-primary text-white hover:bg-primary-dark transition-colors"
          disabled
        >
          <Loader className="animate-spin" size={24} />
        </button>
      );
    }
    
    if (!isPlaying) {
      // More prominent play button when not playing
      return (
        <button 
          onClick={() => onPlayPause(true)}
          className="p-3 rounded-full bg-eduvibe-green text-eduvibe-dark hover:bg-eduvibe-blue transition-colors shadow-lg"
          aria-label="Play"
        >
          <Play size={28} />
        </button>
      );
    }
    
    // Regular pause button when playing
    return (
      <button 
        onClick={() => onPlayPause(false)}
        className="p-2 rounded-full bg-primary text-white hover:bg-primary-dark transition-colors"
        aria-label="Pause"
      >
        <Pause size={24} />
      </button>
    );
  };

  if (!currentSong) return null;

  return (
    <footer className="bg-eduvibe-dark text-white fixed bottom-0 left-0 right-0 z-20 w-full" onClick={handleFooterClick}>
      <div className="relative">
        <div
          ref={progressBarRef}
          className="bg-gray-600 h-2 w-full cursor-pointer"
          onMouseDown={handleSeekMouseDown}
        >
          <div
            className="bg-eduvibe-green h-full transition-all duration-50"
            style={{ 
              width: `${isDragging ? dragProgress : (currentTime / duration) * 100 || 0}%`,
              transition: isDragging ? 'none' : 'width 0.1s linear'
            }}
          />
        </div>
        <div className="flex justify-between px-4 text-xs">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center w-1/4 min-w-0">
            <img 
              src={getCoverImage(currentSong)} 
              alt="Album cover" 
              className="w-12 h-12 object-cover rounded-md mr-2" 
            />
            <div className="hidden sm:block min-w-0">
              <h6 className="font-semibold truncate">{currentSong.title}</h6>
              <p className="text-sm text-gray-400 truncate">{getCoverTitle(currentSong)}</p>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-8">
            <SkipBack
              className="cursor-pointer bg-eduvibe-green text-eduvibe-dark rounded-full p-2 hover:bg-eduvibe-blue transition-colors min-w-9 min-h-9"
              onClick={handlePrevSong}
            />
            {renderPlayPauseButton()}
            <SkipForward
              className="cursor-pointer bg-eduvibe-green text-eduvibe-dark rounded-full p-2 hover:bg-eduvibe-blue transition-colors min-w-9 min-h-9"
              onClick={handleNextSong}
            />
          </div>
          <div className="flex items-center justify-end w-1/4 space-x-4 relative">
            <div 
              ref={volumeBarRef}
              className="w-24 bg-gray-600 rounded-full h-1 relative hidden sm:block cursor-pointer"
              onMouseDown={handleVolumeMouseDown}
            >
              <div
                className="absolute top-0 left-0 bg-eduvibe-green h-full rounded-full transition-all duration-100"
                style={{ width: `${volume * 100}%` }}
              />
              <div 
                className="absolute top-1/2 transform -translate-y-1/2 w-4 h-4 bg-eduvibe-green rounded-full shadow-md"
                style={{ 
                  left: `${volume * 100}%`,
                  transform: 'translate(-50%, -50%)',
                  cursor: isVolumeDragging ? 'grabbing' : 'grab'
                }}
              />
            </div>
            <button
              onClick={toggleChapterMenu}
              className="bg-eduvibe-green text-eduvibe-dark p-2 rounded-full hover:bg-eduvibe-blue transition duration-300 flex items-center justify-center"
              title="Select Chapter"
            >
              <Book size={20} className="mr-1" />
              <ChevronDown size={20} />
            </button>
            {isChapterMenuOpen && chapters && chapters.length > 0 && (
              <div className="absolute bottom-16 right-0 bg-eduvibe-dark border border-gray-600 rounded-md shadow-lg max-h-60 overflow-y-auto z-30 w-48">
                {chapters.map((chapter) => (
                  <div
                    key={chapter._id}
                    onClick={() => handleChapterSelect(chapter._id)}
                    className="px-4 py-2 hover:bg-eduvibe-blue cursor-pointer"
                  >
                    <img 
                      src={chapter.coverImage} 
                      alt="Album cover" 
                      className="w-12 h-12 object-cover rounded-md mr-2" 
                    />{chapter.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Hidden audio element for HTML5 Audio API access */}
      <audio 
        ref={audioRef} 
        style={{ display: 'none' }} 
        key={audioKey}
      />
    </footer>
  );
};

export default MusicFooter;