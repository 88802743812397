// src/services/api.js

import axios from 'axios';
import { 
  CognitoUserPool, 
  CognitoUser, 
  AuthenticationDetails, 
  CognitoUserAttribute 
} from 'amazon-cognito-identity-js';
import { logger } from '../utils/logger'; // Assuming logger path is correct

// --- Configuration ---

// Use REACT_APP_API_URL if set, otherwise default to local dev URL
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
// Add console log for debugging builds/deployment configuration
console.log(`[EduVibz API Config] Using API Base URL: ${apiUrl}`); 

// Ensure the API URL has the /api prefix for production
const baseURL = process.env.NODE_ENV === 'production' && !apiUrl.endsWith('/api') 
  ? `${apiUrl}/api` 
  : apiUrl;

console.log(`[EduVibz API Config] Final Base URL with prefix: ${baseURL}`);

const api = axios.create({
  baseURL: baseURL, // Use the configured baseURL with /api prefix
  withCredentials: true, // Send cookies with requests (important for sessions)
});

// Check if essential Cognito env vars are present
const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

// Log Cognito configuration for easier debugging in browser console
console.log('[EduVibz API Config] Cognito User Pool ID used:', cognitoUserPoolId || 'MISSING!');
console.log('[EduVibz API Config] Cognito Client ID used:', cognitoClientId || 'MISSING!');

if (!cognitoUserPoolId || !cognitoClientId) {
  const errorMsg = 'CRITICAL: Cognito User Pool ID or Client ID is missing in environment variables. Check Cloudflare Pages settings.';
  logger.error(errorMsg);
  console.error(errorMsg);
  // Optionally, you could throw an error here to halt execution if Cognito is essential immediately
  // throw new Error(errorMsg);
}

const poolData = {
  UserPoolId: cognitoUserPoolId,
  ClientId: cognitoClientId
};

// Initialize Cognito User Pool *only if* config is present
const userPool = cognitoUserPoolId && cognitoClientId ? new CognitoUserPool(poolData) : null;

if (!userPool) {
  logger.error("Cognito User Pool could not be initialized due to missing configuration.");
  console.error("Cognito User Pool could not be initialized due to missing configuration.");
}


// --- Cognito Authentication Functions ---

const signUp = async (fullName, email, password, planId) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'name', Value: fullName }),
      // Ensure 'custom:planId' is defined as a custom attribute in your Cognito User Pool settings
      new CognitoUserAttribute({ Name: 'custom:planId', Value: planId || 'beta' }) // Default plan if needed
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        logger.error('Cognito signUp failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
        return;
      }
      resolve(result.user);
    });
  });
};

const confirmSignUp = async (email, code) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, async (err, result) => {
      if (err) {
        logger.error('Cognito email verification failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
        return;
      }

      try {
        // After successful Cognito verification, update our backend
        logger.info('Cognito verification success, attempting backend verification', { email: `***@${email.split('@')[1]}` });
        const response = await api.post('/auth/verify-email', { email, code });
        resolve(response.data);
      } catch (backendError) {
        logger.error('Backend verification call failed after Cognito success', {
          error: backendError.response?.data || backendError.message || backendError,
          status: backendError.response?.status,
          email: `***@${email.split('@')[1]}`
        });
        // Reject with the backend error as it's the final step
        reject(backendError);
      }
    });
  });
};

const resendConfirmationCode = (email) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        logger.error('Cognito resend code failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

const signIn = (email, password) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        try {
          const idToken = session.getIdToken();
          const accessToken = session.getAccessToken(); // Might need this too depending on backend API GW auth
          const refreshToken = session.getRefreshToken();

          if (!idToken || !refreshToken) {
             throw new Error("Session tokens are missing.");
          }

          const token = idToken.getJwtToken();
          const refreshTokenString = refreshToken.getToken();
          
          const payload = idToken.payload;
          const user = {
            email: payload.email,
            name: payload.name,
            cognitoSub: payload.sub,
            // Extract custom attributes safely
            planId: payload['custom:planId'] || 'beta', // Default if not present
            // Add other relevant user details from payload if needed
            // Example: isActive, isAccountHolder might come from your backend /auth/me call later
          };

          // Store tokens securely (e.g., localStorage, sessionStorage, or memory)
          // Be mindful of security implications of localStorage
          localStorage.setItem('token', token); // Example: Storing ID token
          localStorage.setItem('refreshToken', refreshTokenString); // Example: Storing Refresh token

          logger.info('Cognito sign in successful', { email: user.email });
          resolve({ token, refreshToken: refreshTokenString, user });

        } catch (tokenError) {
           logger.error('Error processing Cognito session tokens', { error: tokenError.message, email: `***@${email.split('@')[1]}` });
           reject(tokenError);
        }
      },
      onFailure: (err) => {
        logger.error('Cognito sign in failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
      }
      // Add MFA/New Password required handlers if configured in Cognito
      // newPasswordRequired: (userAttributes, requiredAttributes) => { ... }
      // mfaRequired: (codeDeliveryDetails) => { ... }
    });
  });
};

const forgotPassword = (email) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  // Renaming to align with export name requestPasswordReset
  return new Promise((resolve, reject) => {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function(err) {
        logger.error('Cognito forgotPassword failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
      }
    });
  });
};

const confirmPassword = (email, verificationCode, newPassword) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  // Used for resetting password after forgotPassword flow
  return new Promise((resolve, reject) => {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        logger.error('Cognito confirmPassword (reset) failed', { error: err.message, email: `***@${email.split('@')[1]}` });
        reject(err);
      }
    });
  });
};

// --- Application Specific API Calls ---

const sendListeningEvent = (eventData) => {
  // Destructure to handle potential missing duration
  const { duration, currentTime, ...rest } = eventData;
  // Use currentTime as a fallback if duration is not a valid number
  const validDuration = (typeof duration === 'number' && !isNaN(duration)) ? duration : currentTime;

  return api.post('/listening-events', {
    ...rest,
    duration: validDuration,
    currentTime // Send currentTime regardless
  });
};

const logout = () => {
  // Client-side Cognito logout + Backend API call
  const cognitoUser = userPool?.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut(); // Clears Cognito session info from SDK storage
  }
  // Also clear local storage
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  logger.info('User logged out client-side.');

  // Optionally call backend logout endpoint if it does server-side session invalidation
  // return api.post('/auth/logout');
  return Promise.resolve(); // Return resolved promise after client logout
};


// Get user data from Cognito session
const getCurrentCognitoUser = () => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error('No Cognito user found'));
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err || !session || !session.isValid()) {
        // Include session invalidity in the rejection reason
        reject(err || new Error('Session is invalid or expired'));
        return;
      }

      // Optionally refresh the session if needed, or just use existing valid session
      // cognitoUser.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => { ... });

      cognitoUser.getUserAttributes((attributesErr, attributes) => {
        if (attributesErr) {
          reject(attributesErr);
          return;
        }

        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {});
        
        // Add JWT for potential use, but be careful exposing it
        // userData.idToken = session.getIdToken().getJwtToken();

        resolve(userData); // Contains attributes like email, name, sub, custom attributes
      });
    });
  });
};


// --- Axios Interceptors ---

// Request interceptor to add Authorization header
api.interceptors.request.use(
  async (config) => {
    // List of paths that definitely DON'T need auth
    const publicPaths = ['/curricula', '/grades', '/subjects', '/contact', '/health', '/cookie-policy']; 
    // Add any other public GET endpoints here

    const isPublicPath = publicPaths.some(path => config.url.startsWith(path));

    // Try to get user only if it's NOT a known public path
    if (!isPublicPath && userPool) { // Only proceed if pool exists and path might need auth
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
          return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
              if (err || !session || !session.isValid()) {
                logger.warn('No valid Cognito session found, but allowing request (might fail if auth needed).', { url: config.url });
                // For potentially protected paths, maybe reject? Or let backend handle missing token?
                // For now, let's resolve and let the request go without a token if session fails
                resolve(config); 
                // If you require auth strictly for non-public paths:
                // reject(new Error('Authentication required: No valid session.')); 
                // return; 
              } else {
                try {
                  const token = session.getIdToken().getJwtToken();
                  config.headers['Authorization'] = `Bearer ${token}`;
                  resolve(config);
                } catch (tokenError) {
                  logger.error('Error getting ID token from session.', { error: tokenError.message });
                  reject(tokenError); // Reject if token extraction fails
                }
              }
            });
          });
        } else {
           // No Cognito user logged in via SDK. Allow request to proceed without token.
           // Backend will decide if it's allowed.
           logger.info(`No logged-in user found via SDK for request: ${config.url}. Proceeding without auth header.`);
           return Promise.resolve(config);
        }
    } else {
      // It's a known public path OR userPool isn't configured. Allow request without attempting auth.
      console.log(`[API Interceptor] Path ${config.url} is public or Cognito not configured. Skipping auth header.`);
      return Promise.resolve(config); // MUST resolve config here
    }
  },
  (error) => {
    logger.error('Axios request interceptor error', { error: error.message });
    return Promise.reject(error);
  }
);


// Response interceptor for handling token refresh on 401
// Be careful with this - ensure your backend actually requires refresh for 401s
// and that your refresh endpoint works correctly.
api.interceptors.response.use(
  (response) => response, // Simply return successful responses
  async (error) => {
    const originalRequest = error.config;

    // Check if it's a 401 error, not from a refresh attempt itself, and a refresh token exists
    if (error.response?.status === 401 && !originalRequest._retry && localStorage.getItem('refreshToken')) {
      originalRequest._retry = true; // Mark to prevent infinite loops
      const storedRefreshToken = localStorage.getItem('refreshToken');

      try {
        logger.info('Attempting token refresh due to 401');
        // Call the refreshToken function (defined below or imported)
        const { token } = await refreshToken(storedRefreshToken); // Assumes refresh returns { token }

        // Update local storage and default header for subsequent requests
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // Update the header of the original failed request
        originalRequest.headers['Authorization'] = `Bearer ${token}`;

        logger.info('Token refreshed successfully, retrying original request.');
        // Retry the original request with the new token
        return api(originalRequest);

      } catch (refreshError) {
        logger.error('Token refresh failed, logging out user.', { 
          error: refreshError.response?.data || refreshError.message || refreshError, 
          status: refreshError.response?.status 
        });
        // If refresh fails, logout the user (clear tokens, redirect to login)
        logout(); // Call your logout function
        // Optionally redirect to login page
        // window.location.href = '/login'; 
        return Promise.reject(refreshError); // Reject with the refresh error
      }
    }
    
    // For errors other than 401 or if refresh shouldn't be attempted
    return Promise.reject(error);
  }
);

// --- API Function Definitions ---

// Music / Content
const fetchAlbums = () => api.get('/music/all');
const fetchAlbumById = (id) => api.get(`/music/${id}`);
const searchMusic = (params) => api.get('/music/search', { params });
const getRecentlyListened = () => api.get('/music/recently-listened');
const getChaptersBySubject = (subjectId) => {
  if (!subjectId) return Promise.reject(new Error('Subject ID is required'));
  return api.get(`/music/chapters-by-subject?subjectId=${subjectId}`);
};
const getChapterByNumber = (chapterNumber) => {
  if (!chapterNumber) return Promise.reject(new Error('Chapter number is required'));
  return api.get(`/music/chapters/${chapterNumber}`);
};
const getSongsByChapter = (chapterId) => {
  if (!chapterId) return Promise.reject(new Error('Chapter ID is required'));
  logger.info('Fetching songs for chapter', { chapterId });
  return api.get(`/music/songs-by-chapter/${chapterId}`);
};

// Curricula / Education Structure
const getCurricula = () => api.get('/curricula');
const getGrades = (curriculumId) => api.get(`/grades/${curriculumId}`);
const getSubjects = (gradeId) => api.get(`/subjects/${gradeId}`);

// User Data / Auth Backend Interaction (beyond Cognito direct calls)
const getUserData = () => api.get('/auth/me'); // Gets user data from *your* backend

// Contact Forms
const submitContactForm = (data) => api.post('/contact', { ...data, type: 'contact' });
const submitWaitlist = (data) => api.post('/contact', { ...data, type: 'waitlist' });
const submitFeatureRequest = (data) => api.post('/contact', { ...data, type: 'feature' });
const submitContribute = (data) => api.post('/contact', { ...data, type: 'contribute' });

// Playlists
const getPlaylists = () => api.get('/playlists');
const getPlaylist = (id) => api.get(`/playlists/${id}`); // Alias for getPlaylistById?
const createPlaylist = (playlistData) => api.post('/playlists', playlistData);
const updatePlaylist = (id, playlistData) => api.put(`/playlists/${id}`, playlistData);
const deletePlaylist = (id) => api.delete(`/playlists/${id}`);
const getPlaylistById = (playlistId) => api.get(`/playlists/${playlistId}`);
const addSongToPlaylist = (playlistId, songId) => api.post('/playlists/add-song', { playlistId, songId });
const removeSongFromPlaylist = (playlistId, songId) => api.post('/playlists/remove-song', { playlistId, songId });
const sharePlaylist = (playlistId, userEmail) => api.post('/playlists/share', { playlistId, userEmail });

// Challenges
const fetchUserChallenges = () => api.get('/challenges/user');
const updateChallengeProgress = (challengeId, progress) => api.post('/challenges/progress', { challengeId, progress });
const getAllChallenges = (params) => api.get('/challenges', { params });
const getChallenge = (id) => api.get(`/challenges/${id}`);
const createChallenge = (challengeData) => api.post('/challenges', challengeData);
const updateChallenge = (id, challengeData) => api.put(`/challenges/${id}`, challengeData);
const deleteChallenge = (id) => api.delete(`/challenges/${id}`);

// Static Content
const getCookiePolicy = () => api.get('/cookie-policy');

// Cognito Password Management (User initiated)
const changePassword = (oldPassword, newPassword) => {
  if (!userPool) return Promise.reject(new Error("Cognito not configured"));
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      reject(new Error('No user found')); return;
    }
    cognitoUser.getSession((err, session) => { // Get session to ensure user is authenticated
      if (err || !session) { reject(err || new Error('Not authenticated')); return; }
      cognitoUser.changePassword(oldPassword, newPassword, (changeErr, result) => {
        if (changeErr) {
           logger.error('Cognito changePassword failed', { error: changeErr.message });
           reject(changeErr); return;
        }
        resolve(result);
      });
    });
  });
};

// Re-exporting forgotPassword as requestPasswordReset for clarity if needed elsewhere
const requestPasswordReset = forgotPassword;

// Paystack Payment Functions (Interacting with your backend endpoints)
const initializePaystackPayment = async (email, planId) => {
  try {
    const response = await api.post('/paystack/initialize', { email, planId });
    return response.data;
  } catch (error) {
    logger.error('Payment initialization failed', { error: error.response?.data?.message || error.message });
    throw error; // Re-throw to be handled by calling component
  }
};

const verifyPaystackPayment = async (reference, planCode, isInitialCharge) => {
  try {
    // Ensure reference is included in the URL query parameter as expected by backend
    const response = await api.post(`/paystack/verify?reference=${encodeURIComponent(reference)}`, {
      planCode,
      isInitialCharge // Send these in the body
    });
    return response.data;
  } catch (error) {
    logger.error('Payment verification failed', { error: error.response?.data?.message || error.message });
    throw error;
  }
};

// Subscription Management
const getSubscriptionDetails = () => api.get('/subscriptions/details');


// --- Utility / Helper Functions ---

// Token Refresh Function (Called by interceptor)
const refreshToken = async (refreshTokenValue) => {
  if (!refreshTokenValue) {
     throw new Error("No refresh token available.");
  }
  try {
    // This endpoint needs to exist on your backend API
    const response = await api.post('/auth/refresh-token', { refreshToken: refreshTokenValue }); 
    if (!response.data || !response.data.token) {
        throw new Error("Invalid response from refresh token endpoint");
    }
    return response.data; // Expects backend to return { token: newIdToken, ... }
  } catch (error) {
    logger.error('API call to /auth/refresh-token failed', { 
       error: error.response?.data || error.message, 
       status: error.response?.status 
    });
    throw error; // Re-throw the error to be caught by the interceptor
  }
};

// Authentication Check (Simple check based on token presence)
const isAuthenticated = () => {
  // This is a basic check. A proper check might involve verifying token expiry
  // or making a quick call to a backend /auth/check endpoint.
  const token = localStorage.getItem('token'); 
  return !!token; 
};


// --- Exports ---

// Export the configured axios instance
export default api;

// Export individual functions for use in components/stores
export {
  // Cognito specific exports (consider namespacing these?)
  userPool, // Exporting userPool might not be needed externally
  signUp,
  confirmSignUp,
  resendConfirmationCode,
  signIn,
  forgotPassword, // Keep original name if used directly
  confirmPassword, // Used for password reset flow
  changePassword, // User initiated password change when logged in
  requestPasswordReset, // Clearer name for forgot password flow initiation
  getCurrentCognitoUser as getCurrentUser, // Export Cognito user getter

  // App specific API calls
  sendListeningEvent,
  logout,
  fetchAlbums,
  fetchAlbumById,
  searchMusic,
  getCurricula,
  getGrades,
  getSubjects,
  getRecentlyListened,
  getUserData, // Get data from *your* backend /auth/me
  submitContactForm,
  submitWaitlist,
  submitFeatureRequest,
  submitContribute,
  getPlaylists,
  getPlaylist,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  getPlaylistById,
  addSongToPlaylist,
  removeSongFromPlaylist,
  sharePlaylist,
  fetchUserChallenges,
  updateChallengeProgress,
  getAllChallenges,
  getChallenge,
  createChallenge,
  updateChallenge,
  deleteChallenge,
  getChaptersBySubject,
  getChapterByNumber,
  getSongsByChapter,
  getCookiePolicy,
  initializePaystackPayment,
  verifyPaystackPayment,
  getSubscriptionDetails,

  // Utilities / Helpers exported
  isAuthenticated,
  refreshToken, // Export refresh token function if needed elsewhere, otherwise keep internal

  // Export the instance if needed directly (rare)
  api as apiInstance 
};