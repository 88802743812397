// src/components/common/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-eduvibe-dark text-white py-8 z-50 relative">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4 text-eduvibe-green">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-eduvibe-green">About Us</Link></li>
              <li><Link to="/how-it-works" className="hover:text-eduvibe-green">How It Works</Link></li>
              <li><Link to="/pricing" className="hover:text-eduvibe-green">Pricing</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-eduvibe-green">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/faq" className="hover:text-eduvibe-green">FAQ</Link></li>
              <li><Link to="/contact" className="hover:text-eduvibe-green">Contact Us</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-eduvibe-green">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms-of-service" className="hover:text-eduvibe-green">Terms of Service</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-eduvibe-green">Privacy Policy</Link></li>
              <li><Link to="/cookie-policy" className="hover:text-eduvibe-green">Cookie Policy</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-eduvibe-green">Account</h3>
            <ul className="space-y-2">
              <li><Link to="/login" className="hover:text-eduvibe-green">Login</Link></li>
              <li><Link to="/pricing" className="hover:text-eduvibe-green">Register</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {currentYear} EduVibz. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;