import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import NorthernLights from '../components/common/NorthernLights';
import Header from '../components/common/Header';
import AccountSettings from '../components/settings/AccountSettings';
import LearningPreferences from '../components/settings/LearningPreferences';
import NotificationSettings from '../components/settings/NotificationSettings';
import SubscriptionManagement from '../components/settings/SubscriptionManagement';
import { Settings, User, Book, Bell, CreditCard } from 'lucide-react';
import api from '../services/api';

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('account');
  const { user, fetchUserData } = useAuth();
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await api.get('/user/preferences');
        setUserPreferences(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user preferences:', error);
        setLoading(false);
      }
    };

    fetchPreferences();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    switch (activeTab) {
      case 'account':
        return <AccountSettings userPreferences={userPreferences} onUpdate={fetchUserData} />;
      case 'preferences':
        return <LearningPreferences userPreferences={userPreferences} onUpdate={fetchUserData} />;
      case 'notifications':
        return <NotificationSettings user={user} onUpdate={fetchUserData} />;
      case 'subscription':
        return <SubscriptionManagement user={user} onUpdate={fetchUserData} />;
      default:
        return <AccountSettings userPreferences={userPreferences} onUpdate={fetchUserData} />;
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-eduvibe-green mb-8 flex items-center">
          <Settings className="mr-4" /> User Settings
        </h1>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <ul className="space-y-2">
              <li>
                <button
                  className={`w-full text-left p-2 rounded flex items-center ${activeTab === 'account' ? 'bg-eduvibe-green text-eduvibe-dark' : 'hover:bg-eduvibe-blue hover:bg-opacity-20'}`}
                  onClick={() => setActiveTab('account')}
                >
                  <User className="mr-2" /> Account Settings
                </button>
              </li>
              <li>
                <button
                  className={`w-full text-left p-2 rounded flex items-center ${activeTab === 'preferences' ? 'bg-eduvibe-green text-eduvibe-dark' : 'hover:bg-eduvibe-blue hover:bg-opacity-20'}`}
                  onClick={() => setActiveTab('preferences')}
                >
                  <Book className="mr-2" /> Learning Preferences
                </button>
              </li>
              <li>
                <button
                  className={`w-full text-left p-2 rounded flex items-center ${activeTab === 'notifications' ? 'bg-eduvibe-green text-eduvibe-dark' : 'hover:bg-eduvibe-blue hover:bg-opacity-20'}`}
                  onClick={() => setActiveTab('notifications')}
                >
                  <Bell className="mr-2" /> Notifications
                </button>
              </li>
              {user.isAccountHolder && (
                <li>
                  <button
                    className={`w-full text-left p-2 rounded flex items-center ${activeTab === 'subscription' ? 'bg-eduvibe-green text-eduvibe-dark' : 'hover:bg-eduvibe-blue hover:bg-opacity-20'}`}
                    onClick={() => setActiveTab('subscription')}
                  >
                    <CreditCard className="mr-2" /> Manage Subscription
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="w-full md:w-3/4 md:pl-8">
            <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
              {renderContent()}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SettingsPage;