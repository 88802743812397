import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, UserPlus, Lightbulb, Mail, Music, Mic, HeadphonesIcon, BanknoteIcon } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import api from '../services/api';
import { logger } from '../utils/logger';

const ContactUs = () => {
  const [formType, setFormType] = useState('contact');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    role: '',
    schoolName: '',
    feature: '',
    musicStyle: '',
    instrument: '',
    experience: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitError, setSubmitError] = useState('');

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setSubmitError(''); // Clear any previous error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');
    setSubmitError('');
  
    try {
      const response = await api.post('/contact', { ...formData, type: formType });
  
      if (response.data.success) {
        logger.info('Contact form submitted successfully');
        setSubmitMessage(response.data.message);
        setFormData({ 
          name: '', 
          email: '', 
          message: '', 
          role: '', 
          schoolName: '', 
          feature: '', 
          musicStyle: '', 
          instrument: '', 
          experience: '' 
        });
      } else {
        setSubmitError(response.data.error || 'Failed to submit form. Please try again.');
        logger.error('Contact form submission failed', { error: response.data.error });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'Something went wrong. Please try again.';
      logger.error('Contact form submission failed', { 
        error: errorMessage,
        type: formType,
        email: `***@${formData.email.split('@')[1]}`
      });
      setSubmitError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderForm = () => {
    switch (formType) {
      case 'contact':
        return (
          <>
            <div className="mb-6 text-center">
              <h3 className="text-2xl font-bold text-eduvibe-green mb-2">Let's Chat!</h3>
              <div className="space-y-4 text-left bg-eduvibe-blue bg-opacity-20 p-4 rounded-lg mb-6">
                <div className="flex items-center gap-3">
                  <Mail className="text-eduvibe-green flex-shrink-0" />
                  <p>Questions about our platform? We're here to help!</p>
                </div>
                <div className="flex items-center gap-3">
                  <UserPlus className="text-eduvibe-green flex-shrink-0" />
                  <p>Looking to partner with us? Let's explore possibilities together</p>
                </div>
                <div className="flex items-center gap-3">
                  <Lightbulb className="text-eduvibe-green flex-shrink-0" />
                  <p>Need support? Our team is ready to assist you</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-lg font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-lg font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-lg font-bold mb-2">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                rows="4"
                required
              ></textarea>
            </div>
          </>
        );
      case 'waitlist':
        return (
          <>
            <div className="mb-6 text-center">
              <h3 className="text-2xl font-bold text-eduvibe-green mb-2">Join Our Growing Community!</h3>
              <div className="space-y-4 text-left bg-eduvibe-blue bg-opacity-20 p-4 rounded-lg mb-6">
                <div className="flex items-center gap-3">
                  <UserPlus className="text-eduvibe-green flex-shrink-0" />
                  <p>Get notified when we launch the features or plans you’re interested in</p>
                </div>
                <div className="flex items-center gap-3">
                  <HeadphonesIcon className="text-eduvibe-green flex-shrink-0" />
                  <p>Be among the first to experience our latest features and plans</p>
                </div>
                <div className="flex items-center gap-3">
                  <BanknoteIcon className="text-eduvibe-green flex-shrink-0" />
                  <p>Special launch pricing for early adopters</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-lg font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-lg font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="role" className="block text-lg font-bold mb-2">I am a:</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              >
                <option value="">Select your role</option>
                <option value="student">Student</option>
                <option value="parent">Parent</option>
                <option value="teacher">Teacher</option>
                <option value="school_admin">School Administrator</option>
              </select>
            </div>
            {formData.role === 'teacher' || formData.role === 'school_admin' ? (
              <div className="mb-4">
                <label htmlFor="schoolName" className="block text-lg font-bold mb-2">School Name</label>
                <input
                  type="text"
                  id="schoolName"
                  name="schoolName"
                  value={formData.schoolName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                />
              </div>
            ) : null}
          </>
        );
      case 'feature':
        return (
          <>
            <div className="mb-6 text-center">
              <h3 className="text-2xl font-bold text-eduvibe-green mb-2">Help Shape EduVibz!</h3>
              <div className="space-y-4 text-left bg-eduvibe-blue bg-opacity-20 p-4 rounded-lg mb-6">
                <div className="flex items-center gap-3">
                  <Lightbulb className="text-eduvibe-green flex-shrink-0" />
                  <p>Have an idea that could make learning even more engaging?</p>
                </div>
                <div className="flex items-center gap-3">
                  <Music className="text-eduvibe-green flex-shrink-0" />
                  <p>Want to see specific musical features or learning tools?</p>
                </div>
                <div className="flex items-center gap-3">
                  <Send className="text-eduvibe-green flex-shrink-0" />
                  <p>Your feedback drives our innovation - we're all ears!</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-lg font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-lg font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="feature" className="block text-lg font-bold mb-2">Feature Request</label>
              <textarea
                id="feature"
                name="feature"
                value={formData.feature}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                rows="4"
                required
                placeholder="Describe the feature you'd like to see in EduVibz"
              ></textarea>
            </div>
          </>
        );
      case 'contribute':
        return (
          <>
            <div className="mb-6 text-center">
              <h3 className="text-2xl font-bold text-eduvibe-green mb-2">Join Our Creative Community!</h3>
              <div className="space-y-4 text-left bg-eduvibe-blue bg-opacity-20 p-4 rounded-lg mb-6">
                <div className="flex items-center gap-3">
                  <Mic className="text-eduvibe-green flex-shrink-0" />
                  <p>Create educational music that inspires the next generation</p>
                </div>
                <div className="flex items-center gap-3">
                  <HeadphonesIcon className="text-eduvibe-green flex-shrink-0" />
                  <p>Work with our innovative platform that combines education with music</p>
                </div>
                <div className="flex items-center gap-3">
                  <BanknoteIcon className="text-eduvibe-green flex-shrink-0" />
                  <p>Earn royalties every time your music helps a student learn</p>
                </div>
                <div className="flex items-center gap-3">
                  <Music className="text-eduvibe-green flex-shrink-0" />
                  <p>Be part of a revolutionary approach to educational content</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-lg font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-lg font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="musicStyle" className="block text-lg font-bold mb-2">Your Music Style</label>
              <input
                type="text"
                id="musicStyle"
                name="musicStyle"
                value={formData.musicStyle}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
                placeholder="e.g., Hip-Hop, Pop, Classical, Jazz, Electronic..."
              />
            </div>
            <div className="mb-4">
              <label htmlFor="instrument" className="block text-lg font-bold mb-2">Primary Instrument/Skills</label>
              <input
                type="text"
                id="instrument"
                name="instrument"
                value={formData.instrument}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                required
                placeholder="e.g., Producer, Vocalist, Guitarist, Multi-instrumentalist..."
              />
            </div>
            <div className="mb-4">
              <label htmlFor="experience" className="block text-lg font-bold mb-2">Tell Us About Your Journey</label>
              <textarea
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark bg-white bg-opacity-80 focus:bg-opacity-100 transition-all duration-300"
                rows="4"
                required
                placeholder="Share your musical background, experience, and why you'd like to create educational music with us!"
              ></textarea>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            Get in Touch
          </motion.h1>

          <motion.div
            className="max-w-2xl mx-auto bg-eduvibe-dark bg-opacity-80 p-4 md:p-8 rounded-3xl shadow-2xl"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <div className="mb-6">
              <div 
                className="flex overflow-x-auto md:justify-between no-scrollbar py-2 px-4 bg-eduvibe-blue bg-opacity-50 rounded-full gap-2 md:gap-4 max-w-2xl mx-auto"
                style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
              >
                {['contact', 'waitlist', 'feature', 'contribute'].map((type) => (
                  <button
                    key={type}
                    className={`flex-shrink-0 px-4 py-2 rounded-full transition-all duration-300 text-base flex items-center whitespace-nowrap ${
                      formType === type ? 'bg-eduvibe-green text-eduvibe-dark' : 'text-white hover:bg-eduvibe-blue hover:bg-opacity-75'
                    }`}
                    onClick={() => setFormType(type)}
                  >
                    {type === 'contact' && <Mail className="inline-block mr-2 w-5 h-5" />}
                    {type === 'waitlist' && <UserPlus className="inline-block mr-2 w-5 h-5" />}
                    {type === 'feature' && <Lightbulb className="inline-block mr-2 w-5 h-5" />}
                    {type === 'contribute' && <Music className="inline-block mr-2 w-5 h-5" />}
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </button>
                ))}
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              {renderForm()}
              <button
                type="submit"
                className="bg-eduvibe-green text-eduvibe-dark px-4 md:px-8 py-2 md:py-3 rounded-full text-base md:text-lg font-bold hover:bg-eduvibe-blue transition duration-300 w-full flex items-center justify-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  'Submitting...'
                ) : (
                  <>
                    <Send className="mr-1 md:mr-2 w-4 h-4 md:w-5 md:h-5" />
                    {formType === 'contact' ? 'Send Message' : 
                     formType === 'waitlist' ? 'Join Waitlist' : 
                     formType === 'feature' ? 'Submit Feature Request' : 
                     'Apply to Contribute'}
                  </>
                )}
              </button>
            </form>

            {submitMessage && (
              <div className="mt-4 p-3 bg-eduvibe-blue bg-opacity-50 rounded-lg">
                {submitMessage}
              </div>
            )}
            {submitError && (
              <div className="mt-4 p-3 bg-red-500 bg-opacity-50 rounded-lg text-white">
                {submitError}
              </div>
            )}
          </motion.div>

          <motion.div
            className="text-center mt-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-bold mb-2">Or reach out directly:</h2>
            <a href="mailto:contact@eduvibz.com" className="text-eduvibe-green hover:underline">
              contact@eduvibz.com
            </a>
          </motion.div>
        </main>
      </div>
    </div>
  );
};

export default ContactUs;