// src/utils/headerLinks.js

export const getHeaderLinks = (userStatus, planId, role) => {
    const guestLinks = [
      { to: '/', label: 'Home' },
      { to: '/about', label: 'About Us' },
      { to: '/how-it-works', label: 'How It Works' },
      { to: '/pricing', label: 'Pricing' },
      { to: '/contact', label: 'Contact Us' },
      { to: '/faq', label: 'FAQ' },
      { to: '/login', label: 'Login' }
    ];
  
    if (userStatus === 'guest' || userStatus === 'verified') {
      return guestLinks;
    }
  
    const commonLinks = [
      { to: '/dashboard', label: 'Dashboard' },
      { to: '/browse', label: 'Browse Music' },
    ];
  
    if (userStatus === 'paid') {
      return [
        ...commonLinks,
        { to: '/student-onboarding', label: 'Set Preferences' },
        { to: '/logout', label: 'Logout' }
      ];
    }
  
    const roleBasedLinks = {
      student: [
        { to: '/playlists', label: 'Playlists' },
        // { to: '/progress', label: 'Progress' },
        // { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ],
      parent: [
        { to: '/playlists', label: 'Playlists' },
        // { to: '/progress', label: 'Progress' },
        // { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ],
      teacher: [
        { to: '/class-management', label: 'Class Management' },
        { to: '/playlists', label: 'Playlists' },
        { to: '/quiz-creator', label: 'Quiz Creator' },
        { to: '/challenge-creator', label: 'Challenge Creator' },
        { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ],
      administrator: [
        { to: '/admin-panel', label: 'Admin Panel' },
        { to: '/teacher-dashboard', label: 'Teacher Dashboard' },
        { to: '/manage-licenses', label: 'Manage Licenses' },
        { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ],
      creator: [
        { to: '/upload-music', label: 'Upload Music' },
        { to: '/analytics', label: 'Analytics' },
        { to: '/royalties', label: 'Royalties' },
        { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ],
      admin: [
        { to: '/user-management', label: 'User Management' },
        { to: '/content-management', label: 'Content Management' },
        { to: '/analytics-dashboard', label: 'Analytics Dashboard' },
        { to: '/revenue-sharing', label: 'Revenue Sharing' },
        { to: '/system-settings', label: 'System Settings' },
        { to: '/profile', label: 'Profile' },
        { to: '/settings', label: 'Settings' },
        { to: '/logout', label: 'Logout' }
      ]
    };
  
    if (userStatus === 'complete') {
      const userRoleLinks = roleBasedLinks[role?.toLowerCase()] || roleBasedLinks.student;
      return [...commonLinks, ...userRoleLinks];
    }
  
    // Default case (should not normally be reached)
    return guestLinks;
  };