import Cookies from 'js-cookie';

const COOKIE_CONSENT_KEY = 'cookie_consent';
const COOKIE_PREFERENCES_KEY = 'cookie_preferences';

export const cookieService = {
  hasConsent() {
    return Cookies.get(COOKIE_CONSENT_KEY) === 'true';
  },

  setConsent(accepted) {
    Cookies.set(COOKIE_CONSENT_KEY, accepted, { expires: 365 });
  },

  getPreferences() {
    const prefs = Cookies.get(COOKIE_PREFERENCES_KEY);
    return prefs ? JSON.parse(prefs) : null;
  },

  setPreferences(preferences) {
    Cookies.set(COOKIE_PREFERENCES_KEY, JSON.stringify(preferences), { expires: 365 });
  },

  clearAll() {
    Cookies.remove(COOKIE_CONSENT_KEY);
    Cookies.remove(COOKIE_PREFERENCES_KEY);
  }
}; 