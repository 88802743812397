import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({
  title = 'EduVibz - Music Powered Learning Platform',
  description = 'EduVibz is transforming education through music-powered learning experiences. Engage with our innovative platform designed for students, teachers, and music enthusiasts.',
  keywords = 'music education, learning platform, EduVibz, music powered learning, educational technology',
  canonical = '',
  image = '/images/eduvibz-social-share.jpg',
  type = 'website'
}) => {
  const siteUrl = process.env.REACT_APP_CLIENT_URL || 'https://eduvibz.com';
  const fullCanonical = canonical ? `${siteUrl}${canonical}` : siteUrl;
  const fullImage = image.startsWith('http') ? image : `${siteUrl}${image}`;
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={fullCanonical} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={fullCanonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={fullCanonical} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImage} />
      
      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={fullImage} />
    </Helmet>
  );
};

export default SEO;
