// src/components/common/Header.js
import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
// Using external image URL instead of local asset
// import logo from '../../assets/logo.png';
import { getHeaderLinks } from '../../utils/headerLinks';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';
import '../../styles/northernLights.css';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isLoggedIn, user, userStatus, loading, logout } = useAuth();
  const navigate = useNavigate();

  // Define logo URL constant
  const logoUrl = 'https://i.imgur.com/0y1brB3.png';

  const links = useMemo(() => {
    return getHeaderLinks(userStatus, user?.planId, user?.role);
  }, [userStatus, user]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      logger.error('Logout failed', { error });
    }
  };

  if (loading) {
    return null;
  }

  return (
    <header className="bg-eduvibe-dark bg-opacity-100 backdrop-filter backdrop-blur-lg fixed w-full z-50">
      <div className="container mx-auto px-4">
        <nav className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold">
              <img src={logoUrl} alt="EduVibz Logo" className="h-10 md:h-14 mr-2 pl-2" />
            </Link>
            <Link 
              to="/terms-of-service" 
              className="hidden md:inline-block ml-2 px-3 py-1 text-sm rounded-full border border-eduvibe-green text-eduvibe-green hover:bg-eduvibe-green hover:text-eduvibe-dark transition duration-300"
            >
              Beta
            </Link>
          </div>
          <div className="hidden md:flex space-x-6 items-center">
            {links.map((link, index) => (
              link.label === 'Logout' ? (
                <button
                  key={index}
                  onClick={handleLogout}
                  className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full hover:bg-eduvibe-blue transition duration-300"
                >
                  {link.label}
                </button>
              ) : link.label === 'Pricing' ? (
                <Link
                  key={index}
                  to={link.to}
                  className="px-4 py-2 rounded-full border border-eduvibe-green text-eduvibe-green hover:bg-eduvibe-dark transition duration-300 shadow-[0_0_10px_#00ff00] hover:shadow-[0_0_15px_#00ff00]"
                >
                  Join Beta
                </Link>
              ) : (
                <Link 
                  key={index} 
                  to={link.to} 
                  className={`hover:text-eduvibe-green transition duration-300 ${link.label === 'Login' ? 'bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full hover:bg-eduvibe-blue' : ''}`}
                >
                  {link.label}
                </Link>
              )
            ))}
          </div>
          <div className="md:hidden pr-2">
            <button onClick={toggleMobileMenu} className="text-white focus:outline-none p-3">
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </nav>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-eduvibe-dark bg-opacity-100 backdrop-filter backdrop-blur-lg z-20">
          <div className="container mx-auto px-4 py-4 space-y-4">
            {links.map((link, index) => (
              <div key={index} className="w-full">
                {link.label === 'Logout' ? (
                  <button
                    onClick={() => {
                      handleLogout();
                      toggleMobileMenu();
                    }}
                    className="w-full bg-eduvibe-green text-eduvibe-dark px-4 py-3 rounded-full hover:bg-eduvibe-blue transition duration-300 text-center"
                  >
                    {link.label}
                  </button>
                ) : link.label === 'Pricing' ? (
                  <Link
                    to={link.to}
                    onClick={toggleMobileMenu}
                    className="block w-full px-4 py-3 rounded-full border border-eduvibe-green text-eduvibe-green hover:bg-eduvibe-dark transition duration-300 text-center shadow-[0_0_10px_#00ff00] hover:shadow-[0_0_15px_#00ff00]"
                  >
                    Join Beta
                  </Link>
                ) : (
                  <Link 
                    to={link.to}
                    onClick={toggleMobileMenu}
                    className={`block w-full text-center py-3 px-4 rounded-full transition duration-300 ${
                      link.label === 'Login' 
                        ? 'bg-eduvibe-green text-eduvibe-dark hover:bg-eduvibe-blue' 
                        : 'text-white hover:text-eduvibe-green'
                    }`}
                  >
                    {link.label}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;