import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import api from '../../services/api';
import { logger } from '../../utils/logger';

const Filters = ({ onFilterChange, isVisible, userPreferences }) => {
  const [curricula, setCurricula] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedCurricula, setSelectedCurricula] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [openFilter, setOpenFilter] = useState(null);

  useEffect(() => {
    fetchCurricula();
  }, []);

  useEffect(() => {
    if (userPreferences && curricula.length > 0) {
      const prefCurriculum = userPreferences.preferredCurriculum?._id;
      if (prefCurriculum && curricula.some(c => c._id === prefCurriculum)) {
        setSelectedCurricula([prefCurriculum]);
        fetchGrades([prefCurriculum]);
      }
    }
  }, [userPreferences, curricula]);

  useEffect(() => {
    if (userPreferences && grades.length > 0) {
      const prefGrade = userPreferences.preferredGrade?._id;
      if (prefGrade && grades.some(g => g._id === prefGrade)) {
        setSelectedGrades([prefGrade]);
        fetchSubjects([prefGrade]);
      }
    }
  }, [userPreferences, grades]);

  useEffect(() => {
    if (userPreferences && subjects.length > 0) {
      const prefSubjects = userPreferences.preferredSubjects?.map(s => s._id) || [];
      const validSubjects = prefSubjects.filter(s => subjects.some(sub => sub._id === s));
      setSelectedSubjects(validSubjects);
    }
  }, [userPreferences, subjects]);

  const fetchCurricula = async () => {
    try {
      const response = await api.get('/curricula');
      setCurricula(response.data);
    } catch (error) {
      logger.error('Failed to fetch curricula', { error });
    }
  };

  const fetchGrades = async (curriculumIds) => {
    try {
      const promises = curriculumIds.map(id => api.get(`/grades/${id}`));
      const responses = await Promise.all(promises);
      const allGrades = responses.flatMap(response => response.data);
      setGrades(allGrades);
    } catch (error) {
      logger.error('Failed to fetch grades', { error });
    }
  };

  const fetchSubjects = async (gradeIds) => {
    try {
      const promises = gradeIds.map(id => api.get(`/subjects/${id}`));
      const responses = await Promise.all(promises);
      const allSubjects = responses.flatMap(response => response.data);
      setSubjects(allSubjects);
    } catch (error) {
      logger.error('Failed to fetch subjects', { error });
    }
  };

  const handleFilterToggle = (filterType) => {
    setOpenFilter(openFilter === filterType ? null : filterType);
  };

  const handleOptionToggle = (filterType, optionId) => {
    let updatedSelection;
    switch (filterType) {
      case 'curriculum':
        updatedSelection = selectedCurricula.includes(optionId)
          ? selectedCurricula.filter(id => id !== optionId)
          : [...selectedCurricula, optionId];
        setSelectedCurricula(updatedSelection);
        if (updatedSelection.length > 0) fetchGrades(updatedSelection);
        break;
      case 'grade':
        updatedSelection = selectedGrades.includes(optionId)
          ? selectedGrades.filter(id => id !== optionId)
          : [...selectedGrades, optionId];
        setSelectedGrades(updatedSelection);
        if (updatedSelection.length > 0) fetchSubjects(updatedSelection);
        break;
      case 'subject':
        updatedSelection = selectedSubjects.includes(optionId)
          ? selectedSubjects.filter(id => id !== optionId)
          : [...selectedSubjects, optionId];
        setSelectedSubjects(updatedSelection);
        break;
      default:
        break;
    }

    onFilterChange({
      curriculum: filterType === 'curriculum' ? updatedSelection : selectedCurricula,
      grade: filterType === 'grade' ? updatedSelection : selectedGrades,
      subject: filterType === 'subject' ? updatedSelection : selectedSubjects
    });
  };

  const getDisplayValue = (filterType) => {
    const selected = filterType === 'curriculum' ? selectedCurricula :
                     filterType === 'grade' ? selectedGrades : selectedSubjects;
    
    if (selected.length === 0) return `All ${filterType}s`;
    if (selected.length === 1) {
      const item = (filterType === 'curriculum' ? curricula :
                    filterType === 'grade' ? grades : subjects)
                    .find(i => i._id === selected[0]);
      return item ? item.name : `All ${filterType}s`;
    }
    return `${selected.length} ${filterType}s selected`;
  };

  return (
    <div className={`flex justify-between gap-4 mb-8 ${isVisible ? 'block' : 'hidden md:flex'}`}>
      {[
        { name: 'curriculum', options: curricula, selected: selectedCurricula },
        { name: 'grade', options: grades, selected: selectedGrades },
        { name: 'subject', options: subjects, selected: selectedSubjects }
      ].map((filter) => (
        <div key={filter.name} className="relative w-full md:w-1/3">
          <button
            onClick={() => handleFilterToggle(filter.name)}
            className="w-full px-4 py-2 text-left bg-eduvibe-blue bg-opacity-50 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-eduvibe-green"
          >
            {getDisplayValue(filter.name)}
            <ChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2" size={20} />
          </button>
          {openFilter === filter.name && (
            <div className="absolute z-10 w-full mt-2 py-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg shadow-xl max-h-60 overflow-y-auto">
              {filter.options.map((option) => (
                <label key={option._id} className="flex items-center px-4 py-2 hover:bg-eduvibe-blue hover:bg-opacity-50 cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-eduvibe-green"
                    checked={filter.selected.includes(option._id)}
                    onChange={() => handleOptionToggle(filter.name, option._id)}
                  />
                  <span className="ml-2 text-white">{option.name}</span>
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Filters;