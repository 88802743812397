import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Shield, Eye, Lock, UserCheck, Settings, Database } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const CookiePolicy = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const sections = [
    {
      icon: <Cookie />,
      title: "What are cookies?",
      content: "Cookies are small text files that are stored on your device when you visit our website. They help make your experience better by remembering your preferences and providing essential functionality."
    },
    {
      icon: <Shield />,
      title: "Types of cookies we use",
      content: "We use strictly necessary cookies that are essential for the website to function (like keeping you logged in), performance cookies that help us improve our site's functionality, and analytics cookies through Google Analytics to understand how users interact with our platform. We do not use any third-party marketing or advertising cookies."
    },
    {
      icon: <Database />,
      title: "First-party vs third-party cookies",
      content: "Most of our cookies are first-party cookies, set directly by EduVibz. The only third-party cookies we use are from Google Analytics, which help us understand site usage patterns. These analytics cookies are anonymized and do not track personal information."
    },
    {
      icon: <Eye />,
      title: "How we use cookies",
      content: "We use cookies to: maintain your login session securely, remember your learning progress and preferences, provide a smooth user experience when navigating between pages, and collect anonymous usage statistics to improve our educational platform. We never use cookies to track you across other websites or for advertising purposes."
    },
    {
      icon: <Settings />,
      title: "Cookie duration",
      content: "Session cookies are temporary and deleted when you close your browser. These handle things like keeping you logged in. Persistent cookies remain on your device for a set period - our analytics cookies last 30 days, while preference cookies are stored for 6 months to remember your settings."
    },
    {
      icon: <Lock />,
      title: "Managing cookies",
      content: "You can control cookies through your browser settings. Most browsers let you block third-party cookies, clear existing cookies, and choose whether to accept new cookies. Note that blocking essential cookies will prevent you from logging in or using key features of EduVibz."
    },
    {
      icon: <UserCheck />,
      title: "Your consent",
      content: "When you first visit EduVibz, we'll ask for your consent to use non-essential cookies. You can change your preferences anytime through the Cookie Settings in your account. Essential cookies will still be used as they're required for basic site functionality."
    }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            Cookie Policy
          </motion.h1>
          
          <motion.p
            className="text-xl text-center mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            This policy explains how EduVibz uses cookies and similar technologies to provide you with a better learning experience while protecting your privacy.
          </motion.p>

          {sections.map((section, index) => (
            <motion.section 
              key={index}
              className="mb-12 bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <h2 className="text-3xl font-bold text-eduvibe-blue mb-4 flex items-center">
                {React.cloneElement(section.icon, { className: "mr-3 w-8 h-8" })}
                {section.title}
              </h2>
              <p className="text-lg">{section.content}</p>
            </motion.section>
          ))}

          <motion.section
            className="mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <p className="text-lg">
              This Cookie Policy was last updated on November 09, 2024. We regularly review and update this policy to reflect changes in our practices and services. Any significant changes will be communicated through the platform.
            </p>
            <p className="text-lg mt-4">
              For questions about our cookie practices or to report issues, please contact our privacy team at privacy@eduvibz.com or call +27 (0)83 297 8455.
            </p>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default CookiePolicy;