import React, { useState, useEffect } from 'react';
import { BookOpen, Check } from 'lucide-react';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';

const LearningPreferences = () => {
  const { user, fetchUserData } = useAuth();
  const [preferredCurriculum, setPreferredCurriculum] = useState('');
  const [preferredGrade, setPreferredGrade] = useState('');
  const [preferredSubjects, setPreferredSubjects] = useState([]);
  const [curricula, setCurricula] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (user) {
      setPreferredCurriculum(user.preferredCurriculum?._id || '');
      setPreferredGrade(user.preferredGrade?._id || '');
      setPreferredSubjects(user.preferredSubjects?.map(s => s._id) || []);
    }
  }, [user]);

  const fetchOptions = async () => {
    try {
      const curriculaResponse = await api.get('/curricula');
      setCurricula(curriculaResponse.data);

      if (user.preferredCurriculum?._id) {
        const gradesResponse = await api.get(`/grades/${user.preferredCurriculum._id}`);
        setGrades(gradesResponse.data);
      }

      if (user.preferredGrade?._id) {
        const subjectsResponse = await api.get(`/subjects/${user.preferredGrade._id}`);
        setSubjects(subjectsResponse.data);
      }
    } catch (error) {
      logger.error('Failed to fetch options', { error });
    }
  };

  const handleCurriculumChange = async (e) => {
    const curriculumId = e.target.value;
    setPreferredCurriculum(curriculumId);
    setPreferredGrade('');
    setPreferredSubjects([]);

    if (curriculumId) {
      try {
        const gradesResponse = await api.get(`/grades/${curriculumId}`);
        setGrades(gradesResponse.data);
      } catch (error) {
        logger.error('Failed to fetch grades', { error });
      }
    } else {
      setGrades([]);
    }
  };

  const handleGradeChange = async (e) => {
    const gradeId = e.target.value;
    setPreferredGrade(gradeId);
    setPreferredSubjects([]);

    if (gradeId) {
      try {
        const subjectsResponse = await api.get(`/subjects/${gradeId}`);
        setSubjects(subjectsResponse.data);
      } catch (error) {
        logger.error('Failed to fetch subjects', { error });
      }
    } else {
      setSubjects([]);
    }
  };

  const handleSubjectChange = (subjectId) => {
    setPreferredSubjects(prevSubjects => 
      prevSubjects.includes(subjectId)
        ? prevSubjects.filter(id => id !== subjectId)
        : [...prevSubjects, subjectId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/user/preferences', {
        preferredCurriculum,
        preferredGrade,
        preferredSubjects
      });
      fetchUserData();
      alert('Learning preferences updated successfully!');
    } catch (error) {
      logger.error('Failed to update learning preferences', { error });
      alert('Failed to update learning preferences. Please try again.');
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-eduvibe-green">Learning Preferences</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="curriculum" className="text-lg font-bold mb-2 flex items-center">
            <BookOpen className="mr-2" /> Preferred Curriculum
          </label>
          <select
            id="curriculum"
            value={preferredCurriculum}
            onChange={handleCurriculumChange}
            className="w-full p-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg focus:outline-none focus:border-eduvibe-green text-white"
          >
            <option value="">Select Curriculum</option>
            {curricula.map(curriculum => (
              <option key={curriculum._id} value={curriculum._id}>{curriculum.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="grade" className="text-lg font-bold mb-2 flex items-center">
            <BookOpen className="mr-2" /> Preferred Grade
          </label>
          <select
            id="grade"
            value={preferredGrade}
            onChange={handleGradeChange}
            className="w-full p-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg focus:outline-none focus:border-eduvibe-green text-white"
          >
            <option value="">Select Grade</option>
            {grades.map(grade => (
              <option key={grade._id} value={grade._id}>{grade.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="text-lg font-bold mb-2 flex items-center">
            <BookOpen className="mr-2" /> Preferred Subjects
          </label>
          <div className="space-y-2 max-h-60 overflow-y-auto">
            {subjects.map(subject => (
              <label key={subject._id} className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={preferredSubjects.includes(subject._id)}
                  onChange={() => handleSubjectChange(subject._id)}
                  className="hidden"
                />
                <div className={`w-5 h-5 border ${preferredSubjects.includes(subject._id) ? 'bg-eduvibe-green border-eduvibe-green' : 'border-eduvibe-blue'} rounded flex items-center justify-center`}>
                  {preferredSubjects.includes(subject._id) && <Check size={16} className="text-eduvibe-dark" />}
                </div>
                <span className="text-white">{subject.name}</span>
              </label>
            ))}
          </div>
        </div>
        <button type="submit" className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors">
          Save Preferences
        </button>
      </form>
    </div>
  );
};

export default LearningPreferences;