// src/components/Music/Lyrics.js
import React from 'react';

const Lyrics = ({ song }) => {
  if (!song) return null;

  return (
    <div className="lyrics-container bg-eduvibe-blue bg-opacity-0 pt-10 pr-6 pl-6 pb-60 rounded-lg mb-4 text-center">
      <h2 className="text-3xl font-bold mb-4">{song.title}</h2>
      <div className="lyrics-content bg-eduvibe-blue bg-opacity-10 p-6 rounded-lg mb-4 text-center">
        {song.lyrics ? (
          <pre className="whitespace-pre-wrap font-sans">{song.lyrics}</pre>
        ) : (
          <p className="text-gray-400">Lyrics not available for this song.</p>
        )}
      </div>
    </div>
  );
};

export default Lyrics;