import React from 'react';
import { Music, Sparkles, Zap } from 'lucide-react';

const emojis = ['🎵', '🎶', '🔥', '💯', '🚀', '✨'];

const RecentlyListenedHeader = ({ message }) => {
  const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

  return (
    <div className="flex items-center space-x-2 mb-4">
      <h2 className="text-2xl md:text-3xl font-bold text-eduvibe-green">Your Recent Vibes</h2>
      {message ? (
        <span className="text-xl text-eduvibe-blue animate-bounce">{randomEmoji}</span>
      ) : (
        <div className="flex space-x-2">
          <Music className="text-eduvibe-green animate-bounce" />
          <Sparkles className="text-eduvibe-blue animate-spin-slow" />
          <Zap className="text-eduvibe-green animate-bounce" />
        </div>
      )}
    </div>
  );
};

export default RecentlyListenedHeader;