import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../contexts/AuthContext';
import { getSubscriptionDetails } from '../services/api';
import { AlertTriangle } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import Sidebar from '../components/Music/Sidebar';
import Lyrics from '../components/Music/Lyrics';
import MusicFooter from '../components/Music/MusicFooter';
import api from '../services/api';
import { logger } from '../utils/logger';

const MusicPlayer = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { chapterId, songId, playlistId } = useParams();
  const location = useLocation();
  const initialSong = location.state?.initialSong;

  const [songs, setSongs] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chapter, setChapter] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [error, setError] = useState(null);
  const [chaptersList, setChaptersList] = useState([]);
  const [subscription, setSubscription] = useState({ status: 'active' }); // Default to active
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const lastLoggedTime = useRef(0);

  const [visibleTime, setVisibleTime] = useState(0);
  const [hiddenTime, setHiddenTime] = useState(0);
  const visibilityStartTime = useRef(Date.now());
  const isPageVisible = useRef(true);
  const sessionStartTime = useRef(Date.now());
  const hasUserInteracted = useRef(false);

  const isInitialLoad = useRef(true);

  const getCognitoSub = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.sub;
      } catch (error) {
        logger.error('Error decoding token', { error: error.message });
        return null;
      }
    }
    return null;
  }, []);

  const formatSong = useCallback((song) => {
    if (!song) return null;
    
    const formattedSong = {
      ...song,
      file: song.file || '',
      album: song.album || {
        _id: song.chapter?._id || 'unknown',
        title: song.chapter?.name || 'Unknown Album',
        coverImage: song.chapter?.coverImage || '/default-cover.jpg',
        subject: song.chapter?.subject
      },
      chapter: song.chapter || {
        _id: song.album?._id || 'unknown',
        name: song.album?.title || 'Unknown Chapter',
        coverImage: song.album?.coverImage || '/default-cover.jpg',
        subject: song.album?.subject
      }
    };
    
    return formattedSong;
  }, []);

  const fetchSongsByChapter = useCallback(async (id) => {
    if (!id) {
      logger.error('No chapter ID provided to fetchSongsByChapter');
      setError('Invalid chapter selected');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    try {
      logger.debug('Fetching songs for chapter', { chapterId: id });
      const response = await api.get(`/music/songs-by-chapter/${id}`);
      
      if (!response || !response.data) {
        logger.error('Invalid response from server', { response });
        throw new Error('Invalid server response');
      }
      
      logger.debug('Chapter songs received', { 
        chapterId: id,
        songCount: response.data?.length || 0
      });
      
      if (!Array.isArray(response.data)) {
        logger.error('Server response is not an array', { 
          responseType: typeof response.data 
        });
        throw new Error('Invalid data format from server');
      }
      
      if (response.data.length === 0) {
        logger.info('No songs found for chapter', { chapterId: id });
        setError('No songs found in this chapter.');
        setSongs([]);
        setCurrentSong(null);
        return;
      }
      
      const formattedSongs = response.data.map(song => formatSong(song));
      
      logger.debug('Songs formatted successfully', { 
        chapterId: id,
        songCount: formattedSongs.length 
      });
      
      setSongs(formattedSongs);
      
      // Only set current song if not changing chapters
      if (!currentSong && formattedSongs.length > 0) {
        // If no song is playing, select based on songId or default to first
        const songToPlay = songId ? formattedSongs.find(s => s._id === songId) : formattedSongs[0];
        if (songToPlay) {
          logger.debug('Setting initial song', {
            songId: songToPlay._id,
            chapterId: songToPlay.chapter._id
          });
          setCurrentSong(songToPlay);
          setChapter(songToPlay.chapter);
        }
      }
    } catch (error) {
      logger.error('Error fetching songs', {
        chapterId: id,
        error: error.message
      });
      setError(error.message || 'Failed to fetch songs. Please try again.');
      setSongs([]);
    } finally {
      setIsLoading(false);
    }
  }, [formatSong, songId, currentSong]);

  const fetchPlaylist = useCallback(async (id) => {
    if (!id) return;
    
    setIsLoading(true);
    setError(null);
    try {
      logger.debug('Fetching playlist', { playlistId: id });
      const response = await api.get(`/playlists/${id}`);
      
      if (!response.data?.songs) {
        logger.error('Invalid playlist data', { 
          playlistId: id,
          response: response.data 
        });
        setError('Invalid playlist data received');
        return;
      }
      
      const playlistSongs = response.data.songs
        .map(song => formatSong(song))
        .filter(song => song && song.file);
      
      logger.debug('Playlist songs processed', {
        playlistId: id,
        songCount: playlistSongs.length
      });
      
      setSongs(playlistSongs);
      setPlaylist(response.data);
      
      if (playlistSongs.length > 0) {
        const songToPlay = songId ? playlistSongs.find(s => s._id === songId) : playlistSongs[0];
        setCurrentSong(songToPlay || playlistSongs[0]);
      } else {
        logger.warn('No valid songs in playlist', { playlistId: id });
        setError('No valid songs found in this playlist');
      }
    } catch (error) {
      logger.error('Error fetching playlist', {
        playlistId: id,
        error: error.message
      });
      setError('Failed to fetch the playlist. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [formatSong, songId]);

  const fetchSong = useCallback(async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`/music/song/${id}`);
      const song = formatSong(response.data);
      setCurrentSong(song);
      setSongs([song]);
      setChapter({
        _id: song.chapter._id,
        title: song.chapter.name,
        coverImage: song.chapter.coverImage,
        subject: song.chapter.subject
      });
      await fetchSongsByChapter(song.chapter._id);
    } catch (error) {
      logger.error('Error fetching song', {
        songId: id,
        error: error.message
      });
      setError('Failed to fetch the song. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [fetchSongsByChapter]);

  const fetchChaptersBySubject = useCallback(async (subjectId) => {
    try {
      if (!subjectId) {
        logger.warn('No subject ID provided');
        return;
      }
      const cleanSubjectId = typeof subjectId === 'object' ? subjectId._id : subjectId;
      
      const response = await api.get('/music/chapters-by-subject', {
        params: { subjectId: cleanSubjectId }
      });
      setChapters(response.data);
    } catch (error) {
      logger.error('Error fetching chapters', {
        subjectId: subjectId,
        error: error.message
      });
      setError('Failed to fetch chapters');
    }
  }, []);

  useEffect(() => {
    if (currentSong?.chapter?.subject && !chaptersList.length) {
      const subject = currentSong.chapter.subject;
      const subjectId = typeof subject === 'object' ? subject._id : subject;
      logger.debug('Fetching chapters for subject ID', { subjectId });
      fetchChaptersBySubject(subjectId);
    }
  }, [currentSong, fetchChaptersBySubject, chaptersList.length]);

  // Handle initial song from navigation
  useEffect(() => {
    if (initialSong && !currentSong) {
      const formattedSong = formatSong(initialSong);
      setCurrentSong(formattedSong);
      setChapter(formattedSong.chapter);
      // Fetch all songs from the same chapter
      if (formattedSong.chapter?._id) {
        fetchSongsByChapter(formattedSong.chapter._id);
      }
    }
  }, [initialSong, formatSong, fetchSongsByChapter, currentSong]);

  // Initial content loading
  useEffect(() => {
    if (!initialSong && !currentSong) {  // Only load if no initialSong and no currentSong
      if (playlistId) {
        fetchPlaylist(playlistId);
      } else if (chapterId) {
        fetchSongsByChapter(chapterId);
      }
    }
  }, [playlistId, chapterId, initialSong, fetchPlaylist, fetchSongsByChapter, currentSong]);

  useEffect(() => {
    if (currentSong && songs.length > 0) {
      logger.debug('Initial song load', { 
        songId: currentSong._id, 
        title: currentSong.title,
        isInitialLoad: isInitialLoad.current
      });
      
      // Always ensure the first song is in a non-playing state
      if (isInitialLoad.current) {
        setIsPlaying(false);
        hasUserInteracted.current = false;
        isInitialLoad.current = false;
      }
    }
  }, [currentSong, songs.length]);

  const logListeningEvent = useCallback(async (eventType, songId, additionalData = {}) => {
    if (!songId || !currentSong) return;
    try {
      const currentTime = Date.now();
      const visibleDuration = visibleTime + (isPageVisible.current ? currentTime - visibilityStartTime.current : 0);
      const hiddenDuration = hiddenTime + (!isPageVisible.current ? currentTime - visibilityStartTime.current : 0);

      const cognitoSub = getCognitoSub();
      if (!cognitoSub) {
        logger.error('User not authenticated');
        return;
      }

      const eventData = {
        eventType,
        songId,
        chapterId: currentSong.chapter?._id,
        artist: currentSong.artist,
        genre: currentSong.genre,
        timestamp: new Date().toISOString(),
        visibleDuration,
        hiddenDuration,
        deviceType: getDeviceType(),
        timeOfDay: new Date().getHours(),
        sessionDuration: currentTime - sessionStartTime.current,
        cognitoSub,
        ...additionalData
      };

      logger.debug('Logging event', { eventType, songId, chapterId: currentSong.chapter?._id });
      await api.post('/listening-events', eventData);

      setVisibleTime(0);
      setHiddenTime(0);
      visibilityStartTime.current = currentTime;
    } catch (error) {
      logger.error('Error logging listening event', {
        eventType,
        songId,
        error: error.message
      });
    }
  }, [currentSong, visibleTime, hiddenTime, getCognitoSub]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const currentTime = Date.now();
      if (document.hidden) {
        isPageVisible.current = false;
        setVisibleTime(prev => prev + (currentTime - visibilityStartTime.current));
      } else {
        isPageVisible.current = true;
        setHiddenTime(prev => prev + (currentTime - visibilityStartTime.current));
      }
      visibilityStartTime.current = currentTime;
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleAudioRef = useCallback((ref) => {
    if (ref) {
      setAudioElement(ref);
      logger.debug('Audio element reference set');
    }
  }, []);

  const handleSongSelect = (song) => {
    setCurrentSong(formatSong(song));
    setIsPlaying(false);
    lastLoggedTime.current = 0;
    logListeningEvent('select', song._id);
    
    logger.debug('Song selected by user, ready for playback', {
      songId: song._id,
      title: song.title
    });
  };

  const handleSongChange = useCallback((song, action, maintainPlayState) => {
    if (!song) return;
    
    logger.debug('Song changed', { 
      songId: song._id, 
      title: song.title,
      action: action || 'next',
      maintainPlayState: maintainPlayState
    });
    
    // Use the explicitly passed maintainPlayState if provided, otherwise use current state
    const shouldPlay = maintainPlayState !== undefined ? maintainPlayState : isPlaying;
    
    // First set the current song to trigger loading
    setCurrentSong(formatSong(song));
    
    // Maintain the play state based on the shouldPlay value
    if (shouldPlay) {
      setIsPlaying(true);
      logListeningEvent('start', song._id, { 
        action: action || 'next',
        previousSongId: currentSong?._id
      });
    } else {
      setIsPlaying(false);
      logListeningEvent('select', song._id, {
        action: action || 'next',
        previousSongId: currentSong?._id
      });
    }
    
    lastLoggedTime.current = 0;
  }, [logListeningEvent, isPlaying, currentSong, formatSong]);

  const handlePlayPause = (newIsPlaying) => {
    setIsPlaying(newIsPlaying);
    
    if (!currentSong) return;
    
    if (newIsPlaying) {
      logger.debug('Playing song', { songId: currentSong._id });
      logListeningEvent('play', currentSong._id, { 
        currentTime: audioElement?.currentTime || 0 
      });
    } else {
      logger.debug('Pausing song', { songId: currentSong._id });
      logListeningEvent('pause', currentSong._id, { 
        currentTime: audioElement?.currentTime || 0 
      });
    }
  };

  const handleTimeUpdate = (e) => {
    if (!e.target || !currentSong) return;
    const currentTime = e.target.currentTime;
    const duration = e.target.duration;
    const progress = (currentTime / duration) * 100;

    if (currentTime - lastLoggedTime.current >= 300) {
      logListeningEvent('progress', currentSong._id, { progress, currentTime });
      lastLoggedTime.current = currentTime;
    }

    if (progress >= 70 && lastLoggedTime.current < duration * 0.7) {
      logListeningEvent('milestone', currentSong._id, { milestone: 70, currentTime });
      lastLoggedTime.current = currentTime;
    }
  };

  const handleSongEnd = () => {
    if (currentSong) {
      logListeningEvent('complete', currentSong._id);
    }
  };

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
    }
    return "desktop";
  };

  const handleSkip = (skipAmount) => {
    logListeningEvent('skip', currentSong._id, { skipAmount, currentTime: audioElement.currentTime });
  };

  const handleReplay = (startTime, endTime) => {
    logListeningEvent('replay', currentSong._id, { replayStartTime: startTime, replayEndTime: endTime });
  };

  const handleChapterSelect = async (selectedChapterId) => {
    try {
      setIsLoading(true);
      setError(null);
      logger.debug('Changing to chapter', { chapterId: selectedChapterId });
      
      const songsResponse = await api.get(`/music/songs-by-chapter/${selectedChapterId}`);
      
      if (songsResponse.data && songsResponse.data.length > 0) {
        const formattedSongs = songsResponse.data.map(song => formatSong(song));
        logger.debug('New chapter songs', { 
          chapterId: selectedChapterId,
          songCount: formattedSongs.length 
        });
        
        // First stop playback and reset audio
        setIsPlaying(false);
        if (audioElement) {
          audioElement.currentTime = 0;
          audioElement.pause();
        }
        
        // Then update all state at once
        setSongs(formattedSongs);
        const firstSong = formattedSongs[0];
        setChapter(firstSong.chapter);
        setCurrentSong(firstSong);
        
        // Log chapter change event
        logListeningEvent('chapter_change', firstSong._id, {
          previousChapterId: currentSong?.chapter?._id,
          newChapterId: selectedChapterId
        });
        
        logger.debug('Chapter changed successfully, song loaded in non-playing state', {
          chapterId: selectedChapterId,
          songId: firstSong._id
        });
      } else {
        logger.error('No songs found in chapter', { chapterId: selectedChapterId });
        setError('No songs found in this chapter');
        setSongs([]);
        setCurrentSong(null);
      }
    } catch (error) {
      logger.error('Error changing chapter', {
        chapterId: selectedChapterId,
        error: error.message
      });
      setError('Failed to change chapter');
    } finally {
      setIsLoading(false);
    }
  };

  const checkSubscription = async () => {
    setSubscriptionLoading(false); // No need to check subscription
  };

  useEffect(() => {
    checkSubscription();
  }, []);

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen bg-eduvibe-dark text-white">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen bg-eduvibe-dark text-white">{error}</div>;
  }

  return (
    <div className="h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights audioElement={audioElement} isPlaying={isPlaying} />
      <Header className="z-50 absolute top-0 left-0 right-0" />
      <div className="flex-grow flex overflow-hidden relative pt-16">
        <main className="flex-grow flex flex-col overflow-hidden z-10">
          <div className="flex-grow overflow-y-auto scrollbar-hide">
            {currentSong && <Lyrics song={currentSong} />}
          </div>
        </main>
        <Sidebar className="z-10 absolute top-10 pt-10 left-0 right-0"
          chapter={chapter} 
          playlist={playlist}
          songs={songs} 
          onSongSelect={handleSongSelect} 
          currentSong={currentSong} 
        />
      </div>
      <MusicFooter
        songs={songs}
        currentSong={currentSong}
        isPlaying={isPlaying}
        onSongChange={handleSongChange}
        getAudioRef={handleAudioRef}
        onTimeUpdate={handleTimeUpdate}
        onSongEnd={handleSongEnd}
        onPlayPause={handlePlayPause}
        onSkip={handleSkip}
        onReplay={handleReplay}
        logListeningEvent={logListeningEvent}
        chapters={chapters}
        onChapterSelect={handleChapterSelect}
        fetchChaptersBySubject={fetchChaptersBySubject}
      />
    </div>
  );
};

export default MusicPlayer;
