import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, UserCheck, Mail, Database, Globe, AlertTriangle, FileText } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const PrivacyPolicy = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const sections = [
    {
      icon: <Shield />,
      title: "Information We Collect",
      content: "We collect information you provide directly to us when you create an account or subscribe to our service, including: your name, email address, phone number, and payment information (processed securely through PayFast). We also collect learning data such as: lesson completion rates, quiz scores, time spent on lessons, and music preferences to personalize your learning experience. This data helps us understand how you interact with our platform and improve our educational content."
    },
    {
      icon: <Database />,
      title: "Data Storage & Processing",
      content: "Your data is stored securely in compliance with POPIA (Protection of Personal Information Act). Personal information and payment details are stored separately, with payment processing handled exclusively by PayFast using SSL3 encryption. We retain your data only for as long as necessary to provide our services and comply with legal obligations."
    },
    {
      icon: <Eye />,
      title: "How We Use Your Information",
      content: "We use your information to: provide personalized learning experiences, process payments and manage subscriptions, send important service updates and learning reminders, analyze platform usage to improve our content, and respond to your support requests. We also use anonymized data for research and analytics to enhance our educational methodologies. We never sell your personal data to third parties."
    },
    {
      icon: <Globe />,
      title: "Third-Party Services",
      content: "We use trusted third-party services for specific functions: PayFast for payment processing, AWS Cognito for user authentication, and Google Analytics for website analytics. These services have access only to the information necessary for their specific functions and are bound by strict confidentiality agreements."
    },
    {
      icon: <Lock />,
      title: "Data Security",
      content: "We implement industry-standard security measures including: SSL/TLS encryption for data transmission, secure password hashing, and strict access controls for our staff. All data is backed up regularly and protected against unauthorized access, alteration, or destruction."
    },
    {
      icon: <UserCheck />,
      title: "Your Rights Under POPIA",
      content: "As a South African user, you have the right to: access your personal information, correct any inaccuracies, object to certain processing activities, and receive your data in a portable format. We'll respond to such requests within 30 days."
    },
    {
      icon: <AlertTriangle />,
      title: "Data Breaches",
      content: "In the unlikely event of a data breach, we will notify affected users and relevant authorities within 72 hours of discovery, as required by POPIA. We'll provide details about the breach and steps taken to mitigate any risks."
    },
    {
      icon: <FileText />,
      title: "Cookie Policy",
      content: "We use essential cookies to maintain your session and preferences. Analytics cookies help us understand how users interact with our platform. You can control cookie settings through your browser, though this may affect certain platform functionalities."
    },
    {
      icon: <Mail />,
      title: "Contact Us",
      content: "For privacy-related inquiries or to exercise your data rights, contact our Information Officer at privacy@eduvibz.com. Phone: +27 (0)83 297 8455"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            Privacy Policy
          </motion.h1>
          
          <motion.p
            className="text-xl text-center mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            At EduVibz, protecting your privacy is fundamental to our mission. This comprehensive policy explains how we handle your personal information in compliance with POPIA and international privacy standards.
          </motion.p>

          {sections.map((section, index) => (
            <motion.section 
              key={index}
              className="mb-12 bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <h2 className="text-3xl font-bold text-eduvibe-blue mb-4 flex items-center">
                {React.cloneElement(section.icon, { className: "mr-3 w-8 h-8" })}
                {section.title}
              </h2>
              <p className="text-lg">{section.content}</p>
            </motion.section>
          ))}

          <motion.section
            className="mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <p className="text-lg">
              This Privacy Policy was last updated on November 09, 2024. We regularly review and update this policy to reflect changes in our practices and services. Users will be notified of material changes via email or through the platform.
            </p>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default PrivacyPolicy;