import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, AlertCircle, Loader, Eye, EyeOff, Lock } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import { useAuth } from '../contexts/AuthContext';
import { signIn } from '../services/api';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await signIn(email, password);
      const { token, refreshToken, user } = response;

      // Log in the user
      await login(token, refreshToken);

      // Navigate to dashboard directly - remove subscription check
      navigate('/dashboard');
      
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        localStorage.setItem('userEmail', email);
        navigate('/verify-email', { state: { email } });
      } else if (err.message === 'Unable to connect to the server. Please check your internet connection and try again.') {
        setError(err.message);
      } else {
        setError(err.message || 'An error occurred during login. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-5xl font-extrabold text-eduvibe-green text-center mb-12">Sign In</h1>
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg max-w-lg mx-auto">
            {error && (
              <div className="bg-red-500 text-white p-3 rounded-lg mb-4 flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg font-bold mb-2" htmlFor="email">Email</label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 pl-10 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-lg font-bold mb-2">
                  Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 w-full flex items-center justify-center mb-4"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader className="animate-spin mr-2" />
                    Signing In...
                  </>
                ) : (
                  'Sign In'
                )}
              </button>
            </form>
            <div className="text-center">
              <Link to="/resetrequest" className="text-eduvibe-green hover:underline">Forgot your password?</Link>
            </div>
            <div className="text-center mt-4">
              <Link to="/pricing" className="text-eduvibe-green hover:underline">
                Don't have an account? Sign up
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;