// src/components/playlists/EditPlaylistModal.js
import React, { useState, useEffect } from 'react';
import { X, Music, Trash2 } from 'lucide-react';
import { removeSongFromPlaylist } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';

const EditPlaylistModal = ({ isOpen, onClose, playlist, onUpdatePlaylist }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('personal');
  const [isPublic, setIsPublic] = useState(false);
  const [songs, setSongs] = useState([]);
  const [feedback, setFeedback] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (playlist) {
      setName(playlist.name);
      setDescription(playlist.description);
      setType(playlist.type);
      setIsPublic(playlist.isPublic);
      setSongs(playlist.songs || []);
    }
  }, [playlist]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedPlaylist = {
      ...playlist,
      name,
      description,
      type,
      isPublic,
      songs
    };
    onUpdatePlaylist(updatedPlaylist);
  };

  const handleRemoveSong = async (songId) => {
    try {
      await removeSongFromPlaylist(playlist._id, songId);
      const updatedSongs = songs.filter(song => song._id !== songId);
      setSongs(updatedSongs);
      setFeedback('Song removed successfully');
      setTimeout(() => setFeedback(''), 3000);
    } catch (error) {
      logger.error('Failed to remove song from playlist', { error });
      setFeedback('Failed to remove song. Please try again.');
      setTimeout(() => setFeedback(''), 3000);
    }
  };

  if (!isOpen) return null;

  const canCreateClassPlaylist = user.planId === 'school' && ['teacher', 'administrator'].includes(user.role);

  const playlistTypes = [
    { id: 'personal', label: 'Personal' },
    ...(user.planId === 'family' ? [{ id: 'family', label: 'Family' }] : []),
    ...(canCreateClassPlaylist ? [{ id: 'class', label: 'Class' }] : []),
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-eduvibe-dark border border-eduvibe-blue rounded-lg p-6 w-full max-w-md relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white focus:outline-none p-3"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold text-eduvibe-green flex items-center mb-4">
          <Music className="mr-2" /> Edit Playlist
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Playlist Name"
            className="w-full p-2 bg-eduvibe-blue bg-opacity-20 border border-eduvibe-blue rounded-lg mb-4 focus:outline-none focus:border-eduvibe-green"
            required
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Playlist Description"
            className="w-full p-2 bg-eduvibe-blue bg-opacity-20 border border-eduvibe-blue rounded-lg mb-4 focus:outline-none focus:border-eduvibe-green"
            rows="3"
          />
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Playlist Type</label>
            <div className="flex flex-wrap gap-2">
              {playlistTypes.map(playlistType => (
                <button
                  key={playlistType.id}
                  type="button"
                  onClick={() => setType(playlistType.id)}
                  className={`flex items-center px-3 py-2 rounded-full ${
                    type === playlistType.id ? 'bg-eduvibe-green text-eduvibe-dark' : 'bg-eduvibe-blue bg-opacity-20 text-white'
                  }`}
                >
                  {playlistType.label}
                </button>
              ))}
            </div>
          </div>
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              className="mr-2"
            />
            Make this playlist public
          </label>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Songs in Playlist ({songs.length})</h3>
            {feedback && (
              <p className={`text-sm ${feedback.includes('Failed') ? 'text-red-500' : 'text-green-500'} mb-2`}>
                {feedback}
              </p>
            )}
            <ul className="max-h-60 overflow-y-auto">
              {songs.map(song => (
                <li key={song._id} className="flex justify-between items-center py-2 border-b border-eduvibe-blue">
                  <span>{song.title}</span>
                  <button
                    onClick={() => handleRemoveSong(song._id)}
                    className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-full flex items-center justify-center"
                    style={{ width: '48px', height: '48px' }}
                  >
                    <Trash2 size={24} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors"
            >
              Update Playlist
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPlaylistModal;
