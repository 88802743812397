import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import { confirmSignUp, signIn, resendConfirmationCode } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { login, updateUserStatus } = useAuth();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [resendStatus, setResendStatus] = useState('');

  const handleVerify = async (e) => {
    e.preventDefault();
    setVerificationStatus('Verifying...');
    const email = localStorage.getItem('userEmail');
    const password = localStorage.getItem('tempPassword');

    try {
      // First verify with Cognito
      await confirmSignUp(email, verificationCode);
      setVerificationStatus('Email verified successfully! Signing in...');

      // Then sign in to get tokens
      const { token, refreshToken, user } = await signIn(email, password);
      
      // Update auth context with tokens
      await login(token, refreshToken);
      
      // Update user status and redirect
      updateUserStatus({ 
        ...user,
        isActive: true,
        isAccountHolder: true 
      });

      setVerificationStatus('Successfully signed in! Redirecting to onboarding...');
      setTimeout(() => {
        navigate('/student-onboarding');
      }, 2000);
    } catch (error) {
      console.error('Verification failed:', error);
      setVerificationStatus(
        error.response?.data?.message || 
        error.message || 
        'Verification failed. Please try again.'
      );
    }
  };

  const handleResend = async () => {
    setResendStatus('Resending verification code...');
    const email = localStorage.getItem('userEmail');

    try {
      await resendConfirmationCode(email);
      setResendStatus('Verification code resent successfully. Please check your email.');
    } catch (error) {
      console.error('Failed to resend verification code:', error);
      setResendStatus('Failed to resend verification code. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-5xl font-extrabold text-eduvibe-green text-center mb-12">Verify Your Email</h1>
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg max-w-lg mx-auto text-center">
            <form onSubmit={handleVerify}>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter verification code"
                className="w-full px-4 py-2 rounded-lg text-eduvibe-dark mb-4"
                required
              />
              <button
                type="submit"
                className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
              >
                Verify Email
              </button>
            </form>
            {verificationStatus && (
              <p className="mt-4 text-white bg-opacity-75 bg-gray-800 p-2 rounded">
                {verificationStatus}
              </p>
            )}
            <button
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-3 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300 mt-4"
              onClick={handleResend}
            >
              Resend Verification Code
            </button>
            {resendStatus && (
              <p className="mt-4 text-white bg-opacity-75 bg-gray-800 p-2 rounded">
                {resendStatus}
              </p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default VerifyEmail;