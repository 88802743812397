// src/components/CurriculumOverview/CurriculumOverview.js

import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
// Ensure correct path to api and logger
import api from '../../services/api'; 
import { logger } from '../../utils/logger';

const CurriculumOverview = () => {
  const [curricula, setCurricula] = useState([]);
  const [expandedCurriculum, setExpandedCurriculum] = useState(null);
  const [expandedGrade, setExpandedGrade] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);

  // Define fetch functions outside useEffect so they can be potentially reused
  // Using useCallback to memoize if needed, but not strictly necessary here

  const fetchGrades = async (curriculumId) => {
    // Prevent fetching if grades already exist for this curriculum
    const currentCurriculum = curricula.find(c => c._id === curriculumId);
    // Check if grades exist, are not empty, and the first element is a populated object (has an _id)
    const gradesArePopulated = currentCurriculum &&
                               currentCurriculum.grades &&
                               currentCurriculum.grades.length > 0 &&
                               typeof currentCurriculum.grades[0] === 'object' &&
                               currentCurriculum.grades[0] !== null &&
                               currentCurriculum.grades[0]._id; // Check specifically for _id

    // Removed the check that prevented fetching if grades seemed populated.
    // Now, fetchGrades will always attempt the API call when invoked by toggleCurriculum.
    // if (gradesArePopulated) { // Keep this commented block as reference if needed later
    //     logger.info('Grades already fully fetched (found _id in first grade) for', { curriculumId });
    //     return;
    // }

    logger.info('Proceeding to fetch grades for curriculum:', { curriculumId }); // Updated log
    try {
      setError(null); // Clear previous errors
      const response = await api.get(`/grades/${curriculumId}`);
      const gradesData = response.data || [];
      logger.info('Fetched grades successfully:', { curriculumId, count: gradesData.length });
      
      setCurricula(prevCurricula => 
        prevCurricula.map(curriculum => 
          curriculum._id === curriculumId 
            ? { ...curriculum, grades: gradesData } 
            : curriculum
        )
      );
    } catch (err) {
      const message = `Failed to fetch grades for curriculum ${curriculumId}`;
      console.error(">>> RAW ERROR caught in fetchGrades:", err); 
      console.error(">>> err.message:", err.message);
      console.error(">>> err.response:", err.response); 
      console.error(">>> err.config:", err.config);
      logger.error(message, { 
          errorMessage: err.message, 
          status: err?.response?.status, // Safely access status
          url: err?.config?.url,
          curriculumId 
      });
      setError(message + (err.message ? `: ${err.message}` : ''));
      // Optionally clear grades for this curriculum on error?
      // setCurricula(prevCurricula => 
      //   prevCurricula.map(curriculum => 
      //     curriculum._id === curriculumId 
      //       ? { ...curriculum, grades: undefined } // Reset grades
      //       : curriculum
      //   )
      // );
    }
  };

  const fetchSubjects = async (gradeId) => {
    // Removed the initial check for gradeId. The API call will now always be attempted.
    // The try/catch block below will handle potential errors if gradeId is invalid.

    // Removed the redundant check for existing subjects.
    // toggleGrade now ensures this is called only when expanding.
    // const gradeExists = curricula.some(curr => curr.grades?.some(g => g._id === gradeId && g.subjects));
    // if (gradeExists) {
    //     logger.info('Subjects already fetched for grade', { gradeId });
    //     return; // <-- REMOVED THIS BLOCK
    // }

    logger.info('Proceeding to fetch subjects for grade:', { gradeId }); // Updated log
    try {
      setError(null);
      const response = await api.get(`/subjects/${gradeId}`);
      const subjectsData = response.data || [];
      logger.info('Fetched subjects successfully:', { gradeId, count: subjectsData.length });

      setCurricula(prevCurricula => 
        prevCurricula.map(curriculum => ({
          ...curriculum,
          grades: (curriculum.grades || []).map(grade => 
            grade._id === gradeId 
              ? { ...grade, subjects: subjectsData } 
              : grade
          )
        }))
      );
    } catch (err) {
      const message = `Failed to fetch subjects for grade ${gradeId}`;
      console.error(">>> RAW ERROR caught in fetchSubjects:", err); 
      console.error(">>> err.message:", err.message);
      console.error(">>> err.response:", err.response); 
      console.error(">>> err.config:", err.config);
      logger.error(message, { 
          errorMessage: err.message, 
          status: err?.response?.status, 
          url: err?.config?.url,
          gradeId 
      });
      setError(message + (err.message ? `: ${err.message}` : ''));
      // Optionally clear subjects on error
    }
  };

  // Initial fetch for curricula
  useEffect(() => {
    const fetchInitialCurricula = async () => {
      setLoading(true); // Start loading
      setError(null);
      logger.info('Attempting to fetch initial curricula...');
      try {
        // Explicitly use the api instance configured in api.js
        const response = await api.get('/curricula'); 
        const curriculaData = response.data || [];
        logger.info('Fetched initial curricula successfully:', { count: curriculaData.length });
        setCurricula(curriculaData);
      } catch (err) { // Rename to err to avoid conflict
        const message = 'Failed to fetch initial curricula list';
        // DETAILED LOGGING:
        console.error(">>> RAW ERROR caught in fetchInitialCurricula:", err); 
        console.error(">>> typeof err:", typeof err);
        console.error(">>> err.message:", err.message);
        console.error(">>> err.name:", err.name);
        console.error(">>> err.response:", err.response); // Check if it's an Axios error with response
        console.error(">>> err.request:", err.request); // Check if it's an Axios error about the request
        console.error(">>> err.config:", err.config);   // Axios might add request config
        console.error(">>> err stack:", err.stack);     // Log stack trace if available
        
        logger.error(message, { 
            // Log enriched error data safely
            error_message: err?.message || 'Unknown error message',
            error_name: err?.name,
            error_stack: err?.stack?.substring(0, 500), // Log part of the stack
            response_status: err?.response?.status,
            response_data: err?.response?.data,
            request_url: err?.config?.url,
            request_method: err?.config?.method,
        });
        setError(message + (err.message ? `: ${err.message}` : ''));
        setCurricula([]); // Ensure curricula is empty on error
      } finally {
        setLoading(false); // Stop loading regardless of success/error
      }
    };

    fetchInitialCurricula();
  }, []); // Empty dependency array ensures this runs only once on mount


  const toggleCurriculum = (curriculumId) => {
    if (expandedCurriculum === curriculumId) {
      setExpandedCurriculum(null);
      setExpandedGrade(null); // Collapse grades too
    } else {
      setExpandedCurriculum(curriculumId);
      setExpandedGrade(null);
      // Fetch grades only if they haven't been fetched before OR if they are just IDs
      const currentCurriculumData = curricula.find(c => c._id === curriculumId);
      // Check if grades array is missing OR if it exists but the first element is a string
      const needsFetching = !currentCurriculumData?.grades ||
                            (currentCurriculumData.grades.length > 0 && typeof currentCurriculumData.grades[0] === 'string');
      logger.info('Toggle curriculum check', { curriculumId, needsFetching, grades: currentCurriculumData?.grades });
      // Removed the 'if (needsFetching)' check. fetchGrades will now be called every time
      // a curriculum is expanded (unless it was already expanded).
      fetchGrades(curriculumId);
    }
  };

  const toggleGrade = (gradeId) => {
    if (expandedGrade === gradeId) {
      setExpandedGrade(null);
    } else {
      // Ensure gradeId is valid before proceeding
      if (!gradeId) {
        logger.warn('toggleGrade called with invalid gradeId:', { gradeId });
        return; // Prevent further execution
      }
      setExpandedGrade(gradeId);
      // Always attempt to fetch subjects when expanding a grade.
      fetchSubjects(gradeId);
    }
  };

  // Render Loading State
  if (loading) {
    return (
      <section className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl mb-16 text-center">
        <p className="text-xl text-white">Loading curricula...</p>
        {/* Optional: Add a spinner here */}
      </section>
    );
  }

  // Render Error State
  if (error && curricula.length === 0) { // Show specific error message if initial load failed
    return (
      <section className="bg-red-900 bg-opacity-50 border border-red-700 p-8 rounded-xl mb-16 text-center">
        <h3 className="text-2xl font-bold text-red-300 mb-2">Error Loading Data</h3>
        <p className="text-red-400">{error}</p>
        <button 
           onClick={() => window.location.reload()} // Simple retry by reloading page
           className="mt-4 px-4 py-2 bg-eduvibe-green text-white rounded hover:bg-opacity-80"
        >
          Retry
        </button>
      </section>
    );
  }
  
  // Render No Data State (if API returns empty array successfully)
  if (!loading && curricula.length === 0) {
    return (
        <section className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl mb-16 text-center">
          <h2 className="text-3xl font-bold mb-4 text-white">Explore Our Supported Curricula</h2>
          <p className="text-xl text-white">No curricula currently available. Please check back later.</p>
        </section>
      );
  }

  // Render Main Content
  return (
    <section className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl mb-16">
      <h2 className="text-3xl font-bold mb-4 text-white">Explore Our Supported Curricula</h2>
      {/* Display error message subtly if it occurred during grade/subject fetch */}
      {error && <p className="text-red-400 mb-4 text-sm">Notice: {error}</p>} 
      <p className="text-xl mb-6 text-white">
        The list of curricula, grades, and subjects we support is growing. 
        Your support helps us expand this list and bring music-powered learning to more students!
      </p>
      <div className="space-y-4">
        {curricula.map(curriculum => (
          <div key={`curriculum-${curriculum._id}`} className="border border-eduvibe-green rounded-lg overflow-hidden">
            <button
              className="w-full text-left p-4 bg-eduvibe-blue hover:bg-eduvibe-green-dark transition-colors duration-300 flex justify-between items-center text-white" // Ensure text is visible
              onClick={() => toggleCurriculum(curriculum._id)}
              aria-expanded={expandedCurriculum === curriculum._id}
              aria-controls={`curriculum-content-${curriculum._id}`}
            >
              <span className="text-lg font-semibold">{curriculum.name}</span>
              {expandedCurriculum === curriculum._id ? <ChevronDown /> : <ChevronRight />}
            </button>
            {expandedCurriculum === curriculum._id && (
              <div id={`curriculum-content-${curriculum._id}`} className="pl-8 py-2 bg-eduvibe-dark bg-opacity-50 text-white"> {/* Ensure text visible */}
                {/* Check if grades exist before mapping */}
                {!curriculum.grades ? (<p className="text-sm p-2">Loading grades...</p>) :
                 curriculum.grades.length === 0 ? (<p className="text-sm p-2">No grades found for this curriculum.</p>) :
                 (curriculum.grades || []).map(grade => (
                  <div key={`grade-${grade._id}`} className="mb-2">
                    <button
                      className="w-full text-left p-2 hover:bg-eduvibe-blue hover:bg-opacity-30 transition-colors duration-300 flex justify-between items-center"
                      onClick={() => toggleGrade(grade._id)}
                      aria-expanded={expandedGrade === grade._id}
                      aria-controls={`grade-content-${grade._id}`}
                    >
                      <span>{grade.name}</span>
                      {expandedGrade === grade._id ? <ChevronDown /> : <ChevronRight />}
                    </button>
                    {expandedGrade === grade._id && (
                      <div id={`grade-content-${grade._id}`} className="pl-8 py-2">
                        {/* Check if subjects exist */}
                        {!grade.subjects ? (<p className="text-xs italic">Loading subjects...</p>) :
                         grade.subjects.length === 0 ? (<p className="text-xs italic">No subjects found.</p>) :
                         (
                          <ul className="list-disc list-inside"> {/* Add list style */}
                            {(grade.subjects || []).map(subject => subject && subject._id ? (
                              <li key={`subject-${subject._id}`} className="text-sm py-1">
                                {subject.name}
                              </li>
                            ) : null)}
                          </ul>
                        )}
                      </div>
                    )}
                  </div>
                 ))
                }
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default CurriculumOverview;
