import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
// import { Fireworks } from '@fireworks-js/react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import api from '../services/api';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';

const StudentOnboarding = () => {
  const { updateUserStatus, fetchUserData, user } = useAuth(); 
  const [curriculums, setCurriculums] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [curriculumsRes, subjectsRes] = await Promise.all([
          api.get('/curricula'),
          api.get('/subjects')
        ]);
        
        console.log('Curricula response:', curriculumsRes);
        console.log('Subjects response:', subjectsRes);

        if (Array.isArray(curriculumsRes.data)) {
          setCurriculums(curriculumsRes.data);
        } else {
          console.error('Curricula data is not an array:', curriculumsRes.data);
          setError('Error fetching curricula data');
        }

        if (Array.isArray(subjectsRes.data)) {
          setSubjects(subjectsRes.data);
        } else {
          console.error('Subjects data is not an array:', subjectsRes.data);
          setError('Error fetching subjects data');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
        if (error.response) {
          console.error('Error response:', error.response);
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        setError(`Error fetching data: ${error.message}`);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchGrades = async () => {
      if (selectedCurriculum) {
        try {
          const gradesRes = await api.get(`/grades/${selectedCurriculum}`);
          console.log('Grades response:', gradesRes.data);
          setGrades(gradesRes.data);
        } catch (error) {
          console.error('Error fetching grades:', error);
          setError('Error fetching grades. Please try again.');
        }
      } else {
        setGrades([]);
      }
    };
    fetchGrades();
  }, [selectedCurriculum]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!selectedCurriculum || !selectedGrade || selectedSubjects.length === 0) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const response = await api.post('/user/preferences', {
        preferredCurriculum: selectedCurriculum,
        preferredGrade: selectedGrade,
        preferredSubjects: selectedSubjects
      });

      if (response.data) {
        const userData = await fetchUserData();
        updateUserStatus({ ...userData, preferredCurriculum: selectedCurriculum });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
      setError('Failed to save preferences. Please try again.');
    }
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-eduvibe-blue text-white">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Oops! Something went wrong.</h1>
          <p className="text-xl">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-8 bg-eduvibe-green text-eduvibe-dark px-6 py-2 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col text-white overflow-hidden relative">
      {/* <Fireworks
        options={{
          rocketsPoint: {
            min: 0,
            max: 100
          },
          explosion: 5,
          intensity: 30,
          traceLength: 3,
          traceSpeed: 10,
          flickering: 50,
        }}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: 'transparent'
        }}
      /> */}
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-6xl font-extrabold text-eduvibe-green text-center mb-8 animate-pulse">Welcome to the EduVibe Tribe!</h1>
          <p className="text-3xl text-center mb-12 text-eduvibe-blue animate-bounce">Get ready to rock your education! Let's personalize your learning journey.</p>
          <form onSubmit={handleSubmit} className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-xl backdrop-filter backdrop-blur-lg max-w-2xl mx-auto transform hover:scale-105 transition-transform duration-300">
            <div className="mb-6">
              <label className="block text-lg font-bold mb-4" htmlFor="curriculum">Select Your Curriculum</label>
              <select
                id="curriculum"
                value={selectedCurriculum}
                onChange={(e) => setSelectedCurriculum(e.target.value)}
                className="w-full px-4 py-3 rounded-lg text-eduvibe-dark text-lg appearance-none bg-white bg-no-repeat bg-right pr-10"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23000'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                  backgroundSize: '1.5rem'
                }}
                required
              >
                <option value="">Choose a curriculum</option>
                {Array.isArray(curriculums) && curriculums.map((curriculum) => (
                  <option key={curriculum._id} value={curriculum._id}>{curriculum.name}</option>
                ))}
              </select>
            </div>
            <div className="mb-6">
              <label className="block text-lg font-bold mb-4" htmlFor="grade">Select Your Grade</label>
              <select
                id="grade"
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
                className="w-full px-4 py-3 rounded-lg text-eduvibe-dark text-lg appearance-none bg-white bg-no-repeat bg-right pr-10"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23000'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                  backgroundSize: '1.5rem'
                }}
                required
              >
                <option value="">Choose a grade</option>
                {Array.isArray(grades) && grades.map((grade) => (
                  <option key={grade._id} value={grade._id}>{grade.name}</option>
                ))}
              </select>
            </div>
            <div className="mb-6">
              <label className="block text-lg font-bold mb-4">Select Your Subjects</label>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {Array.isArray(subjects) && subjects.map((subject) => (
                  <label key={subject._id} className="flex items-center space-x-3 p-4 bg-eduvibe-dark bg-opacity-50 rounded-lg hover:bg-opacity-70 transition-all cursor-pointer">
                    <input
                      type="checkbox"
                      value={subject._id}
                      checked={selectedSubjects.includes(subject._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSubjects([...selectedSubjects, subject._id]);
                        } else {
                          setSelectedSubjects(selectedSubjects.filter(id => id !== subject._id));
                        }
                      }}
                      className="w-6 h-6 rounded border-2 border-eduvibe-green accent-eduvibe-green cursor-pointer"
                    />
                    <span className="text-lg">{subject.name}</span>
                  </label>
                ))}
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-eduvibe-green text-eduvibe-dark px-8 py-4 rounded-full text-xl font-bold hover:bg-eduvibe-blue transition duration-300 mt-8"
            >
              Let's Get Started!
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default StudentOnboarding;