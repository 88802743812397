import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Lock } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import { changePassword } from '../services/api';
import { toast } from 'react-hot-toast';
import '../styles/northernLights.css';
import '../styles/index.css';
import '../styles/styles.css';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    return requirements;
  };

  useEffect(() => {
    const requirements = validatePassword(newPassword);
    setPasswordStrength(requirements);
  }, [newPassword]);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmPassword && newPassword !== '');
  }, [newPassword, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsSubmitting(true);

    if (!Object.values(passwordStrength).every(Boolean)) {
      setError('New password does not meet all requirements');
      setIsSubmitting(false);
      return;
    }

    if (!passwordsMatch) {
      setError('Passwords do not match');
      setIsSubmitting(false);
      return;
    }

    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      setError(err.message || 'Failed to change password');
      toast.error(err.message || 'Failed to change password');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <h1 className="text-4xl md:text-6xl font-bold text-center text-eduvibe-green mb-8">
            Change Password
          </h1>

          <div className="max-w-md mx-auto bg-eduvibe-dark bg-opacity-80 p-6 rounded-3xl shadow-2xl">
            {error && (
              <div className="mb-4 p-3 bg-red-500 bg-opacity-20 text-red-100 rounded-lg">
                {error}
              </div>
            )}
            {success && (
              <div className="mb-4 p-3 bg-green-500 bg-opacity-20 text-green-100 rounded-lg">
                {success}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="currentPassword" className="block text-lg font-bold mb-2">
                  Current Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showCurrentPassword ? "text" : "password"}
                    id="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showCurrentPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="newPassword" className="block text-lg font-bold mb-2">
                  New Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showNewPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
                <div className="mt-2 text-sm">
                  <p className={passwordStrength.length ? 'text-green-500' : 'text-red-500'}>
                    • At least 8 characters
                  </p>
                  <p className={passwordStrength.uppercase ? 'text-green-500' : 'text-red-500'}>
                    • At least one uppercase letter
                  </p>
                  <p className={passwordStrength.lowercase ? 'text-green-500' : 'text-red-500'}>
                    • At least one lowercase letter
                  </p>
                  <p className={passwordStrength.number ? 'text-green-500' : 'text-red-500'}>
                    • At least one number
                  </p>
                  <p className={passwordStrength.special ? 'text-green-500' : 'text-red-500'}>
                    • At least one special character
                  </p>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="confirmPassword" className="block text-lg font-bold mb-2">
                  Confirm New Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark w-6 h-6" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg text-eduvibe-dark"
                    required
                    style={{ paddingLeft: '2.5rem' }}
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-eduvibe-dark cursor-pointer hover:text-gray-600"
                  >
                    {showConfirmPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  </span>
                </div>
                {confirmPassword && (
                  <p className={`mt-2 text-sm ${passwordsMatch ? 'text-green-500' : 'text-red-500'}`}>
                    {passwordsMatch ? '✓ Passwords match' : '✗ Passwords do not match'}
                  </p>
                )}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-full text-lg font-bold hover:bg-eduvibe-blue transition duration-300"
              >
                {isSubmitting ? 'Changing Password...' : 'Change Password'}
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChangePassword;