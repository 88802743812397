import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Music, Brain, Lock, School, MessageCircle, PenTool, Star } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const FAQItem = ({ question, answer, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 bg-eduvibe-dark bg-opacity-60 rounded-lg overflow-hidden">
      <button
        className="w-full text-left p-4 focus:outline-none flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center text-xl font-semibold">
          {React.cloneElement(icon, { className: "mr-2" })}
          {question}
        </span>
        <ChevronDown
          className={`transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="p-4 bg-eduvibe-blue bg-opacity-20">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => {
  const faqItems = [
    {
      icon: <Music />,
      question: "What exactly is EduVibz?",
      answer: "EduVibz is an innovative educational platform that harnesses the power of music to enhance learning. We transform curriculum content into catchy, memorable songs, making studying more engaging and effective. It's like having a personal tutor who's also a chart-topping artist!"
    },
    {
      icon: <Brain />,
      question: "How does learning with music actually work?",
      answer: "Great question! Learning with music taps into the brain's natural ability to process and remember patterns. When information is set to music, it engages multiple areas of the brain simultaneously, creating stronger neural connections. This can lead to improved recall and understanding. Plus, it's just more fun! Check out our 'How It Works' page for more scientific details."
    },
    {
      icon: <School />,
      question: "Is EduVibz suitable for all ages and subjects?",
      answer: "Absolutely! Our list may be small now, but it is ever growing. Have a look at the curriculae, grades and subjects covered. Feel free to request yours if it is not there in the feature request tab on our contact page. EduVibz is designed to cater to learners of all ages, from primary school to university level. Our AI-powered system can adapt content for any subject, whether it's mathematics, history, languages, or sciences. The tunes might change, but the effectiveness remains!"
    },
    {
      icon: <Lock />,
      question: "How does EduVibz protect my privacy?",
      answer: (
        <div>
          <p>We take your privacy seriously at EduVibz. All personal data is encrypted and stored securely. We never share your information with third parties without your explicit consent.</p>
          <p className="mt-2">For more details, please review our <a href="/privacy-policy" className="text-eduvibe-green hover:underline">Privacy Policy</a>.</p>
        </div>
      )
    },
    {
      icon: <MessageCircle />,
      question: "How can I get in touch with EduVibz?",
      answer: (
        <div>
          <p>We'd love to hear from you! Here's how you can reach us:</p>
          <ul className="list-disc list-inside mt-2">
            <li>For general inquiries: Visit our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>For schools wanting to join the waitlist: Use the 'Waitlist' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>To apply as a music contributor: Fill out the 'Contribute' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>To request features or songs: Use the 'Feature' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
          </ul>
        </div>
      )
    },
    {
      icon: <PenTool />,
      question: "Can I contribute my own educational songs to EduVibz?",
      answer: "Absolutely! We're always looking for talented individuals to contribute to our musical education revolution. If you're interested in creating educational content for EduVibz, please fill out the 'Contribute' form on our Contact Page. Note that we have a revenue share agreement for accepted contributions."
    },
    {
      icon: <Star />,
      question: "Can I request specific songs or features?",
      answer: "Of course! We love hearing from our users. You can submit feature or song requests through the 'Feature Request' form on our Contact Page. While we can't guarantee that all requests will be implemented, we seriously consider all user feedback. Please note that no royalties are payable for feature requests."
    },
    {
      icon: <School />,
      question: "I'm a teacher/school administrator. How can we use EduVibz in our curriculum?",
      answer: "We're excited to work with educators! EduVibz is working on a solution that can be integrated into your curriculum in various ways, from custom quizzes to monitoring and achievements. If you're interested in bringing EduVibz to your school, please fill out the 'Waitlist' form on our Contact Page. We'll get in touch to discuss how we can best serve your specific educational needs."
    },
    {
      icon: <Lock />,
      question: "What are the terms of service for using EduVibz?",
      answer: (
        <div>
          <p>Our terms of service outline the rules and regulations for using the EduVibz platform. This includes information about user accounts, content usage, and our policies on data protection.</p>
          <p className="mt-2">For the full details, please read our <a href="/terms-of-service" className="text-eduvibe-green hover:underline">Terms of Service</a>.</p>
        </div>
      )
    },
    {
      icon: <Brain />,
      question: "Is EduVibz backed by scientific research?",
      answer: "Yes, indeed! The concept behind EduVibz is grounded in extensive scientific research on music and learning. Studies have shown that music can enhance memory formation, improve focus, and make learning more enjoyable. We're constantly updating our methods based on the latest research in neuroscience and education. Check out our 'How It Works' page for more details and references to specific studies."
    },
    {
      icon: <Music />,
      question: "What kind of music does EduVibz use?",
      answer: "Our vision is for EduVibz to use a wide variety of musical styles to cater to different tastes and learning preferences. Our system can create educational songs in genres ranging from pop and rock to classical and electronic. The list is continuously growing, so you will keep seeing even more value for your subscription. The goal is to find the perfect beat for every learner!"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Frequently Asked Questions
          </motion.h1>
          
          <motion.p
            className="text-xl text-center mb-12"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Got questions? We've got answers! Dive into our FAQ to learn more about EduVibz and how we're revolutionizing learning through music.
          </motion.p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {faqItems.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} icon={item.icon} />
            ))}
          </motion.div>

          <motion.div
            className="mt-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <p className="text-xl mb-4">Still have questions? We're here to help!</p>
            <a 
              href="/contact" 
              className="bg-eduvibe-green text-eduvibe-dark px-8 py-4 rounded-full text-xl font-bold hover:bg-eduvibe-blue transition duration-300 inline-flex items-center"
            >
              Contact Us
              <MessageCircle className="ml-2" />
            </a>
          </motion.div>
        </main>
      </div>
    </div>
  );
};

export default FAQ;