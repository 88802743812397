import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const AlbumRow = ({ albums, onAlbumSelect }) => {
  const rowRef = useRef(null);

  const scrollRow = (direction) => {
    const scrollAmount = direction === 'left' ? -400 : 400;
    rowRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  // Check if albums is an array and has items
  if (!Array.isArray(albums) || albums.length === 0) {
    return null; // or return a placeholder component
  }

  return (
    <div className="relative group">
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full bg-eduvibe-blue bg-opacity-70 text-eduvibe-green p-2 rounded-full z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block"
        onClick={() => scrollRow('left')}
      >
        <ChevronLeft size={24} />
      </button>
      <div
        ref={rowRef}
        className="flex overflow-x-auto space-x-4 pb-4 scrollbar-hide px-4 md:px-0"
      >
        {albums.map((album) => (
          <div 
            key={album._id} 
            className="flex-shrink-0 w-48 bg-eduvibe-blue bg-opacity-30 rounded-lg overflow-hidden transition-all duration-300 hover:bg-opacity-50 hover:scale-105 cursor-pointer"
            onClick={() => onAlbumSelect(album)}
          >
            <img 
              src={album.coverImage || '/default-album-cover.jpg'} 
              alt={album.name || album.title} 
              className="w-full h-48 object-cover" 
            />
            <div className="p-4">
              <h3 className="text-eduvibe-green font-semibold mb-1 truncate">
                {album.name || album.title}
              </h3>
              {album.subject && album.subject.name && (
                <p className="text-sm text-eduvibe-blue truncate">
                  {album.subject.name}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full bg-eduvibe-blue bg-opacity-70 text-eduvibe-green p-2 rounded-full z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block"
        onClick={() => scrollRow('right')}
      >
        <ChevronRight size={24} />
      </button>
    </div>
  );
};

export default AlbumRow;