import React from 'react';
import { Award, CheckCircle, Clock } from 'lucide-react';

const ChallengeCard = ({ challenge }) => {
  const isExpired = new Date(challenge.endDate) < new Date();
  const hasStarted = new Date(challenge.startDate) <= new Date();

  return (
    <div className={`bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg ${isExpired ? 'opacity-50' : ''}`}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-eduvibe-green">{challenge.title}</h2>
        <span className="text-3xl">{challenge.icon}</span>
      </div>
      <p className="text-lg mb-4">{challenge.description}</p>
      <div className="mb-4">
        <div className="bg-eduvibe-dark h-4 rounded-full">
          <div
            className="bg-eduvibe-green h-4 rounded-full"
            style={{ width: `${challenge.progress}%` }}
          ></div>
        </div>
        <p className="text-sm mt-1">
          Progress: {challenge.progress}%
        </p>
      </div>
      <div className="flex items-center text-eduvibe-green mb-4">
        <Award className="mr-2" />
        <p className="font-semibold">Reward: {challenge.reward}</p>
      </div>
      <div className="flex justify-between items-center">
        <span className="bg-eduvibe-green text-eduvibe-dark px-3 py-1 rounded-full text-sm">
          +{challenge.xpReward} XP
        </span>
        {challenge.completed && (
          <span className="flex items-center text-eduvibe-green">
            <CheckCircle className="mr-2" />
            Completed
          </span>
        )}
        {!challenge.completed && hasStarted && !isExpired && (
          <span className="flex items-center text-yellow-400">
            <Clock className="mr-2" />
            In Progress
          </span>
        )}
        {!hasStarted && (
          <span className="flex items-center text-blue-400">
            Coming Soon
          </span>
        )}
        {isExpired && !challenge.completed && (
          <span className="flex items-center text-red-400">
            Expired
          </span>
        )}
      </div>
      <div className="mt-2 text-sm text-gray-400">
        Difficulty: {challenge.difficulty}
      </div>
    </div>
  );
};

export default ChallengeCard;