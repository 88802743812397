import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api, { refreshToken as apiRefreshToken } from '../services/api';
import { jwtDecode } from 'jwt-decode';
import { initializeSocket } from '../services/socket';
import { logger } from '../utils/logger';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userStatus, setUserStatus] = useState('guest'); // 'guest', 'verified', 'complete'

    const updateUserStatus = useCallback((userData) => {
        if (!userData) {
            setUserStatus('guest');
        } else if (!userData.preferredCurriculum) {
            setUserStatus('verified');
        } else {
            setUserStatus('complete');
        }
    }, []);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await api.get('/auth/me');
            logger.debug('Fetched user data', { 
                user: response.data,
                status: response.status 
            });

            const userData = {
                ...response.data,
                sub: response.data.cognitoSub || response.data.sub || response.data['cognito:username'],
                isActive: true // Always set to true - remove subscription dependency
            };

            setUser(userData);
            setIsLoggedIn(true);
            updateUserStatus(userData);
            return userData;
        } catch (error) {
            logger.error('Failed to fetch user data', { error });
            setIsLoggedIn(false);
            setUser(null);
            updateUserStatus(null);
            throw error;
        }
    }, [updateUserStatus]);

    const refreshUserToken = useCallback(async () => {
        try {
            const refreshTokenValue = localStorage.getItem('refreshToken');
            if (!refreshTokenValue) throw new Error('No refresh token available');

            const response = await apiRefreshToken(refreshTokenValue);
            const { token, user: userData } = response.data;
            
            localStorage.setItem('token', token);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setIsLoggedIn(true);
            setUser({ ...userData, isActive: true }); // Always set to true
            updateUserStatus(userData);
            logger.debug('Token refreshed successfully');
        } catch (error) {
            logger.error('Failed to refresh token', { error });
            await logout();
        }
    }, [updateUserStatus]);

    const checkAuthStatus = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 < Date.now()) {
                    await refreshUserToken();
                } else {
                    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    await fetchUserData();
                }
            } catch (error) {
                logger.error('Auth status check failed', { error });
                await logout();
            }
        } else {
            setIsLoggedIn(false);
            setUser(null);
            updateUserStatus(null);
        }
        setLoading(false);
    }, [fetchUserData, refreshUserToken, updateUserStatus]);

    useEffect(() => {
        checkAuthStatus();
    }, [checkAuthStatus]);

    const login = useCallback(async (token, refreshTokenValue) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshTokenValue);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        try {
            const userData = await fetchUserData();
            logger.info('User logged in successfully', { user: userData });
            setUser({
                ...userData,
                cognitoSub: userData.cognitoSub || userData['cognito:username']
            });
            initializeSocket(userData.cognitoSub || userData['cognito:username']);
        } catch (error) {
            logger.error('Login failed', { error });
            throw error;
        }
    }, [fetchUserData]);

    const logout = useCallback(async () => {
        try {
            await api.post('/auth/logout');
            logger.info('User logged out successfully');
        } catch (error) {
            logger.error('Logout error', { error });
        } finally {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            delete api.defaults.headers.common['Authorization'];
            setIsLoggedIn(false);
            setUser(null);
            updateUserStatus(null);
        }
    }, [updateUserStatus]);

    const value = {
        isLoggedIn,
        user,
        userStatus,
        login,
        logout,
        refreshUserToken,
        loading,
        checkAuthStatus,
        fetchUserData,
        updateUserStatus
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};