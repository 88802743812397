// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import CookieConsent from './components/common/CookieConsent';
import ScrollHandler from './components/common/ScrollHandler';
import ErrorBoundary from './components/common/ErrorBoundary';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import MusicPlayer from './pages/MusicPlayer';
import ChangePassword from './pages/ChangePassword';
import ResetRequest from './pages/ResetRequest';
import SubscriptionPlans from './pages/SubscriptionPlans';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';
import VerifyEmailHandler from './pages/VerifyEmailHandler';
import Payment from './pages/Payment';
import StudentOnboarding from './pages/StudentOnboarding';
import BrowseMusic from './pages/BrowseMusic';
import AboutUs from './pages/AboutUs';
import HowItWorks from './pages/HowItWorks';
import ContactUs from './pages/ContactUs';
import FAQ from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ProfilePage from './pages/ProfilePage';
import PlaylistsPage from './pages/PlaylistsPage';
import ProgressPage from './pages/ProgressPage';
import SettingsPage from './pages/SettingsPage';
import ChallengesPage from './pages/ChallengesPage';
import CookiePolicy from './pages/CookiePolicy';
import ResetPassword from './pages/ResetPassword';
import { logger } from './utils/logger';

// Initialize Google Analytics
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-TVCG63M3Z1';
ReactGA.initialize(TRACKING_ID);

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

const AuthStatusChecker = ({ children }) => {
  const { checkAuthStatus, loading } = useAuth();

  useEffect(() => {
    logger.info('AuthStatusChecker mounted, checking auth status');
    if (!loading) {
      checkAuthStatus();
    }
  }, [checkAuthStatus, loading]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

const ContentWrapper = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const excludeFooterPaths = ['/music-player', '/music-player/chapter', '/music-player/song', '/music-player/playlist'];

  // Track page views
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    logger.info('Page view tracked', { path: location.pathname });
  }, [location]);

  const shouldExcludeFooter = 
    isLoggedIn || // Hide footer if user is logged in
    excludeFooterPaths.some(path => location.pathname.startsWith(path));

  return (
    <div className="flex flex-col min-h-screen">
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <main className="flex-grow">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      {!shouldExcludeFooter && (
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      )}
    </div>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <AuthProvider>
          <Router>
            <AuthStatusChecker>
              <ScrollHandler>
                <ContentWrapper>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="/music-player/chapter/:chapterId" element={<ProtectedRoute><MusicPlayer /></ProtectedRoute>} />
                    <Route path="/music-player/song/:songId" element={<ProtectedRoute><MusicPlayer /></ProtectedRoute>} />
                    <Route path="/music-player/playlist/:playlistId" element={<ProtectedRoute><MusicPlayer /></ProtectedRoute>} />
                    <Route path="/music-player" element={<ProtectedRoute><MusicPlayer /></ProtectedRoute>} />
                    <Route path="/changepassword" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
                    <Route path="/resetrequest" element={<ResetRequest />} />
                    <Route path="/pricing" element={<SubscriptionPlans />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/verify-email-handler" element={<VerifyEmailHandler />} />
                    <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
                    <Route path="/student-onboarding" element={<ProtectedRoute><StudentOnboarding /></ProtectedRoute>} />
                    <Route path="/browse" element={<ProtectedRoute><BrowseMusic /></ProtectedRoute>} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                    <Route path="/playlists" element={<ProtectedRoute><PlaylistsPage /></ProtectedRoute>} />
                    <Route path="/progress" element={<ProtectedRoute><ProgressPage /></ProtectedRoute>} />
                    <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
                    <Route path="/challenges" element={<ProtectedRoute><ChallengesPage /></ProtectedRoute>} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                  </Routes>
                </ContentWrapper>
              </ScrollHandler>
            </AuthStatusChecker>
            <ErrorBoundary>
              <CookieConsent />
            </ErrorBoundary>
          </Router>
        </AuthProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;