// src/components/playlists/CreatePlaylistModal.js
import React, { useState } from 'react';
import { X, Music } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const CreatePlaylistModal = ({ isOpen, onClose, onCreatePlaylist, initialSong }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('personal');
  const [isPublic, setIsPublic] = useState(false);
  const { user } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPlaylist = {
      name,
      description,
      type,
      isPublic
    };
    onCreatePlaylist(newPlaylist, initialSong);
    setName('');
    setDescription('');
    setType('personal');
    setIsPublic(false);
  };

  if (!isOpen) return null;

  const canCreateClassPlaylist = user.planId === 'school' && ['teacher', 'administrator'].includes(user.role);

  const playlistTypes = [
    { id: 'personal', label: 'Personal' },
    ...(user.planId === 'family' ? [{ id: 'family', label: 'Family' }] : []),
    ...(canCreateClassPlaylist ? [{ id: 'class', label: 'Class' }] : []),
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-eduvibe-dark border border-eduvibe-blue rounded-lg p-6 w-full max-w-md relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-eduvibe-green hover:text-white p-2 rounded-full bg-eduvibe-green flex items-center justify-center"
          style={{ width: '48px', height: '48px' }}
        >
          <X size={24} color="white" />
        </button>
        <h2 className="text-2xl font-bold text-eduvibe-green flex items-center mb-4">
          <Music className="mr-2" /> Create New Playlist
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Playlist Name"
            className="w-full p-2 bg-eduvibe-blue bg-opacity-20 border border-eduvibe-blue rounded-lg mb-4 focus:outline-none focus:border-eduvibe-green"
            required
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Playlist Description"
            className="w-full p-2 bg-eduvibe-blue bg-opacity-20 border border-eduvibe-blue rounded-lg mb-4 focus:outline-none focus:border-eduvibe-green"
            rows="3"
          />
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Playlist Type</label>
            <div className="flex flex-wrap gap-2">
              {playlistTypes.map(playlistType => (
                <button
                  key={playlistType.id}
                  type="button"
                  onClick={() => setType(playlistType.id)}
                  className={`flex items-center px-3 py-2 rounded-full ${
                    type === playlistType.id ? 'bg-eduvibe-green text-eduvibe-dark' : 'bg-eduvibe-blue bg-opacity-20 text-white'
                  }`}
                >
                  {playlistType.label}
                </button>
              ))}
            </div>
          </div>
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              className="mr-2"
            />
            Make this playlist public
          </label>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePlaylistModal;
