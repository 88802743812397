// src/components/common/CookieConsent.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Settings, X, Check } from 'lucide-react';
import { cookieService } from '../../services/cookieService';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: true,
  });

  useEffect(() => {
    const hasExistingConsent = cookieService.hasConsent();
    setShowConsent(!hasExistingConsent);
  }, []);

  const handleAcceptAll = () => {
    const allPreferences = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };
    cookieService.setConsent(true);
    cookieService.setPreferences(allPreferences);
    setPreferences(allPreferences);
    setShowConsent(false);
  };

  const handleSavePreferences = () => {
    cookieService.setConsent(true);
    cookieService.setPreferences(preferences);
    setShowConsent(false);
    setShowPreferences(false);
  };

  if (!showConsent) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      <div className="bg-eduvibe-dark bg-opacity-95 backdrop-blur-lg border-t border-eduvibe-blue border-opacity-30">
        <div className="container mx-auto px-4 py-6">
          {!showPreferences ? (
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
              <div className="text-white text-center md:text-left">
                We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{" "}
                <Link to="/cookie-policy" className="text-eduvibe-green hover:underline">
                  Learn more
                </Link>
              </div>
              <div className="flex gap-3">
                <button
                  onClick={() => setShowPreferences(true)}
                  className="px-8 py-3 rounded-full bg-eduvibe-blue text-white hover:bg-opacity-90 transition-all duration-300 text-base font-semibold"
                >
                  Customize
                </button>
                <button
                  onClick={handleAcceptAll}
                  className="px-8 py-3 rounded-full bg-eduvibe-green text-eduvibe-dark hover:bg-opacity-90 transition-all duration-300 text-base font-semibold"
                >
                  Accept All
                </button>
              </div>
            </div>
          ) : (
            <div className="text-white max-w-3xl mx-auto">
              <h3 className="text-2xl font-bold mb-6 flex items-center text-eduvibe-green">
                <Settings className="mr-2" /> Cookie Preferences
              </h3>
              <div className="space-y-4 mb-6">
                <div className="flex items-center bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl">
                  <input
                    type="checkbox"
                    checked={preferences.necessary}
                    disabled
                    className="mr-4 w-6 h-6 md:w-8 md:h-8 cursor-not-allowed"
                  />
                  <div>
                    <label className="font-semibold block">Necessary Cookies</label>
                    <span className="text-sm opacity-80">Required for the website to function</span>
                  </div>
                </div>
                {[
                  {
                    id: 'functional',
                    title: 'Functional Cookies',
                    description: 'For enhanced functionality and preferences'
                  },
                  {
                    id: 'analytics',
                    title: 'Analytics Cookies',
                    description: 'Help us improve by tracking usage'
                  },
                  {
                    id: 'marketing',
                    title: 'Marketing Cookies',
                    description: 'For personalized advertisements'
                  }
                ].map((cookie) => (
                  <div key={cookie.id} className="flex items-center bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl">
                    <input
                      type="checkbox"
                      checked={preferences[cookie.id]}
                      onChange={(e) => setPreferences({ ...preferences, [cookie.id]: e.target.checked })}
                      className="mr-4 w-6 h-6 md:w-8 md:h-8 cursor-pointer"
                    />
                    <div>
                      <label className="font-semibold block">{cookie.title}</label>
                      <span className="text-sm opacity-80">{cookie.description}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setShowPreferences(false)}
                  className="px-8 py-3 rounded-full bg-eduvibe-blue text-white hover:bg-opacity-90 transition-all duration-300 text-base font-semibold flex items-center"
                >
                  <X className="w-5 h-5 mr-2" /> Cancel
                </button>
                <button
                  onClick={handleSavePreferences}
                  className="px-8 py-3 rounded-full bg-eduvibe-green text-eduvibe-dark hover:bg-opacity-90 transition-all duration-300 text-base font-semibold flex items-center"
                >
                  <Check className="w-5 h-5 mr-2" /> Save Preferences
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;