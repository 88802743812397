// client/src/services/socket.js

import io from 'socket.io-client';
import { logger } from '../utils/logger';

let socket = null;
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_DELAY = 2000;
const CONNECTION_TIMEOUT = 10000;

// Derive socket URL from API URL environment variable for consistency
const getSocketUrl = () => {
  const apiUrlFromEnv = process.env.REACT_APP_API_URL;
  
  if (apiUrlFromEnv) {
    // If REACT_APP_API_URL is set, derive the base URL (remove /api if present)
    // Use URL constructor for robust parsing
    try {
      const url = new URL(apiUrlFromEnv);
      // Return origin (scheme + hostname + port)
      return url.origin; 
    } catch (e) {
      logger.error('Invalid REACT_APP_API_URL format, falling back for socket.', { url: apiUrlFromEnv });
      // Fallback to a sensible production default if parsing fails
      return 'https://api.eduvibz.com'; 
    }
  } else {
    // If REACT_APP_API_URL is NOT set, assume production or a default
    logger.warn('REACT_APP_API_URL not set, using default production socket URL.');
    return 'https://api.eduvibz.com'; // Default production URL
    // Or fallback to localhost for local dev if preferred:
    // return 'http://localhost:5000'; 
  }
};

const SOCKET_URL = getSocketUrl();
logger.info(`[Socket Config] Using Socket URL: ${SOCKET_URL}`);


const cleanupSocket = (timeoutId) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  if (socket) {
    try {
      socket.removeAllListeners();
      socket.close();
      socket.disconnect();
    } catch (error) {
      logger.error('Error cleaning up socket', {
        error: error.message,
        socketId: socket?.id
      });
    }
    socket = null;
    reconnectAttempts = 0;
  }
};

const initializeSocket = (token) => {
  return new Promise((resolve, reject) => {
    if (!token) {
      logger.warn('No token provided for socket connection');
      return resolve(null);
    }

    // If socket is already connected, return it
    if (socket?.connected) {
      logger.debug('Socket already connected', { socketId: socket.id });
      return resolve(socket);
    }

    // Clean up any existing socket
    cleanupSocket();

    let timeoutId = null;

    logger.debug('Initializing socket connection', { url: SOCKET_URL });
    
    try {
      socket = io(SOCKET_URL, {
        auth: { token },
        reconnection: true,
        reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
        reconnectionDelay: RECONNECT_DELAY,
        timeout: CONNECTION_TIMEOUT,
        transports: ['polling', 'websocket'], // Try polling first, then upgrade to websocket
        upgrade: true,
        forceNew: true,
        autoConnect: false // Don't connect automatically
      });

      // Set up connection timeout
      timeoutId = setTimeout(() => {
        if (!socket?.connected) {
          const error = new Error('Socket connection timeout');
          logger.error('Socket connection timeout', {
            socketId: socket?.id,
            reconnectAttempts
          });
          cleanupSocket(timeoutId);
          reject(error);
        }
      }, CONNECTION_TIMEOUT);

      // Set up event handlers before connecting
      socket.on('connect', () => {
        clearTimeout(timeoutId);
        logger.info('Socket connected', {
          socketId: socket.id,
          reconnectAttempts
        });
        reconnectAttempts = 0;
        resolve(socket);
      });

      socket.on('connect_error', (error) => {
        reconnectAttempts++;
        logger.error('Socket connection error', {
          error: error.message,
          socketId: socket?.id,
          attempt: reconnectAttempts,
          maxAttempts: MAX_RECONNECT_ATTEMPTS
        });

        if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
          logger.error('Max reconnection attempts reached', {
            socketId: socket?.id
          });
          cleanupSocket(timeoutId);
          reject(new Error('Max reconnection attempts reached'));
        }
      });

      socket.on('disconnect', (reason) => {
        logger.warn('Socket disconnected', {
          reason,
          socketId: socket?.id,
          willReconnect: reason !== 'io client disconnect'
        });
      });

      socket.on('error', (error) => {
        logger.error('Socket error', {
          error: error.message,
          socketId: socket?.id
        });
      });

      // Now connect
      socket.connect();

    } catch (error) {
      logger.error('Error initializing socket', {
        error: error.message
      });
      cleanupSocket(timeoutId);
      reject(error);
    }
  });
};

const onChallengesCompleted = (callback) => {
  if (!socket?.connected) {
    logger.warn('Socket not connected when trying to listen for challenges');
    return null;
  }

  socket.on('challenges:completed', callback);
  return () => {
    if (socket) {
      socket.off('challenges:completed', callback);
    }
  };
};

const onUserDataUpdated = (callback) => {
  if (!socket?.connected) {
    logger.warn('Socket not connected when trying to listen for user updates');
    return null;
  }

  socket.on('user:updated', callback);
  return () => {
    if (socket) {
      socket.off('user:updated', callback);
    }
  };
};

const disconnectSocket = () => {
  cleanupSocket();
};

export {
  initializeSocket,
  onChallengesCompleted,
  onUserDataUpdated,
  disconnectSocket
};
