// src/pages/BrowseMusic.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';
import SearchBar from '../components/Browse/SearchBar';
import Filters from '../components/Browse/Filters';
import AlbumRow from '../components/Browse/AlbumRow';
import SearchResults from '../components/Browse/SearchResults';
import AddSongModal from '../components/playlists/AddSongModal';
import SelectPlaylistModal from '../components/playlists/SelectPlaylistModal';
import { Music, Sparkles, Zap } from 'lucide-react';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { logger } from '../utils/logger';

const BrowseMusic = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    curriculum: [],
    grade: [],
    subject: []
  });
  const [showFilters, setShowFilters] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [randomSubjects, setRandomSubjects] = useState([]);
  const [albums, setAlbums] = useState({});
  const [recentlyListened, setRecentlyListened] = useState([]);
  const [recentlyListenedError, setRecentlyListenedError] = useState('');
  const [isAddSongModalOpen, setIsAddSongModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [isSelectPlaylistModalOpen, setIsSelectPlaylistModalOpen] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  const fetchAlbums = useCallback(async (subjects) => {
    try {
      const subjectIds = subjects.map(subject => subject._id).join(',');
      const response = await api.get('/music/albums-by-subjects', { params: { subjects: subjectIds } });
      const albumsData = {};
      subjects.forEach(subject => {
        albumsData[subject._id] = response.data.filter(album => album.subject === subject._id);
      });
      setAlbums(albumsData);
      logger.debug('Albums fetched successfully', {
        albumCount: response.data.length
      });
    } catch (error) {
      logger.error('Error fetching albums', {
        error: error.message,
        subjects
      });
    }
  }, []);

  const fetchRandomSubjects = useCallback(async () => {
    try {
      const params = {};
      if (filters.grade.length > 0) params.grade = filters.grade.join(',');
      if (filters.curriculum.length > 0) params.curriculum = filters.curriculum.join(',');

      const response = await api.get('/random-subjects', { params });
      setRandomSubjects(response.data);
      fetchAlbums(response.data);
      logger.debug('Random subjects fetched successfully', {
        subjectCount: response.data.length
      });
    } catch (error) {
      logger.error('Error fetching random subjects', {
        error: error.message,
        filters
      });
    }
  }, [filters, fetchAlbums]);

  const fetchUserPreferences = useCallback(async () => {
    try {
      const response = await api.get('/user/preferences');
      setUserPreferences(response.data);
      setFilters(prevFilters => ({
        curriculum: response.data.preferredCurriculum ? [response.data.preferredCurriculum._id] : prevFilters.curriculum,
        grade: response.data.preferredGrade ? [response.data.preferredGrade._id] : prevFilters.grade,
        subject: response.data.preferredSubjects ? response.data.preferredSubjects.map(s => s._id) : prevFilters.subject
      }));
      logger.debug('User preferences fetched successfully', {
        userPreferences: response.data
      });
    } catch (error) {
      logger.error('Error fetching user preferences', {
        error: error.message
      });
    }
  }, []);

  const fetchRecentlyListenedChapters = useCallback(async () => {
    try {
      const response = await api.get('/music/recently-listened');
      if (response.data && response.data.chapters && response.data.chapters.length > 0) {
        setRecentlyListened(response.data.chapters);
        logger.debug('Recently listened chapters fetched successfully', {
          chapterCount: response.data.chapters.length
        });
      } else {
        setRecentlyListened([]);
        setRecentlyListenedError("Quiet here, huh? Your music history will show up as soon as you start listening!");
        logger.debug('No recently listened chapters found', {});
      }
    } catch (error) {
      logger.error('Error fetching recently listened chapters', {
        error: error.message
      });
      if (error.response && error.response.status === 401) {
        setRecentlyListenedError("Please log in to see your listening history.");
        logger.debug('Unauthorized error fetching recently listened chapters', {});
      } else {
        setRecentlyListenedError("Oops! We couldn't fetch your vibe history. Try refreshing!");
        logger.debug('Error fetching recently listened chapters', {
          error: error.message
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchUserPreferences();
    fetchRecentlyListenedChapters();
  }, [fetchUserPreferences, fetchRecentlyListenedChapters]);

  useEffect(() => {
    if (filters.curriculum.length > 0 || filters.grade.length > 0 || filters.subject.length > 0) {
      fetchRandomSubjects();
    }
  }, [filters, fetchRandomSubjects]);

  const handleSearch = async (term) => {
    setSearchTerm(term);
    try {
      const params = { songName: term };
      if (filters.curriculum.length > 0) params.curriculum = filters.curriculum.join(',');
      if (filters.grade.length > 0) params.grade = filters.grade.join(',');
      if (filters.subject.length > 0) params.subject = filters.subject.join(',');

      const response = await api.get('/music/search', { params });
      setSearchResults(response.data);
      logger.debug('Search results fetched successfully', {
        resultCount: response.data.length
      });
    } catch (error) {
      logger.error('Error searching music', {
        error: error.message,
        term,
        filters
      });
      setSearchResults([]);
    }
  };

  const handleFilterChange = (newFilters) => {
    logger.debug('Filters updated', {
      oldFilters: filters,
      newFilters
    });
    setFilters(newFilters);
    if (searchTerm) {
      handleSearch(searchTerm);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleAlbumSelect = (album) => {
    if (album && album._id) {
      navigate(`/music-player/chapter/${album._id}`);
      logger.debug('Album selected', {
        albumId: album._id
      });
    } else {
      logger.warn('Invalid album selected', { album });
    }
  };
  
  const handleSongSelect = (song) => {
    if (song && song._id) {
      navigate(`/music-player/song/${song._id}`, { 
        state: { 
          initialSong: {
            ...song,
            album: {
              _id: song.chapter._id,
              title: song.chapter.name,
              coverImage: song.chapter.coverImage
            }
          } 
        } 
      });
      logger.debug('Song selected', {
        songId: song._id,
        chapterId: song.chapter._id
      });
    } else {
      logger.warn('Invalid song selected', { song });
    }
  };

  const handleAddToPlaylist = (song) => {
    setSelectedSong(song);
    setIsSelectPlaylistModalOpen(true);
    logger.debug('Song added to playlist', {
      songId: song._id
    });
  };

  const handleAddSongToPlaylist = async (song, playlistId) => {
    logger.debug('Song added to playlist successfully', {
      songId: song._id,
      playlistId
    });
  };

  const handleCloseSelectPlaylistModal = () => {
    setIsSelectPlaylistModalOpen(false);
  };

  const RecentlyListenedHeader = () => (
    <div className="flex items-center space-x-2 mb-4">
      <h2 className="text-2xl md:text-3xl font-bold text-eduvibe-green">Your Recent Vibes</h2>
      <div className="flex space-x-2">
        <Music className="text-eduvibe-green animate-bounce" />
        <Sparkles className="text-eduvibe-blue animate-spin-slow" />
        <Zap className="text-eduvibe-green animate-bounce" />
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper">
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <h1 className="text-4xl md:text-6xl font-extrabold text-center text-eduvibe-green mb-8">
            Drop the Beat on Your Studies
          </h1>
          
          <SearchBar onSearch={handleSearch} toggleFilters={toggleFilters} />
          <Filters onFilterChange={handleFilterChange} isVisible={showFilters} userPreferences={userPreferences} />

          {searchResults ? (
            <SearchResults 
              results={searchResults} 
              onSongSelect={handleSongSelect}
              onAddToPlaylist={handleAddToPlaylist}
            />
          ) : (
            <>
              <section className="mb-12">
                <RecentlyListenedHeader />
                {recentlyListenedError ? (
                  <p className="text-xl text-eduvibe-blue mb-4">{recentlyListenedError}</p>
                ) : recentlyListened && recentlyListened.length > 0 ? (
                  <AlbumRow albums={recentlyListened} onAlbumSelect={handleAlbumSelect} />
                ) : (
                  <p className="text-xl text-eduvibe-blue mb-4">Start your musical journey and see your recent tracks here!</p>
                )}
              </section>

              {randomSubjects.map(subject => (
                <section key={subject._id} className="mb-12">
                  <h2 className="text-2xl md:text-3xl font-bold text-eduvibe-green mb-4">{subject.name}</h2>
                  <AlbumRow albums={albums[subject._id] || []} onAlbumSelect={handleAlbumSelect} />
                </section>
              ))}
            </>
          )}
        </main>
      </div>
      <AddSongModal
        isOpen={isAddSongModalOpen}
        onClose={() => setIsAddSongModalOpen(false)}
        onAddSong={handleAddSongToPlaylist}
      />
      <SelectPlaylistModal
        isOpen={isSelectPlaylistModalOpen}
        onClose={handleCloseSelectPlaylistModal}
        song={selectedSong}
        onAddToPlaylist={handleAddSongToPlaylist}
      />
    </div>
  );
};

export default BrowseMusic;
