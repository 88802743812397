// src/services/musicService.js
import { userPool } from './api';
import { logger } from '../utils/logger';

// Simple in-memory cache for song blobs
const songCache = new Map();
const songRequests = new Map();

// Function to clear the song cache
export const clearSongCache = () => {
  // Revoke all object URLs before clearing
  songCache.forEach(objectUrl => {
    try {
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      logger.warn('Error revoking object URL during cache clear', { error: error.message });
    }
  });
  songCache.clear();
  songRequests.clear();
  logger.debug('Song cache cleared');
};

/**
 * Get a signed URL for streaming a song
 * @param {string} songId - The ID of the song to stream
 * @returns {Promise<{url: string}>} - The signed URL for streaming
 */
export const getStreamingUrl = async (songId) => {
  try {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      throw new Error('No authenticated user found');
    }

    const session = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) reject(err);
        else resolve(session);
      });
    });

    return {
      url: `/api/music/stream/${songId}`
    };
  } catch (error) {
    logger.error('Failed to get streaming URL', {
      error: error.message,
      songId
    });
    throw error;
  }
};

/**
 * Stream a song using the provided URL
 * @param {string} url - The URL to stream from
 * @returns {Promise<string>} - Object URL for the audio blob
 */
export const streamSong = async (url) => {
  try {
    // Check if we have this song cached
    if (songCache.has(url)) {
      logger.debug('Using cached song data', { url });
      return songCache.get(url);
    }

    // Check if there's already a request in progress for this URL
    if (songRequests.has(url)) {
      logger.debug('Joining existing request for song', { url });
      return songRequests.get(url);
    }

    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      throw new Error('No authenticated user found');
    }

    const session = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) reject(err);
        else resolve(session);
      });
    });

    const token = session.getIdToken().getJwtToken();
    
    logger.debug('Streaming song', { url });
    
    // Create a new request promise and store it
    const requestPromise = (async () => {
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        
        // Cache the result
        songCache.set(url, objectUrl);
        
        logger.debug('Created object URL for song', { 
          url, 
          objectUrl: objectUrl.substring(0, 30) + '...' 
        });

        return objectUrl;
      } finally {
        // Clean up the request promise when done
        songRequests.delete(url);
      }
    })();

    // Store the promise
    songRequests.set(url, requestPromise);
    
    return requestPromise;
  } catch (error) {
    logger.error('Failed to stream song', {
      error: error.message,
      url
    });
    throw error;
  }
};

/**
 * Preload a song in the background
 * @param {string} songId - The ID of the song to preload
 * @returns {Promise<void>}
 */
export const preloadSong = async (songId) => {
  try {
    const { url } = await getStreamingUrl(songId);
    const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';
    const fullUrl = `${baseUrl}${url}`;

    // Only preload if not already cached
    if (!songCache.has(fullUrl) && !songRequests.has(fullUrl)) {
      await streamSong(fullUrl);
      logger.debug('Successfully preloaded song', { songId });
    }
  } catch (error) {
    logger.warn('Failed to preload song', {
      error: error.message,
      songId
    });
  }
};
