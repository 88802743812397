import React, { useState, useEffect } from 'react';
import NorthernLights from '../components/common/NorthernLights';
import Header from '../components/common/Header';
import ChallengeCard from '../components/challenges/ChallengeCard';
import { Trophy, Filter } from 'lucide-react';
import { fetchUserChallenges } from '../services/api';
import { onChallengesCompleted } from '../services/socket';

const ChallengesPage = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await fetchUserChallenges();
        setChallenges(response.data.challenges);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch challenges. Please try again later.');
        setLoading(false);
      }
    };

    fetchChallenges();

    const handleChallengesCompleted = (completedChallenges) => {
      setChallenges(prevChallenges => 
        prevChallenges.map(challenge => 
          completedChallenges.find(c => c._id === challenge._id)
            ? { ...challenge, completed: true, progress: 100 }
            : challenge
        )
      );
    };

    onChallengesCompleted(handleChallengesCompleted);

    return () => {
      // Clean up socket listener if needed
    };
  }, []);

  const filteredChallenges = challenges.filter(challenge => {
    if (filter === 'all') return true;
    if (filter === 'active') return !challenge.completed && new Date(challenge.startDate) <= new Date() && new Date(challenge.endDate) > new Date();
    if (filter === 'completed') return challenge.completed;
    if (filter === 'upcoming') return new Date(challenge.startDate) > new Date();
    return true;
  });

  if (loading) {
    return <div>Loading challenges...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-eduvibe-green mb-8 flex items-center">
          <Trophy className="mr-4" /> Active Challenges
        </h1>
        <div className="mb-8">
          <label className="mr-4">Filter challenges:</label>
          <select 
            value={filter} 
            onChange={(e) => setFilter(e.target.value)}
            className="bg-eduvibe-blue text-white p-2 rounded"
          >
            <option value="all">All Challenges</option>
            <option value="active">Active Challenges</option>
            <option value="completed">Completed Challenges</option>
            <option value="upcoming">Upcoming Challenges</option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredChallenges.map((challenge) => (
            <ChallengeCard key={challenge._id} challenge={challenge} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default ChallengesPage;