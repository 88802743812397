import React from 'react';
import { motion } from 'framer-motion';
import { FileText, UserCheck, Copyright, CreditCard, AlertTriangle, Truck, Shield, Building, Mail, Rocket, Star, Bell } from 'lucide-react';
import Header from '../components/common/Header';
import NorthernLights from '../components/common/NorthernLights';

const TermsOfService = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const sections = [
    {
      icon: <Rocket />,
      title: "Beta Program",
      content: "Welcome to the EduVibz Beta Program! By participating, you acknowledge that: (1) The service is in beta testing phase and may contain bugs or errors, (2) Features may change or be removed without notice, (3) We'll collect your feedback to improve the platform, and (4) After the beta period ends, continued access will require a paid subscription. Beta users may receive special benefits when we transition to paid plans."
    },
    {
      icon: <Building />,
      title: "Who We Are",
      content: "EduVibz is revolutionizing the education industry by providing an innovative music-based learning platform. We're registered in South Africa as EduVibz (Pty) Ltd (2024/615680/07) and operate from Johannesburg, South Africa."
    },
    {
      icon: <UserCheck />,
      title: "User Responsibilities",
      content: "As a beta user, you agree to: (1) Use our services for their intended educational purposes, (2) Provide feedback when requested, (3) Report any bugs or issues you encounter, (4) Keep your account information secure, and (5) Not share your beta access with others. Your input helps shape the future of EduVibz! 🚀"
    },
    {
      icon: <Star />,
      title: "Beta Features",
      content: "During the beta, you'll have access to all platform features including gamified learning, personalized playlists, progress tracking, and AI-powered music education tools. While these features are currently free, they may become part of different subscription tiers after the beta period."
    },
    {
      icon: <Copyright />,
      title: "Intellectual Property",
      content: "All our awesome content (music, lyrics, educational materials) is protected by copyright laws. Just like you can't copy your favorite artist's music without permission, you can't reproduce our content without our consent. We've worked hard on these beats! 🎵"
    },
    {
      icon: <Bell />,
      title: "Beta Duration & Transition",
      content: "The beta phase will run for a limited time. We'll provide at least 30 days notice before transitioning to paid plans. During this transition, beta users will receive information about available plans and any special offers for early supporters."
    },
    {
      icon: <Shield />,
      title: "Privacy & Security",
      content: "We take your privacy seriously (as required by POPIA). During the beta, we may collect additional usage data and feedback to improve the platform. Your personal info is stored separately from usage data, and all data is handled securely using SSL3 encryption. 🛡️"
    },
    {
      icon: <AlertTriangle />,
      title: "Account Termination",
      content: "We reserve the right to suspend accounts that violate these terms, misuse beta access, or cause harm to our community. Beta access may also be revoked if users are inactive for extended periods. Don't worry though - we're not the fun police, just keep it respectful! ✌️"
    },
    {
      icon: <FileText />,
      title: "Changes to Terms",
      content: "We may update these terms occasionally (last updated: 22 February, 2025). During the beta phase, changes might be more frequent as we refine our service. We'll notify you of any major changes - no sneaky stuff! Your continued use of EduVibz means you accept any updates."
    }
  ];

  return (
    <div className="min-h-screen flex flex-col text-white overflow-y-auto relative">
      <Header />
      <NorthernLights />
      <div className="content-wrapper z-10 relative">
        <main className="container mx-auto px-4 py-8 mt-16">
          <motion.h1 
            className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            Beta Terms of Service
          </motion.h1>
          
          <motion.p
            className="text-xl text-center mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            Welcome to the EduVibz Beta! 🚀 Before you start your musical learning journey, let's cover the important stuff about being a beta user.
          </motion.p>

          {sections.map((section, index) => (
            <motion.section 
              key={index}
              className="mb-12 bg-eduvibe-dark bg-opacity-60 p-6 rounded-xl"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <h2 className="text-3xl font-bold text-eduvibe-blue mb-4 flex items-center">
                {React.cloneElement(section.icon, { className: "mr-3 w-8 h-8" })}
                {section.title}
              </h2>
              <p className="text-lg">{section.content}</p>
            </motion.section>
          ))}

          <motion.section
            className="mb-12"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2 className="text-3xl font-bold text-eduvibe-blue mb-4 flex items-center">
              <Mail className="mr-3 w-8 h-8" />
              Contact Us
            </h2>
            <p className="text-lg">
              Questions about the beta program or these terms? Reach out to us at beta@eduvibz.com or visit us at:
              <br /><br />
              EduVibz (Pty) Ltd<br />
              67 Juweel Street, Jukskei Park<br />
              Johannesburg, 2188<br />
              South Africa<br />
              Phone: +27 (0)83 297 8455
            </p>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default TermsOfService;