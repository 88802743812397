import React, { useState, useEffect } from 'react';
import { CreditCard, AlertTriangle, RefreshCw, Calendar } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import api, { getSubscriptionDetails } from '../../services/api';
import { logger } from '../../utils/logger';

const SubscriptionManagement = () => {
  const { user, fetchUserData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await getSubscriptionDetails();
        logger.debug('Subscription details:', {
          userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined,
          status: response.data?.status,
          planType: response.data?.planType
        });
        setSubscription(response.data);
        setError(null);
      } catch (error) {
        logger.error('Error fetching subscription details:', {
          userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined,
          error: error.message
        });
        // Fallback to user data from Cognito if API fails
        setSubscription({
          planId: user['custom:planId'],
          status: user['custom:isActive'] === '1' ? 'active' : 'cancelled',
          subscriptionCode: user['custom:subscriptionId']
        });
        setError('Unable to fetch complete subscription details. Showing basic information.');
      }
    };
    fetchSubscription();
  }, [user]);

  const getPlanName = (planId) => {
    switch (planId) {
      case 'PLN_52ma6lqd68a5evu':
        return 'Annual Student Plan';
      case 'PLN_s79w3cojev6tnyh':
        return 'Monthly Student Plan';
      default:
        return 'Unknown Plan';
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-ZA', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const handleCancel = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        setIsLoading(true);
        await api.post('/subscriptions/cancel');
        await fetchUserData();
        const response = await getSubscriptionDetails();
        setSubscription(response.data);
        alert('Thank you for your support. Your subscription will remain active until your next billing date.');
      } catch (error) {
        logger.error('Error cancelling subscription:', {
          userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined,
          error: error.message
        });
        alert('Failed to cancel subscription. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReactivate = async () => {
    if (window.confirm('Would you like to reactivate your subscription?')) {
      try {
        setIsLoading(true);
        await api.post('/subscriptions/reactivate');
        await fetchUserData();
        const response = await getSubscriptionDetails();
        setSubscription(response.data);
        alert('Your subscription has been reactivated successfully!');
      } catch (error) {
        logger.error('Error reactivating subscription:', {
          userId: user?.sub ? `***${user.sub.slice(-4)}` : undefined,
          error: error.message
        });
        alert('Failed to reactivate subscription. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!subscription) {
    return <div className="p-4">Loading subscription details...</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-eduvibe-green">Manage Subscription</h2>
      {error && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 mb-4 rounded">
          {error}
        </div>
      )}
      <div className="bg-eduvibe-blue bg-opacity-20 p-4 rounded-lg mb-4">
        <h3 className="text-xl font-semibold mb-2 flex items-center">
          <CreditCard className="mr-2" /> Current Plan: {getPlanName(subscription.planId)}
        </h3>
        <div className="space-y-2">
          {subscription.status === 'trial' && subscription.trialEndDate && (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 mb-2 rounded">
              <p className="font-medium">Free Trial Active</p>
              <p className="text-sm">Trial ends: {formatDate(subscription.trialEndDate)}</p>
            </div>
          )}
          <div className="flex items-center">
            <span className={`inline-block w-2 h-2 rounded-full mr-2 ${
              subscription.status === 'active' || subscription.status === 'trial' ? 'bg-green-500' : 'bg-red-500'
            }`}></span>
            <span>Status: {subscription.status === 'trial' ? 'Trial' : subscription.status === 'active' ? 'Active' : 'Canceled'}</span>
          </div>
          {subscription.nextPaymentDate && (
            <div className="flex items-center text-white text-sm">
              <Calendar className="mr-2 h-4 w-4" /> 
              <span>Next billing date: {formatDate(subscription.nextPaymentDate)}</span>
            </div>
          )}
          {subscription.subscriptionCode && (
            <div className="text-sm text-gray-500">
              Subscription ID: {subscription.subscriptionCode}
            </div>
          )}
        </div>
      </div>
      <div className="space-y-4 mb-8">
        {subscription.status === 'active' || subscription.status === 'trial' ? (
          <button 
            onClick={handleCancel}
            disabled={isLoading}
            className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors flex items-center justify-center disabled:opacity-50"
          >
            <AlertTriangle className="mr-2" /> 
            {isLoading ? 'Processing...' : 'Cancel Subscription'}
          </button>
        ) : (
          <button 
            onClick={handleReactivate}
            disabled={isLoading}
            className="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors flex items-center justify-center disabled:opacity-50"
          >
            <RefreshCw className="mr-2" /> 
            {isLoading ? 'Processing...' : 'Reactivate Subscription'}
          </button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionManagement;