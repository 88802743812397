import React, { useState } from 'react';
import { Search, Filter } from 'lucide-react';

const SearchBar = ({ onSearch, toggleFilters }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 relative flex items-center">
      <div className="relative flex-grow flex items-center bg-eduvibe-blue bg-opacity-50 rounded-full overflow-hidden">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for tracks, subjects, or curricula..."
          className="flex-grow bg-transparent text-white px-6 py-3 focus:outline-none placeholder-gray-300 rounded-l-full"
        />
        <button 
          type="submit" 
          className="bg-eduvibe-green text-eduvibe-dark px-6 py-3 md:px-8 rounded-r-full hover:bg-opacity-90 transition-colors duration-300"
        >
          <Search size={20} />
        </button>
      </div>
      <button 
        type="button" 
        onClick={toggleFilters}
        className="ml-4 bg-eduvibe-blue bg-opacity-50 text-white p-3 rounded-full hover:bg-eduvibe-green transition-colors duration-300 md:hidden"
      >
        <Filter size={20} />
      </button>
    </form>
  );
};

export default SearchBar;