import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import NorthernLights from '../components/common/NorthernLights';
import Header from '../components/common/Header';
import { User, Mail, Edit2, Save } from 'lucide-react';

const ProfilePage = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState({
    fullName: '',
    email: '',
    bio: '',
    favoriteSubjects: [],
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      setProfile({
        fullName: user.name || '',
        email: user.email || '',
        bio: user.bio || '',
        favoriteSubjects: user.favoriteSubjects || [],
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // In a real application, you would send the updated profile to the backend
    console.log('Updated profile:', profile);
    setIsEditing(false);
  };

  return (
    <div className="min-h-screen flex flex-col text-white bg-eduvibe-dark overflow-hidden relative">
      <NorthernLights />
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-eduvibe-green mb-8">My Profile</h1>
        <div className="bg-eduvibe-blue bg-opacity-20 rounded-xl p-6 backdrop-filter backdrop-blur-lg">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="fullName" className="text-lg font-bold mb-2 flex items-center">
                <User className="mr-2" /> Full Name
              </label>
              {isEditing ? (
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={profile.fullName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg focus:outline-none focus:border-eduvibe-green"
                />
              ) : (
                <p className="text-xl">{profile.fullName}</p>
              )}
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="text-lg font-bold mb-2 flex items-center">
                <Mail className="mr-2" /> Email
              </label>
              <p className="text-xl">{profile.email}</p>
            </div>
            <div className="mb-6">
              <label htmlFor="bio" className="text-lg font-bold mb-2 flex items-center">
                <Edit2 className="mr-2" /> Bio
              </label>
              {isEditing ? (
                <textarea
                  id="bio"
                  name="bio"
                  value={profile.bio}
                  onChange={handleChange}
                  className="w-full px-3 py-2 bg-eduvibe-dark border border-eduvibe-blue rounded-lg focus:outline-none focus:border-eduvibe-green"
                  rows="4"
                ></textarea>
              ) : (
                <p className="text-xl">{profile.bio}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-lg font-bold mb-2">Favorite Subjects</label>
              <div className="flex flex-wrap">
                {profile.favoriteSubjects.map((subject, index) => (
                  <span key={index} className="bg-eduvibe-green text-eduvibe-dark px-3 py-1 rounded-full text-sm mr-2 mb-2">
                    {subject}
                  </span>
                ))}
              </div>
            </div>
            {isEditing ? (
              <button type="submit" className="bg-eduvibe-green text-eduvibe-dark px-4 py-2 rounded-lg hover:bg-eduvibe-blue transition-colors flex items-center">
                <Save className="mr-2" /> Save Changes
              </button>
            ) : (
              <button type="button" onClick={() => setIsEditing(true)} className="bg-eduvibe-blue text-white px-4 py-2 rounded-lg hover:bg-eduvibe-green transition-colors flex items-center">
                <Edit2 className="mr-2" /> Edit Profile
              </button>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;